import { takeLatest, put, call } from 'redux-saga/effects';
import actionTypes from '../actionTypes';
import {
  getStoreDataSuccess,
  getStoreDataRequest,
  getStoreDataFailure,
  clearCart,
  requestUserCart,
  itemDetailsLoader,
  itemDetailsSuccess,
  itemDetailsFailure,
  clearPaymentData
} from '../actions/userActions';
import * as ApiService from '../../services/apiService';
import { getLocalStorageTokens } from '../../utils/tokensHelper';
import Moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { oldSchemaToNewSchemaVariants } from '../../services/varientsSchemamapping';
const publicIp = require('public-ip');

function* getStoreData(action) {
  try {
    yield put(getStoreDataRequest(action.payload.loadingStatus));
    window.localStorage.removeItem('cartId');
    const APIObj = {
      endPoint: `public/stores/${action.payload.storeId}`,
      authenticationRequired: false,
      method: 'GET'
    };
    var apiResult = yield ApiService.callApi(APIObj);
    const offlineStoreData = yield call(offlineStore, apiResult);
    window.sessionStorage.setItem('storeLinkingId', apiResult.data[0].id);
    apiResult.data[0].items = yield oldSchemaToNewSchemaVariants(
      apiResult.data[0].items
    );

    var apiObj = {
      storeData: apiResult.data[0],
      storeAvailability: offlineStoreData.storeAvailability,
      storeDayAvaibility: offlineStoreData.storeDayAvaibility,
      storeCurrentDayData: offlineStoreData.storeCurrentDayData
    };

    yield put(getStoreDataSuccess(apiObj));

    if (!sessionStorage.getItem('ip')) {
      let ip = yield publicIp.v4().catch((e) => {
        console.log(e);
      });
      const storeVisit = yield call(updateVisitors);
      if (ip) sessionStorage.setItem('ip', ip);
    }

    const { isLoggedIn } = getLocalStorageTokens();
    yield put(clearPaymentData());

    if (!isLoggedIn && action.payload.loadingStatus) {
      yield put(clearCart());

    } else {
      // yield put(clearCart());
      yield put(requestUserCart());
    }
  } catch (error) {
    yield put(getStoreDataFailure(error));
    toastr.error(error.message || 'Something went wrong!');
  }
}

function* getStoreItemData(action) {
  // console.log(action, "action apicallll");

  try {
    yield put(itemDetailsLoader());
    const APIObj = {
      endPoint: `public/item/${action.payload.storeId}/${action.payload.itemId}`,
      authenticationRequired: false,
      method: 'GET'
    };
    var apiResult = yield ApiService.callApi(APIObj);
    window.sessionStorage.setItem('storeId', action.payload.storeId);
    const currentStore = apiResult.data;
    let item = apiResult.data.items[0];
    item = yield oldSchemaToNewSchemaVariants(apiResult.data.items);
    if (item !== undefined && item !== null && item.length > 0) {
      item.forEach((f) => {
        f['selectedVariants'] = [];
      });
    }

    yield put(
      itemDetailsSuccess({
        store: currentStore,
        item: item[0]
      })
    );
  } catch (error) {
    yield put(itemDetailsFailure(error));
    toastr.error(error.message || 'Something went wrong!');
  }
}

function* setStoreItemData(action) {
  const currentStore = action.payload.store;

  let item = action.payload.item;
  if (item?.variants?.length) {
    item['selectedVariants'] = [];
  }
  // yield put(itemDetailsSuccess(item));
  yield put(
    itemDetailsSuccess({
      store: currentStore,
      item: item
    })
  );
}
export default [
  takeLatest(actionTypes.GET_STORE_DATA, getStoreData),
  takeLatest(actionTypes.GET_ITEM_DETAIL, getStoreItemData),
  takeLatest(actionTypes.SET_ITEM_DETAIL, setStoreItemData)
];

function compareTime(data1, data2) {
  return new Promise(async function (resolve, reject) {
    var start_time = data1.split(' ');
    var time = start_time[0].split(':');
    var stime = Number(time[0].toString());
    if (start_time[1] === 'PM' && stime < 12) stime = parseInt(stime) + 12;
    else if (start_time[1] === 'AM' && stime === 12) stime = 0;
    start_time = stime + ':' + time[1];

    var end_time = data2.split(' ');
    var time1 = end_time[0].split(':');
    var etime = Number(time1[0].toString());
    if (end_time[1] == 'PM' && etime < 12) etime = parseInt(etime) + 12;
    else if (end_time[1] == 'AM' && etime == 12) etime = 0;
    end_time = etime + ':' + time1[1];

    if (start_time != '' && end_time != '') {
      var str1 = start_time;
      var str2 = end_time;

      str1 = str1.split(':');
      str2 = str2.split(':');

      var totalSeconds1 = parseInt(str1[0] * 3600 + str1[1] * 60 + str1[0]);
      var totalSeconds2 = parseInt(str2[0] * 3600 + str2[1] * 60 + str2[0]);
      if (parseInt(totalSeconds2 - totalSeconds1) > 0) {
        resolve(true);
      } else {
        resolve(false);
      }
    }
  });
}

async function offlineStore(storeData) {
  return new Promise(async function (resolve, reject) {
    // try {
    const currentTimestamp = Math.ceil(Date.now() / 1000);
    var framedStoreData;
    var days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    var d = new Date();
    var dayName = days[d.getDay()];
    var storeObj = storeData.data[0].onlineStore[dayName];
    var dayAvaibility = storeObj.isAvailable;
    var availability = storeData.data[0].onlineStore.available;
    let _go = true;
    if (storeData.data[0].onlineStore?.tempDis) {
      if (storeData.data[0].onlineStore.tempDisTime > currentTimestamp)
        _go = false;
    }
    if (availability & _go) {
      var startEpoch = storeObj.startEpoch;
      var endEpoch = storeObj.endEpoch;
      if (dayAvaibility) {
        const start_time = Moment.unix(startEpoch).format('h:mm:ss A');
        const end_time = Moment.unix(endEpoch).format('h:mm:ss A');
        const current_time =
          d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
        const t1 = await compareTime(start_time, current_time);
        const t2 = await compareTime(current_time, end_time);

        if (t1 && t2) {
          framedStoreData = {
            storeAvailability: availability,
            storeDayAvaibility: true,
            storeCurrentDayData: storeObj
          };
        } else {
          framedStoreData = {
            storeAvailability: availability,
            storeDayAvaibility: false,
            storeCurrentDayData: storeObj
          };
        }
      } else {
        framedStoreData = {
          storeAvailability: availability,
          storeDayAvaibility: dayAvaibility,
          storeCurrentDayData: storeObj
        };
      }
    } else {
      framedStoreData = {
        storeAvailability: false,
        storeDayAvaibility: false,
        storeCurrentDayData: storeObj
      };
    }

    resolve(framedStoreData);
    // } catch (err) {
    //   reject(err);
    // }
  });
}

function updateVisitors() {
  const storeId = window.sessionStorage.getItem('storeLinkingId');
  const APIObj = {
    endPoint: `public/visitors/${storeId}`,
    authenticationRequired: false,
    method: 'PUT',
    body: null,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*'
    }
  };
  ApiService.callApi(APIObj).catch((err) => {
    sessionStorage.clear();
  });
}
