import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import _ from 'lodash';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const DeliveryOptions = ({
  onlineStore,
  classes,
  deliveryOption,
  handleDeliveryOption
}) => {
  const selfPickupEnabled = onlineStore?.selfPickupEnabled ?? true;
  let deliveryObj;
  if (onlineStore?.additionalCharges && onlineStore?.additionalCharges.length) {
    var deliveryCharges = _.filter(onlineStore?.additionalCharges, {
      name: 'Delivery Charges',
      enabled: true
    });
    if (deliveryCharges.length) deliveryObj = deliveryCharges[0];
  } else {
    if (onlineStore?.delivery) {
      deliveryObj = {
        name: 'Delivery Charges',
        cost: onlineStore?.delivery?.cost,
        enabled: onlineStore?.delivery?.enabled
      };
    }
  }

  if (deliveryObj?.enabled && selfPickupEnabled)
    return (
      <>
        <Typography className={classes.text}>
          <Box fontWeight="fontWeightMedium">Delivery Option</Box>
        </Typography>
        <Paper className={classes.deliveryDetailsPaper} elevation={0}>
          <Grid>
            <RadioGroup value={deliveryOption} onChange={handleDeliveryOption}>
              <Grid container className={classes.radio_items}>
                <Grid item>
                  <FormControlLabel
                    value="delivery"
                    control={
                      <Radio
                        classes={{
                          root: classes.radio,
                          checked: classes.checked
                        }}
                      />
                    }
                    label="Home Delivery"
                    classes={{ label: classes.label }}
                  />
                </Grid>
                <Grid item>
                  <Typography className={classes.del_text}>
                    &#8377; {deliveryObj?.cost}
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={6}>
                <FormControlLabel
                  value="self-pickup"
                  control={
                    <Radio
                      classes={{
                        root: classes.radio,
                        checked: classes.checked
                      }}
                    />
                  }
                  label="Self Pickup"
                  classes={{ label: classes.label }}
                />
              </Grid>
            </RadioGroup>
          </Grid>
        </Paper>
      </>
    );
  else if (deliveryObj?.enabled || selfPickupEnabled)
    return (
      <div>
        {' '}
        <Paper className={classes.item_paper} elevation={0}>
          <Grid xs={12} className={classes.grid_flex}>
            <Grid
              style={{
                paddingRight: 4,
                display: 'flex'
              }}
            >
              <ErrorOutlineIcon
                style={{
                  color: '#DBA719',
                  fontSize: 20
                }}
              />
            </Grid>
            <Grid item xs={11}>
              <Typography className={classes.paymentText}>
                Delivery Option
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.paymentText}>
                <Box
                  fontWeight="fontWeightMedium"
                  style={{ width: 'max-content' }}
                >
                  {deliveryObj?.enabled ? 'Home Delivery' : 'Self-Pickup'}
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );

  return <div />;
};

export default DeliveryOptions;
