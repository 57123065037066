import React from 'react';
import _ from 'lodash';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { getLocalStorageTokens } from '../utils/tokensHelper';
import {
  //ROOT_ROUTE,
  LOGIN_ROUTE,
  LOGIN_ACCOUNT,
  HOME_ROUTE,
  HOME_STORE_ROUTE,
  ORDER_ROUTE,
  ACCOUNT_ROUTE,
  CART_ROUTE,
  ITEM_DETAILS,
  INDIVIDUAL_ORDER,
  ADDRESS,
  HOME_STORE_ROUTE_TEMP,
  ORDER_CONFIRM,
  INVOICE_VIEW,
  WISHLIST,
  CUSTOM_ITEM_DETAILS
} from '../utils/routesConstants';
import PageNotFound from './PageNotFound';
import Login from './Login';
import Tab from './Tabs';
import Marketplace from './Marketplace';
import Account from './Tabs/Account';
import Order_Details from './Tabs/Order_Detail';
import Cart from './Cart';
import ItemDetails from './ItemDetail';
import WishList from './WishList';

import IndividualOrder from './Tabs/Order_Detail/components/individual_order';
import Address from './Tabs/Account/components/address';

// import Landing from './Landing';
import OrderConfirmation from './OrderConfirmation';
import Invoice from './Invoice/index';


const routesConfig = {
  // landing: {
  //   path: HOME_STORE_ROUTE_TEMP,
  //   component: Landing,
  //   exact: true,
  //   privateRoute: false
  // },
  login: {
    path: LOGIN_ROUTE,
    component: Login,
    exact: true,
    privateRoute: false
  },
  loginAccount: {
    path: LOGIN_ACCOUNT,
    component: Account,
    exact: true,
    privateRoute: true
  },
  home: {
    path: HOME_STORE_ROUTE,
    component: Tab,
    exact: true,
    privateRoute: false
  },
  marketPlace: {
    path: HOME_ROUTE,
    component: Marketplace,
    exact: true,
    privateRoute: false
  },
  order_detail: {
    path: ORDER_ROUTE,
    component: Order_Details,
    exact: true,
    privateRoute: true
  },
  account: {
    path: ACCOUNT_ROUTE,
    component: Account,
    exact: true,
    privateRoute: true
  },
  cart: {
    path: CART_ROUTE,
    component: Cart,
    exact: true,
    privateRoute: false
  },
  item_details: {
    path: ITEM_DETAILS,
    component: ItemDetails,
    exact: true,
    privateRoute: false
  },


  custom_item_details: {
    path: CUSTOM_ITEM_DETAILS,
    component: ItemDetails,
    exact: true,
    privateRoute: false
  },


  individual_order: {
    path: INDIVIDUAL_ORDER,
    component: IndividualOrder,
    exact: true,
    privateRoute: true
  },
  address: {
    path: ADDRESS,
    component: Address,
    exact: true,
    privateRoute: true
  },
  orderConfirmed: {
    path: ORDER_CONFIRM,
    component: OrderConfirmation,
    exact: true,
    privateRoute: true
  },
  viewInvoice: {
    path: INVOICE_VIEW,
    component: Invoice,
    exact: true,
    privateRoute: false
  },
  wishlist: {
    path: WISHLIST,
    component: WishList,
    exact: true,
    privateRoute: false
  },

};

const ProtectedRoutes = (props) => {
  const { component: Component, privateRoute, ...rest } = props;
  const { isLoggedIn } = getLocalStorageTokens();
  const isValidRoute = (privateRoute && isLoggedIn) || !privateRoute;

  if (Component.name === 'Login' && isLoggedIn)
    return <Redirect to={HOME_ROUTE} />;

  if (isValidRoute || props.match.params.storeId !== undefined) {
    return <Component {...rest} />;
  }
  return <Redirect to={privateRoute ? LOGIN_ROUTE : HOME_ROUTE} />;
};

const AppRoutes = () => {
  const routes = _.keys(routesConfig);

  return (
    <Switch>
      {routes.map((route) => {
        const config = routesConfig[route];

        return (
          <Route
            exact={config.exact}
            key={`${route}`}
            path={config.path}
            render={(props) => (
              <ProtectedRoutes
                component={config.component}
                privateRoute={config.privateRoute}
                {...props}
              />
            )}
          />
        );
      })}

      <Route path="*" render={() => <PageNotFound />} />
    </Switch>
  );
};

export default withRouter(AppRoutes);
