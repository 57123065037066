import { createBrowserHistory } from 'history';
import { getStoreIdBasedOnDomain } from './domainStoreIdMapping';

let history = createBrowserHistory();

export const navigateTo = (route) => {
  history.push(route);
};

export const navigateToWithState = (route, item) => {
  history.push({ pathname: route, state: item });
};


export const backTo = () => {
  history.goBack();
}


export const replace = () => {
  history.replace();
}

export const replaceWithRoute = (route) => {
  history.replace(route);
}

export const navigateToHome = () => {
  // let isCustomDomain = window.sessionStorage.getItem('custom_domain');
  // var isTrueSet = (isCustomDomain === 'true');
  const storeId = window.sessionStorage.getItem('storeId');
  if (isFromCustomDomain())
    navigateTo('/');
  else if (storeId !== undefined)
    navigateTo(`/s/${storeId}`);
  else backTo()

}

export const replaceToHome = () => {

  const storeId = window.sessionStorage.getItem('storeId');
  if (isFromCustomDomain())
    replaceWithRoute('/');
  else if (storeId !== undefined)
    replaceWithRoute(`/s/${storeId}`);
  else backTo()

}

export const shareUrl = () => {
  const storeId = window.sessionStorage.getItem('storeId');

  if (isFromCustomDomain()) {
    return window.location.href;
  }
  else
    return `https://apnadukan.co/s/${storeId}`;



}
export const isFromCustomDomain = () => {
  let isCustomDomain = window.sessionStorage.getItem('custom_domain');
  let storeIdAlias = getStoreIdBasedOnDomain();

  var isTrueSet = (isCustomDomain === 'true');
  return isTrueSet || storeIdAlias;

}



export default history;
