import React from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  RadioGroup,
  Radio,
  withStyles,
} from '@material-ui/core';

const GreenRadio = withStyles ({
  root: {
    color: '#5C4DB1',
    '&$checked': {
      color: '#5C4DB1',
    },
  },
  checked: {},
}) (props => <Radio color="default" {...props} />);

const WeightSelectItem = ({
  weightList,
  selectedItem,
  handleVarients,
  uniqueOptionAvailable,
  type,
  multipleVariantCheck = false,
}) => {
  return (
    <Grid>
      <InputLabel
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          fontWeight: '500',
          color: '#333333',
        }}
      >
        Variants
      </InputLabel>
      <Box display="flex" flexWrap="wrap">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="variants"
            name="variants"
            value={selectedItem}
            onChange={a => {
              var ob = {};
              ob[type] = a.target.value;
              handleVarients (ob);
            }}
          >
            {weightList.map ((a, i) => (
              <Box display="flex" alignItems="center">
                <FormControlLabel
                  value={a}
                  control={<GreenRadio />}
                  label={a}
                />
                {/* 
                                <Box fontSize="12px" style={{ color: '#777777' }}>
                                    {sp}
                                </Box> 
                                */}
              </Box>
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Grid>
  );
};
export default WeightSelectItem;
