import React, { useState } from 'react';
import {
  AppBar,
  Typography,
  Button,
  Box,
  IconButton,
  Toolbar,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LoginImage from '../../../assets/images/login.png';

import LoginIndex from '../index';
import '../style.scss';

const Login = ({ showNavBar, handleClose }) => {
  const [login, setLogin] = useState(true);

  // Currently not used 
  const handleLoginScreen = () => {
    setLogin(true);
  };

  return (
    <Box
      display="flex"
      height={['calc(100vh - 56px)', '100%', '100%', '100%']}
      width="100%"
      position="relative"
      flexDirection="column"
    >

      <Box className="content-mobile">
        <Box className="login-title"
          display="flex"
        >
          <AppBar className="appBar">
            <Toolbar>
              <Typography color="secondary" variant="h6">
                Login
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
      </Box>

      <Box className="content-dsk">
        <Box
          width="100%"
          mt="55px"
          justifyContent="space-between"
          alignItems="center" display="flex"
        >
          <Box pl={2} fontSize="18px">Login</Box>
          <IconButton onClick={handleClose}>
            <CloseIcon size="18" />
          </IconButton>
        </Box>
      </Box>

      <Box
        display="flex"
        width="100%"
        overflow="auto"
      >
        {login
          ? <LoginIndex handleClose={handleClose} />
          : <Box
            display="flex"
            flexDirection="column"
            width="100%"
          >
            <Box
              overflow="hidden"
              width="100%"
              className="responsive-img-holder"
            >
              <img className="responsive-img" src={LoginImage} />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">

              <Box
                display="flex"
                width="90%"
                pb={5} alignItems="flex-end" className="login-bottom-section"
              >
                <Button
                  // onClick={() => navigateTo(LOGIN_ROUTE)}
                  onClick={handleLoginScreen}
                  size="large"
                  className="btn"
                  variant="contained"
                  size="large"
                  color="primary"
                  fullWidth
                >
                  Login
                </Button>
              </Box>
            </Box>
          </Box>}
      </Box>
    </Box>
  );
};

export default Login;

