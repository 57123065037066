import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Slide,
  Modal,
  makeStyles,
  Divider,
} from '@material-ui/core';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import LazyLoad from 'react-lazyload';

import { useHomeHook } from '../hooks';
import { useWishlistHook } from '../../../WishList/hooks';

import '../styles.scss';
import ListItem from './listItem';
import GridItem from './gridItem';
import EmptyResult from './emptySearch';
import VarientSelection from './varientsSelection';
import { navigateTo } from '../../../../utils/history';
import { CART_ROUTE } from '../../../../utils/routesConstants';
import { navigateToWithState } from '../../../../utils/history';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  img: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '80%',
    borderRadius: 15,
    objectFit: 'cover',
    width: '100%',
    minHeight: '30px',
  },
  paperGrid: {
    // backgroundColor: 'white',
    padding: theme.spacing(1),
    marginBottom: 1,
    ' -webkit-box-shadow': 'none',
    '-moz-box-shadow': 'none',
    boxShadow: ' none',
  },


  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    height: '100%',
    overflow: 'hidden',
  },
  addToCartButton: {
    padding: theme.spacing(2),
    margin: 5,
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#5C4DB1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemAddedTab: {
    padding: theme.spacing(1),
    margin: 5,
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#DBA719',
    width: '-webkit-fill-available',
  },
}));

function ListCustomItem({ toggle, products, userCartData, storeName, isMobile, wishlist, storeId }) {
  const { addItemToCart, removeItemFromCart } = useHomeHook();
  const { updateWishlist } = useWishlistHook();
  const [itemIdArray, setitemIdArray] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [productList, setProductList] = useState([]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const addItem = item => {
    if (item.variants && item.variants.length) {
      setSelectedItem(item);
      handleOpen();
    } else {
      setitemIdArray(oldArray => [...oldArray, item._id]);
      addItemToCart(item);
    }
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const removeItem = item => {
    if (item.variants && item.variants.length) {
      handleClickOpen();
    } else {
      setitemIdArray(oldArray => [...oldArray, item._id]);
      removeItemFromCart(item);
    }
  };

  const convertToRs = (mrp, price) => {
    return `${Math.round((mrp - price) * 100 / mrp)} %`;
  };

  useEffect(
    () => {
      if (!userCartData.onAddItemLoader) setitemIdArray([]);
    },
    [userCartData.onAddItemLoader]
  );
  useEffect(
    () => {
      if (products.length > 10)
        setProductList(products.slice(0, 9))
      else
        setProductList(products);

    },
    [toggle, products]
  );

  const handleGridClick = (e, item, storeName) => {
    const storeId = window.sessionStorage.getItem('storeLinkingId');
    navigateToWithState(`${storeId}/${item._id}`, {
      item,
      storeName,
    });
  };
  const handleWishList = (item) => {
    console.log(wishlist.wishlistData, 'wishlist.wishlistData', item);
    var clonelist = _.clone(wishlist.wishlistData, true);
    const storeId = window.sessionStorage.getItem('storeLinkingId');

    if (_.find(wishlist.wishlistData, {
      _id: item._id
    })) {
      _.remove(clonelist, wishlist => wishlist._id === item._id);

    }
    else {
      clonelist.push({ ...item, storeId })
    }
    updateWishlist(clonelist)

  }
  let loadMore = () => {
    if (productList.length < products.length) {
      var clonelist = _.clone(productList, true);
      var sliceProduct = products.slice(clonelist.length, clonelist.length + 10);
      var productConcat = _.concat(clonelist, sliceProduct);
      setProductList(productConcat)
    }

  }

  if (products.length === 0) {
    return <EmptyResult classes={classes} isMobile={isMobile} />;
  }
  return (
    <div
      style={{
        flexGrow: 1,
        marginBottom: 30,
      }}
    >
      {!toggle &&
        <Box height={'100%'}>
          <InfiniteScroll dataLength={productList.length}
            next={loadMore}
            hasMore={productList.length < products.length}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            onScroll={(y) => {
              if (window.pageYOffset < 40) {
                setProductList(products.slice(0, 9))
              }

            }}
          >
            {productList.map((item, index) => {
              return (

                <Box py={1} style={{ maxWidth: '570px' }} key={index}>
                  <ListItem
                    item={item}
                    handleGridClick={handleGridClick}
                    convertToRs={convertToRs}
                    storeName={storeName}
                    userCartData={userCartData}
                    itemIdArray={itemIdArray}
                    addItem={addItem}
                    removeItem={removeItem}
                    classes={classes}
                    isWishlisted={_.find(wishlist.wishlistData, {
                      _id: item._id
                    })}
                    handleWishList={handleWishList}
                  />
                  <Divider />
                </Box>
              );
            })}
          </InfiniteScroll>
        </Box>}

      {toggle &&
        <Paper
          // style={{
          //   padding: theme.spacing(1),
          //   marginBottom: 1,
          //   ' -webkit-box-shadow': 'none',
          //   '-moz-box-shadow': 'none',
          //   boxShadow: ' none'
          // }}
          className={classes.paperGrid}
        >
          {!isMobile ? <Grid>

            <InfiniteScroll dataLength={productList.length}
              next={loadMore}
              hasMore={productList.length < products.length}
              loader={<h4>Loading...</h4>}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              onScroll={(y) => {
                if (window.pageYOffset < 40) {
                  setProductList(products.slice(0, 9))
                }

              }}
            >
              <Grid container spacing={3}>
                {productList &&
                  productList.map(item => {
                    return (
                      <Grid item xs={12} sm={6} md={4} lg={3} >
                        <LazyLoad

                          height={100}
                          key={item.id}
                          offset={[-200, 0]}
                          placeholder={<Box display="flex" height="213px" width="100%"
                            height="211px"
                            style={{
                              backgroundColor: '#f3f3f3'
                            }}
                            borderRadius={5}
                            justifyContent='center' alignItems='center'
                          ><div className="loader small-loader"></div></Box>}
                        >
                          <GridItem
                            item={item}
                            handleGridClick={handleGridClick}
                            convertToRs={convertToRs}
                            storeName={storeName}
                            userCartData={userCartData}
                            itemIdArray={itemIdArray}
                            addItem={addItem}
                            removeItem={removeItem}
                            // classes={classes}
                            handleWishList={handleWishList}
                            isWishlisted={_.find(wishlist.wishlistData, {
                              _id: item._id
                            })}
                          />
                        </LazyLoad>
                      </Grid>

                    );
                  })}
              </Grid>
            </InfiniteScroll>
          </Grid> : (



              <InfiniteScroll dataLength={productList.length}
                next={loadMore}
                hasMore={productList.length < products.length}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }

                onScroll={(y) => {
                  if (window.pageYOffset < 40) {
                    setProductList(products.slice(0, 9))
                  }

                }}

              >
                {/* <Grid container spacing={3}> */}
                {
                  productList.map((item, index) => {
                    return (
                      <Grid item xs={12} sm={6} md={4} lg={3} key={index} >
                        {/* {index} */}
                        <GridItem
                          item={item}
                          handleGridClick={handleGridClick}
                          convertToRs={convertToRs}
                          storeName={storeName}
                          userCartData={userCartData}
                          itemIdArray={itemIdArray}
                          addItem={addItem}
                          removeItem={removeItem}
                          // classes={classes}
                          handleWishList={handleWishList}
                          isWishlisted={_.find(wishlist.wishlistData, {
                            _id: item._id
                          })}

                        />
                      </Grid>
                    )
                  })
                }
                {/* </Grid> */}
              </InfiniteScroll>
            )
          }

        </Paper>}

      {isMobile
        ? <Backdrop
          className={classes.backdrop}
          open={openModal}
        // onClick={() => setOpenModal(false)}
        >
          <Slide direction="up" in={openModal} timeout={1000}>
            <Box className="mobilePopup" elevation={0} p={4}>
              <VarientSelection
                openModal={openModal}
                setOpenModal={setOpenModal}
                handleClose={handleClose}
                classes={classes}
                item={selectedItem}
                userCartData={userCartData}
              />
            </Box>
          </Slide>
        </Backdrop>
        : <Modal
          aria-labelledby="transition-modal-title"
          // className={classes.modal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          closeAfterTransition
        >
          <VarientSelection
            openModal={openModal}
            setOpenModal={setOpenModal}
            handleClose={handleClose}
            classes={classes}
            item={selectedItem}
            userCartData={userCartData}
          />
        </Modal>}

      <AlertDialogSlide handleClose={handleClickClose} open={open} />
    </div>
  );
}

const mapStateToProps = ({ userCartData, wishlist }) => ({
  userCartData, wishlist
});

export default connect(mapStateToProps)(ListCustomItem);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AlertDialogSlide({ handleClose, open }) {
  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {'Remove item from cart'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            This item as multiple customizations added. Proceed to cart to remove
            item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={() => navigateTo(CART_ROUTE)} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
