const syncActions = [
  'GET_AUTH_DATA',
  'UPDATE_AUTH_DATA',
  'LOGOUT',

  'GET_STORE_DATA',
  'GET_ITEM_DETAIL',
  'SET_ITEM_DETAIL',
  'GET_ALL_ORDERS',

  'GET_USER_CART_DATA',
  'ADD_ITEM_TO_CART',
  'REMOVE_ITEM_FROM_CART',

  'CART_CLEAR',
  'CART_CHECKOUT',

  'ADD_USER_NOTE',
  'UPDATE_USER_NOTE',
  'UPDATE_VARIANTS',
  'GET_ALL_COUPONS',

  'INITIATE_PAYMENT',
  'CHECK_PAYMENT_STATUS',
  'CLEAR_PAYMENT_DATA',
  'GET_WISHLIST',
  'ADD_TO_WISHLIST',
  'UPDATE_WISHLIST'
];

export default syncActions;
