import React, { useState } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  Box
} from '@material-ui/core';
import _ from 'lodash';

import PlaceHolder from '../../../../assets/images/image_placeholder.png';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Favorite from '@material-ui/icons/Favorite';

const GridItem = ({
  item,
  handleGridClick,
  convertToRs,
  storeName,
  userCartData,
  itemIdArray,
  addItem,
  removeItem,
  handleWishList,
  isWishlisted
}) => {
  var quantity = 0;
  var cartSelectedVariants = _.filter(userCartData.cartData, { _id: item._id });
  if (item.variants.length)
    for (var i = 0; i < cartSelectedVariants.length; i++) {
      quantity = quantity + cartSelectedVariants[i].quantity;
    }
  else {
    var index = userCartData.cartData?.findIndex((x) => x._id === item._id);
    if (index > -1) quantity = userCartData.cartData[index].quantity;
  }
  let allImages = item.image
    ? [item.image, ...item.imagesList]
    : item.imagesList;
  const [loaded, setLoaded] = useState(false);
  return (
    <Box key={item._id} width="100%">
      <Box
        borderRadius={5}
        overflow="hidden"
        position="relative"
        style={{
          height: '211px',
          backgroundColor: '#f3f3f3',
          cursor: 'pointer'
        }}
        onClick={(e) => {
          handleGridClick(e, item, storeName);
        }}
      >
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {loaded ? null : <div className="loader small-loader"></div>}
          {!(allImages && allImages.length !== 0) ? (
            <img
              style={{ margin: 'auto', backgroundColor: '#f3f3f3' }}
              src={PlaceHolder}
              alt={PlaceHolder}
              onLoad={() => setLoaded(true)}
            />
          ) : (
              <img
                className="item-img-grid"
                src={allImages[0]}
                alt={PlaceHolder}
                height="100%"
                width="100%"
                zIndex={0}
                style={loaded ? {} : { display: 'none' }}
                onLoad={() => setLoaded(true)}
                onError={(e) => {
                  e.target.src = PlaceHolder;
                  e.target.style =
                    'height:40px;width:40px;background-color:#f3f3f3;margin:auto';
                }}
              />
            )}
        </Box>
        <Box width="100%">
          {item.mrp > item.price ? (
            <Box
              py="5px"
              textAlign="center"
              width="45%"
              fontWeight="bold"
              position="absolute"
              top={0}
              left={0}
              className={
                item.tagsList && item.tagsList.length
                  ? 'item-save-price-text'
                  : 'item-save-price-text-tag'
              }
            >
              {convertToRs(item.mrp, item.price)} OFF
            </Box>
          ) : null}
          {item.tagsList && item.tagsList.length ? (
            <Box
              py="5px"
              textAlign="center"
              width="55%"
              fontWeight="bold"
              position="absolute"
              top={0}
              right={0}
              // className="taglist"
              className={item.mrp > item.price ? 'taglist' : 'taglist-off'}
            >
              {item.tagsList[0]}
            </Box>
          ) : null}
        </Box>
      </Box>

      <Box style={{ marginTop: 15, marginBottom: 10 }}>
        <Grid
          // onClick={(e) => {
          //     handleGridClick(item, storeName);
          // }}
          container
          item
          xs={12}
          direction="column"

        >
          <Grid container
            item
            xs={12}
            direction="row"
            justify='space-between'
          >

            <Grid
              item
              xs={10}


            >
              <Box className="item-itemName">
                <Box
                  onClick={(e) => {
                    handleGridClick(e, item, storeName);
                  }}
                  fontWeight="fontWeightMedium"
                  style={{
                    fontSize: 16,
                    cursor: 'pointer'
                  }}
                >
                  {item.itemType}
                </Box>


              </Box>
            </Grid>
            <Grid
              item
              onClick={() => {
                handleWishList(item);
              }}
            >
              {isWishlisted ? <Favorite style={{ color: '#5c4db1', fontSize: 15 }} /> : <FavoriteBorderIcon style={{ color: '#5c4db1', fontSize: 15 }} />}
            </Grid>
          </Grid>

          <Typography
            className="item-type"
            style={{ fontSize: 13, marginTop: 3 }}
          >
            {item.category}
          </Typography>

          {/* {item.tagsList && item.tagsList.length ? (
                        <Typography>
                            <span className="taglist">{item.tagsList[0]}</span>
                        </Typography>
                    ) : null} */}

          <Grid
            item
            container
            xs={12}
            style={{
              // cursor: 'pointer',
              marginTop: 3
            }}
            direction="row"
          >
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box mr={2}>
                <Typography
                  variant="body2"
                  color="textPrimary"
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold'
                  }}
                  className="item-price"
                >
                  &#8377; {item.price}
                </Typography>
              </Box>

              <Box
              // borderRadius={5}
              // overflow='hidden' border={1} borderColor="#f4f4f4"
              // style={{ backgroundColor: 'white' }}
              >
                <Grid container className="add-item-outer-container">
                  {userCartData.cartData?.findIndex(
                    (x) => x._id === item._id
                  ) === -1 ? (
                      <Grid
                        className="list-item-add-button"
                        item
                        // onClick={handleOpen}
                        onClick={
                          itemIdArray.includes(item._id) &&
                            userCartData.onAddItemLoader
                            ? null
                            : () => addItem(item)
                        }
                      >
                        {userCartData.onAddItemLoader ? (
                          <Box width="100%">
                            {itemIdArray.includes(item._id) ? (
                              <Box
                                display="flex"
                                minWidth="55px"
                                justifyContent="center"
                                className="circular"
                              >
                                <CircularProgress size={16} />
                              </Box>
                            ) : (
                                <Box
                                  // variant="subtitle2"
                                  className="addButton"
                                >
                                  ADD
                                </Box>
                              )}
                          </Box>
                        ) : (
                            <>
                              <Box className="addButton">ADD</Box>
                            </>
                          )}
                      </Grid>
                    ) : (
                      // <Box className="add-item-outer-container">
                      <Box
                        display="flex"
                        className="add-item-inner-container"
                        style={{ fontSize: '14px' }}
                      >
                        <Box
                          onClick={
                            itemIdArray.includes(item._id) &&
                              userCartData.onAddItemLoader
                              ? null
                              : () => removeItem(item)
                          }
                          textAlign="center"
                          className="add-item-minus-container"
                        >
                          <Typography
                            textAlign="center"
                            style={{ color: '#5C4DB1' }}
                          >
                            {'-'}
                          </Typography>
                        </Box>
                        <Box
                        // display='flex' px="10px" justifyContent='center'
                        >
                          {userCartData.onAddItemLoader ? (
                            <Box>
                              {itemIdArray.includes(item._id) ? (
                                <Box
                                  minWidth="32px"
                                  height="100%"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <CircularProgress size={16} />
                                </Box>
                              ) : (
                                  <Typography className="add-item-counter">
                                    {quantity}
                                  </Typography>
                                )}
                            </Box>
                          ) : (
                              <Typography className="add-item-counter">
                                {quantity}
                              </Typography>
                            )}
                        </Box>
                        <Box
                          onClick={
                            itemIdArray.includes(item._id) &&
                              userCartData.onAddItemLoader
                              ? null
                              : () => addItem(item)
                          }
                          textAlign="center"
                          className="add-item-plus-container"
                        >
                          <Typography
                          // className={classes.discount_text}
                          // style={{ color: '#5C4DB1' }}
                          >
                            {'+'}
                          </Typography>
                        </Box>
                        {/* </Box> */}
                      </Box>
                    )}
                </Grid>
              </Box>
            </Box>
          </Grid>
          {item.mrp !== 0 && item.mrp ? (
            <Typography className="item-type item-strike-through-price">
              &#8377; {item.mrp}
            </Typography>
          ) : null}
        </Grid>
      </Box>
    </Box>
  );
};

export default GridItem;
