import React, { useEffect, useState } from 'react';
import InnerAppBar from '../Header/innerAppBar';
import MainHeader from '../Header/mainHeader';
import {
    Box,
    Typography,
    Grid,
    Container,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import ListCustomItem from '../Tabs/Home/components/listView';
import MenuIcon from '@material-ui/icons/Menu';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import EmptyResult from '../Tabs/Home/components/emptySearch';


function WishList({ userCartData, storeData, wishlist }) {
    const [toggle, setToggle] = React.useState(
        true
    );
    const useStyles = makeStyles((theme) => ({
        rootBgColor: {
            [theme.breakpoints.down("md")]: {
                backgroundColor: 'transparent',
            },
            [theme.breakpoints.up("md")]: {
                backgroundColor: 'white',
            }
        },

    }));
    const classes = useStyles();
    const [wishListItems, setWishListItems] = useState([]);
    useEffect(() => {
        const storeId = window.sessionStorage.getItem('storeLinkingId');
        // console.log(storeId, 'storeLinkingId')
        let wishlistItemData = wishlist.wishlistData.filter(el => el.storeId === storeId);
        setWishListItems(wishlistItemData);

    }, [wishlist])

    return (<div className="main-div-in" style={{ minHeight: "100vh" }}>
        <MainHeader />
        <Container>
            <InnerAppBar title={'Wishlist'}
                backspace={true} bgColor={false} userCartData={userCartData} />
            <Box
                // mt={[2, 2, 4, 4]}
                // minHeight="75vh"
                // // p={[1, 1, 3, 3]}
                // px={[1, 1, 3, 3]}
                className={classes.rootBgColor}
            >
                <Box display="flex" flexDirection="column" my={1}>
                    <Box py={5} style={{ padding: 20 }}>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sm
                                container
                            // alignItems="center"
                            >
                                <Grid
                                    item
                                    xs
                                // alignItems="center"
                                >
                                    <Typography className="tabs-heading semiBold">
                                        {wishListItems.length} items
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Box style={{ cursor: 'pointer' }}>
                                        <Box
                                            className="cat-toggle-icon-container"
                                            py="5px"
                                        >
                                            {true ? (
                                                <MenuIcon
                                                    onClick={() => {
                                                        // console.log(itemViewFormat, 'itemViewFormat')
                                                        // itemViewFormat = 'list';
                                                        setToggle(!toggle);
                                                    }}
                                                />
                                            ) : (
                                                    <ViewComfyIcon
                                                        onClick={() => {
                                                            //   itemViewFormat = 'grid';

                                                            setToggle(!toggle);
                                                        }}
                                                    />
                                                )}
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    {wishListItems.length ?
                        <Box>
                            <ListCustomItem
                                toggle={toggle}
                                products={wishListItems ?? []}
                                storeName={storeData.storeData.storeName}
                                isMobile={false}
                            />
                        </Box> : <Grid
                            container
                            direction="column"
                            style={{
                                justify: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <EmptyResult classes={classes} isMobile={window.innerWidth < 900 ? true : false} />
                        </Grid>
                    }
                </Box>

            </Box>


        </Container>
    </div>);
}


const mapStateToProps = ({ userCartData, wishlist, storeData }) => ({
    userCartData,
    storeData,
    wishlist
});

export default connect(mapStateToProps)(WishList);