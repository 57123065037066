import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TwitterIcon from '@material-ui/icons/Twitter';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { Box } from '@material-ui/core';

import { navigateToHome } from '../../utils/history';
import LogoImage from '../../assets/images/logo.svg';
import NewInvoice from './components/newInvoice';
import OldInvoice from './components/oldInvoice';
import PageNotFound from '../PageNotFound';
import './invoice_styles.scss';

const Index = () => {
  let store_id;
  let order_id;

  const [loader, setLoader] = useState(true);
  const [toggle, setToggle] = useState(false);
  // const [toggle, setToggle] = useState(true);

  const [apiError, setApiError] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [storeId, setStoreId] = useState(null);
  const [whatsappUrl, setWhatsappUrl] = useState(null);
  const [twitterUrl, setTwitterUrl] = useState(null);
  const [shareUrl, setShareurl] = useState(null);

  const [invoiceData, setInvoiceData] = useState(null);
  const [items, setItems] = useState([]);
  const [subTotal, setSubTotal] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [deliveryCharges, setDeliveryAmount] = useState(null);
  const [packagingCharges, setPackagingAmount] = useState(null);
  const [orderDate, setOrderDate] = useState(null);
  const [totalTaxes, setTotalTaxes] = useState(null);
  const [discountPercent, setDiscountPercent] = useState(null);

  // New Invoice States
  const [showTaxColumn, setShowTaxColumn] = useState(false);
  const [showHSNColumn, setShowHSNColumn] = useState(false);

  let shareText = 'Access the invoice at url ';

  useEffect(() => {
    const getInVoiceData = async () => {
      let url = window.location.href.split('/');
      if (url.length > 3) {
        store_id = url[url.length - 2];
        order_id = url[url.length - 1];
      }

      try {
        setStoreId(store_id);

        let res = await axios.get(
          `https://api.vardaan.app/invoice/${store_id}/${order_id}`
        );

        const data = res.data.response.data[0];
        setInvoiceData(data);
        setItems(data.items);
        setLoader(false);
      } catch (err) {
        console.log('error', err);
        setApiError(true);
        setLoader(false);
      }
    };
    getInVoiceData();
    let url = encodeURI(window.location.href);
    setTwitterUrl(
      `http://www.twitter.com/intent/tweet?text=${shareText}${url}`
    );
    setWhatsappUrl(`https://wa.me/?text=${shareText}${url}`);
    setShareurl(`${shareText}${url}`);
  }, [storeId]);

  useEffect(() => {
    let sumSubTotal = 0;
    let taxes = 0;
    for (let i = 0; i < items.length; i++) {
      sumSubTotal = sumSubTotal + items[i].price * items[i].quantity;
      taxes =
        taxes + (items[i].priceWithGst - items[i].price) * items[i].quantity;
    }
    setSubTotal(Math.round(sumSubTotal * 100) / 100);
    setTotalTaxes(Math.round(taxes * 100) / 100);

    if (invoiceData) {
      setOrderDate(
        invoiceData.orderDate.split(' ')[0] +
        ' ' +
        invoiceData.orderDate.split(' ')[1] +
        ' ' +
        invoiceData.orderDate.split(' ')[2]
      );
      let disc =
        (Math.abs(invoiceData.discount) * 100) / (subTotal + totalTaxes);
      setDiscountPercent(Math.round(disc * 100) / 100);
      let _additional = 0;
      if (
        invoiceData.additionalCharges &&
        invoiceData.additionalCharges.length
      ) {
        invoiceData.additionalCharges.forEach((ele) => {
          _additional = _additional + ele.cost;
        });
      } else {
        _additional =
          _additional +
          invoiceData.deliveryCharges +
          invoiceData.packagingCharges;
      }
      setTotalAmount(
        subTotal +
        totalTaxes -
        Math.round(Math.abs(invoiceData.discount) * 100) / 100 +
        Math.round(Math.abs(_additional) * 100) / 100
      );
      setDeliveryAmount(
        Math.round(Math.abs(invoiceData.deliveryCharges) * 100) / 100
      );
      setPackagingAmount(
        Math.round(Math.abs(invoiceData.packagingCharges) * 100) / 100
      );

      // Checking For the Tax And Hsn Columns For New Invoice
      for (let i = 0; i < items.length; i++) {
        if (items[i].gst > 0) {
          setShowTaxColumn(true);
          break;
        }
      }
      for (let i = 0; i < items.length; i++) {
        if (items[i].hsnCode) {
          setShowHSNColumn(true);
          break;
        }
      }
    }
  }, [items]);

  const handleCloseModal = () => {
    setIsModal(false);
    setIsCopied(false);
  };
  return (
    <>
      {items.length > 0 && (
        <div>
          {/* // HEADER START */}
          {items.length > 0 && (
            <div style={navContainer}>
              {window.location.href.startsWith('https://apandukan.co/') ? (
                <Box className="logo " onClick={() => navigateToHome()}>
                  <img src={LogoImage} />
                </Box>
              ) : (
                  <Box></Box>
                )}

              <div
                style={{
                  marginRight: '2%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <button
                  style={topButtonPrint}
                  title="Print"
                  onClick={() => window.print()}
                >
                  <PrintOutlinedIcon style={topNavIcon} />
                </button>
                <button
                  style={topButton}
                  title="Share"
                  onClick={() => setIsModal(true)}
                >
                  <ShareOutlinedIcon style={topNavIcon} />
                </button>
              </div>
            </div>
          )}

          {/* // MODAL BLOCK START */}
          <Modal
            isOpen={isModal}
            contentLabel="onRequestClose Example"
            onRequestClose={handleCloseModal}
            style={ModalContainer}
          >
            {isCopied && <p style={copySuccessMessage}>Copied!</p>}
            <div
              style={{
                position: 'absolute',
                top: 10,
                right: 25,
                cursor: 'pointer',
                fontSize: 18
              }}
              onClick={handleCloseModal}
            >
              <CancelOutlinedIcon />
            </div>

            <p
              style={{
                textAlign: 'center',
                fontSize: 16,
                fontWeight: 'bold'
              }}
            >
              Share To!
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                paddingLeft: 20,
                paddingRight: 20
              }}
            >
              <a href={whatsappUrl} target="_blank" style={whatsappContainer}>
                <WhatsAppIcon
                  style={{ width: 45, borderRadius: 10, fontSize: 35 }}
                />
              </a>

              <a href={twitterUrl} target="_blank" style={twitterContainer}>
                <TwitterIcon
                  style={{ width: 45, borderRadius: 10, fontSize: 35 }}
                />
              </a>
              <CopyToClipboard text={shareUrl} onCopy={() => setIsCopied(true)}>
                <span style={copyClipBoard}>
                  <FileCopyOutlinedIcon style={topNavIconCopy} />
                </span>
              </CopyToClipboard>
            </div>
          </Modal>

          {loader && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              Loading...
            </div>
          )}

          {items.length > 0 && !toggle && (
            <OldInvoice
              orderDate={orderDate}
              invoiceData={invoiceData}
              items={items}
              discountPercent={discountPercent}
              subTotal={subTotal}
              deliveryCharges={deliveryCharges}
              packagingCharges={packagingCharges}
              totalAmount={totalAmount}
              showTaxColumn={showTaxColumn}
              showHSNColumn={showHSNColumn}
              totalTaxes={totalTaxes}
            />
          )}

          {items.length > 0 && toggle && (
            <NewInvoice
              orderDate={orderDate}
              invoiceData={invoiceData}
              items={items}
              discountPercent={discountPercent}
              subTotal={subTotal}
              totalAmount={totalAmount}
              deliveryCharges={deliveryCharges}
              packagingCharges={packagingCharges}
              showTaxColumn={showTaxColumn}
              showHSNColumn={showHSNColumn}
            />
          )}
        </div>
      )}

      {/* // ERROR API UI  START */}
      {!loader && (items.length === 0 || apiError) && <PageNotFound />}
    </>
  );
};

export default Index;

const topButton = {
  backgroundColor: 'transparent',
  color: 'black',
  border: 'none',
  marginRight: '10px',
  cursor: 'pointer',
  outline: 'none'
};

const topButtonPrint = {
  backgroundColor: 'transparent',
  color: 'black',
  border: 'none',
  marginRight: '1.5%',
  marginLeft: '1.5%',
  cursor: 'pointer',
  outline: 'none'
};

const navContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0% 2%',
  width: '100%',
  height: '50px',
  lineHeight: '50px',
  position: 'fixed',
  zIndex: 2,
  top: 0,
  backgroundColor: '#5c4db1',
  boxShadow: '0 5px 10px 0 rgba(2, 61, 121, 0.07)'
};

const topNavIcon = {
  color: '#fff',
  fontSize: 20
};

const topNavIconCopy = {
  color: '#000',
  fontSize: 20
};

const ModalContainer = {
  content: {
    position: 'absolute',
    width: '22%',
    height: 130,
    padding: '1.5rem 3rem',
    backgroundColor: '#ffffff',
    border: '1px solid #d0cccc',
    boxShadow:
      '0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17)',
    zIndex: 99,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '100vh',
    overflowY: 'auto'
  }
};

const whatsappContainer = {
  background: '#02E675',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  borderRadius: 10,
  height: 45
};

const twitterContainer = {
  background: '#1DA2F1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  borderRadius: 10,
  height: 45
};

const copyClipBoard = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  background: ' #e6e6e6',
  width: '45px',
  borderRadius: '10px',
  height: '45px'
};

const copySuccessMessage = {
  fontWeight: 'bold',
  color: 'green',
  position: 'absolute',
  top: 10,
  left: '40%',
  transform: 'translate(0px, -50%)'
};
