import React, {useState} from 'react';
import {Typography, Grid, Tabs, Tab, Box} from '@material-ui/core';
import PropTypes from 'prop-types';
import {useMarketHomeHook} from '../hooks';
import {useStyles} from '../style';
import '../style.scss';
import StoreListItem from './StoreListItem';

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps (index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
function TabPanel (props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index &&
        <Box>
          <Typography style={{padding: 0}} variant="subtitle1">
            {children}
          </Typography>
        </Box>}
    </div>
  );
}
const Category = () => {
  const categories = ['All', 'Grocery', 'Vegetable', 'Bakery', 'Application'];
  const {getVisitedStore} = useMarketHomeHook ();
  const classes = useStyles ();
  const [value, setValue] = React.useState (0);
  const [category, setCategory] = useState ('All');
  const handleChange = (event, newValue) => {
    setValue (newValue);
    // console.log(newValue);
    // var filtered = _.filter(storeData.storeData.items, ['category', categories[newValue]]);

    // if (filtered.length == 0) {
    //   filtered = storeData.storeData.items;
    // }
    // setProducts(filtered)
  };

  // return <div className="home-loader" style={{ fontSize: 20 }}>
  return (
    <React.Fragment>
      <div className="categorycontainer">
        <Typography>Category</Typography>
        <Tabs
          indicatorColor="secondary"
          onChange={handleChange}
          value={value}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          className="bg-white"
        >
          {categories.map ((item, index) => (
            <Tab
              style={{
                margin: 10,
                backgroundColor: value === index ? '#5C4DB1' : '#FFFFFF',
                borderRadius: 15,
                border: 'solid',
                borderWidth: 1,
                borderColor: '#EBEBEB',
                color: value === index ? '#FFFFFF' : '#5C4DB1',
              }}
              key={index}
              label={item}
              {...a11yProps (index)}
            />
          ))}
        </Tabs>
        {categories.map ((item, index) => {
          return (
            <TabPanel
              key={index}
              value={value}
              index={index}
              className="bg-white"
            >
              <Grid item>
                <Grid item xs={12} sm container>
                  <Grid item xs>
                    <Typography gutterBottom>{item} store(s)</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <StoreListItem />
            </TabPanel>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default Category;
