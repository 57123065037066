import React, {useState, useEffect} from 'react';
import {Typography, Button, Box, CircularProgress} from '@material-ui/core';
// import { useStyles } from '../style';
import '../style.scss';

const Credentials = ({phone, setPhone, onPhoneNumberSubmit}) => {
  // const classes = useStyles();
  const [isError, setIsError] = useState (false);
  const [disable, setDisable] = useState (false);

  useEffect (
    () => {
      if (phone.length > 0) if (validate ()) setIsError (false);
    },
    [phone]
  );

  const validate = () => {
    if (phone.match (/^[0]?(91)?[6789]\d{9}$/)) {
      return true;
    } else {
      setIsError (true);
      return false;
    }
  };

  const handleSubmit = e => {
    e.preventDefault ();
    if (validate ()) {
      setDisable (true);
      onPhoneNumberSubmit ();
    }
  };

  const handleChange = e => setPhone (e.target.value);

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
    >
      {/* <React.Fragment > */}
      <div id="recaptcha-container" />

      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <form onSubmit={handleSubmit}>
          <Box pb={3}>
            <Typography variant="h6">
              Please Login
            </Typography>
          </Box>
          <Box pb={2} fontWeight={400}>
            <Typography>
              Enter Mobile Number
            </Typography>
          </Box>
          <Box pb={2}>
            <Box className="left-inner-addon">
              <Typography className="prefix-text">+91</Typography>
              <input
                fullWidth
                className="left-inner-addon-input"
                value={phone}
                variant="outlined"
                type="number"
                onChange={e => handleChange (e)}
              />
            </Box>
            {/* {isError && (<Typography className="phone-error">{"Invalid Phone Number"}</Typography>)} */}
            <Typography className="phone-error">
              {isError ? 'Invalid Phone Number' : ''}
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              fullWidth
              disabled={disable}
            >
              {disable ? <CircularProgress size={16} /> : null}
              <Box pl={1}>Continue</Box>
            </Button>
          </Box>
        </form>
      </Box>
      {/* </React.Fragment> */}
    </Box>
  );
};
export default Credentials;
