import React, { useEffect, useState } from 'react';
import './style.scss';
import { connect } from 'react-redux';
import {
  Typography,
  Grid,
  TextField,
  Container,
  Box,
  Divider,
  useMediaQuery
} from '@material-ui/core';
import { useFormik } from 'formik';
import { toastr } from 'react-redux-toastr';
import { useLocation } from 'react-router-dom';

import { useAccountHook } from './hooks';
import { useStyles } from './style';
import Image from './components/image';
import AddressList from './components/addressList';
import AddAddressButton from './components/addAddressButton';
import Logout from './components/logout';
import LoginAppBarContainer from './components/loginAccountAppBar';
import { backTo } from '../../../utils/history';
import LoginScreen from '../../Login/Components/LoginScreen';
import { getLocalStorageTokens } from '../../../utils/tokensHelper';
import InnerAppBar from '../../Header/innerAppBar';
import MainHeader from '../../Header/mainHeader';

const validate = ({ username, email }) => {
  const errors = {};
  if (!username) {
    errors.username = 'Required';
  } else if (!/^[a-zA-Z ]*$/.test(username)) {
    errors.username = 'Name is not valid';
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) &&
    email
  ) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

const Account = ({ userData, userCartData }) => {
  const mediumViewport = useMediaQuery('(min-width:960px)');
  const largeViewport = useMediaQuery('(min-width:1280px)');
  const { isLoggedIn } = getLocalStorageTokens();
  var accountUserInfo = userData.user;
  const { updateUserAuthentication } = useAccountHook();
  const classes = useStyles();
  const location = useLocation();
  const pathName = location.pathname;
  const formik = useFormik({
    initialValues: {
      username: accountUserInfo?.username,
      email: accountUserInfo?.email,
      phoneno: accountUserInfo?.phoneno
    },
    validate,
    onSubmit: (values) => {
      if (pathName == '/account-login') {
        handleLoginScreenForm(values);
      } else {
        handleSave(values);
      }
    }
  });


  const handleSave = (key, values) => {
    if (Object.keys(formik.errors).length > 0) {
      toastr.error('Fix the errors to save the data');
      return;
    }
    if (key === 'username' && accountUserInfo['username'] !== values) {
      accountUserInfo['username'] = values;
      updateUserAuthentication(accountUserInfo);
    } else if (key === 'email' && accountUserInfo['email'] !== values) {
      accountUserInfo['email'] = values;
      updateUserAuthentication(accountUserInfo);
    }
  };

  const handleLoginScreenForm = async (values) => {
    accountUserInfo['username'] = values.username;
    accountUserInfo['email'] = values.email;
    await updateUserAuthentication(accountUserInfo);
    backTo();
  };

  if (!isLoggedIn) {
    window.localStorage.setItem('redirect-to', 'account');
    return <LoginScreen />;
  }
  return (
    <Box
      className="main-div"
      // minHeight={['calc(100vh - 56px)', 'calc(100vh - 56px)', '100%', '100%']}
      style={{ minHeight: "100vh" }}
    >
      {pathName == '/account-login' ? (
        <LoginAppBarContainer route="Account" userCartData={userCartData} />
      ) : (
        <MainHeader />
      )}
      {/* <AppBarContainer route="Account" userCartData={userCartData} /> */}
      <Container
        className={classes.containerPadding}
      // className="items-container"
      >
        <Box pt={[3, 3, 0, 0]}>
          {pathName == '/account-login' ? (
            <Box
              pt={['56px', '56px', 5, 5]}
              marginTop={largeViewport || mediumViewport ? '56px' : '0px'}
            ></Box>
          ) : (
            <InnerAppBar
              title="Account"
              backspace={false}
              bgColor={false}
              userCartData={userCartData}
            />
          )}
          <form onSubmit={formik.handleSubmit}>
            {pathName == '/account-login' ? (
              <Box
                height="100%"
                className={classes.rootBgColor}
                px={[2, 2, 8, 12]}
              >
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Image accountUserInfo={accountUserInfo} />

                    <Box
                    // className={classes.gridItem}
                    >
                      <Typography className={classes.label}>
                        Full Name
                      </Typography>
                    </Box>
                    <Box
                    // className={classes.gridItem}
                    >
                      <TextField
                        name="username"
                        id="outlined-basic"
                        placeholder="Name"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: false
                        }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes.inputField,
                            input: classes.resize
                          }
                        }}
                        value={formik.values.username}
                        onChange={(e) => {
                          e.persist();
                          formik.handleChange(e);
                        }}
                        onBlur={(e) => {
                          handleSave('username', e.target.value);
                        }}
                      />
                      {formik.errors.username ? (
                        <Typography className={classes.error}>
                          {formik.errors.username}
                        </Typography>
                      ) : null}
                    </Box>
                    <Typography className={classes.label}>Email ID</Typography>
                    <TextField
                      name="email"
                      id="outlined-basic"
                      placeholder="Email"
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: false
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.inputField,
                          input: classes.resize
                        }
                      }}
                      value={formik.values.email}
                      onChange={(e) => {
                        e.persist();
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        handleSave('email', e.target.value);
                      }}
                    />
                    {formik.errors.email ? (
                      <Typography className={classes.error}>
                        {formik.errors.email}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box className={classes.rootBgColor}>
                <Box px={[2, 2, 8, 12]} py={3}>
                  <Grid container>
                    <Grid item container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Image accountUserInfo={accountUserInfo} />
                      </Grid>
                      <Grid item xs={12} md={6} className={classes.cardBgColor}>
                        <Box
                        // className={classes.gridItem}
                        >
                          <Typography className={classes.label}>
                            Full Name
                          </Typography>
                        </Box>
                        <Box
                        // className={classes.gridItem}
                        >
                          <TextField
                            name="username"
                            id="outlined-basic"
                            placeholder="Name"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                              shrink: false
                            }}
                            InputProps={{
                              classes: {
                                notchedOutline: classes.inputField,
                                input: classes.resize
                              }
                            }}
                            value={formik.values.username}
                            onChange={(e) => {
                              e.persist();
                              formik.handleChange(e);
                            }}
                            //onChange={(e) => setusername(e.target.value)}
                            onBlur={(e) => {
                              handleSave('username', e.target.value);
                            }}
                          />
                          {formik.errors.username ? (
                            <Typography className={classes.error}>
                              {formik.errors.username}
                            </Typography>
                          ) : null}
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      spacing={3}
                      className={classes.cardBgColor}
                    >
                      <Grid item xs={12} md={6}>
                        <Box
                        // className={classes.gridItem}
                        >
                          <Typography className={classes.label}>
                            Mobile Number
                          </Typography>
                        </Box>

                        <Box
                        // className={classes.gridItem}
                        >
                          <TextField
                            disabled
                            id="outlined-basic"
                            variant="outlined"
                            value={formik.values.phoneno}
                            fullWidth
                            InputProps={{
                              classes: {
                                notchedOutline: classes.inputField,
                                input: classes.resize
                              }
                            }}
                            InputProps={{
                              classes: {
                                notchedOutline: classes.inputField,
                                input: classes.resize
                              }
                            }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box
                        // className={classes.gridItem}
                        >
                          <Typography className={classes.label}>
                            Email ID
                          </Typography>
                        </Box>
                        <Box
                        // className={classes.gridItem}
                        >
                          <TextField
                            name="email"
                            id="outlined-basic"
                            placeholder="Email"
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                              shrink: false
                            }}
                            InputProps={{
                              classes: {
                                notchedOutline: classes.inputField,
                                input: classes.resize
                              }
                            }}
                            value={formik.values.email}
                            onChange={(e) => {
                              e.persist();
                              formik.handleChange(e);
                            }}
                            onBlur={(e) => {
                              handleSave('email', e.target.value);
                            }}
                          />
                          {formik.errors.email ? (
                            <Typography className={classes.error}>
                              {formik.errors.email}
                            </Typography>
                          ) : null}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />

                <Box pb={2} pt={2}>
                  <Box px={[2, 2, 8, 12]}>
                    <AddAddressButton />
                  </Box>
                  <Box px={[2, 2, 8, 12]}>
                    <AddressList accountUserInfo={accountUserInfo} />
                  </Box>
                </Box>
              </Box>
            )}
          </form>
          {pathName == '/account-login' ? null : (
            <Box
              display={['block', 'block', 'none', 'none']}
              className="logout-holder"
            >
              <Logout />
            </Box>
          )}
        </Box>
      </Container>
      <Box style={{ minHeight: "5vh" }}></Box>
    </Box>
  );
};

const mapStateToProps = ({ userData, userCartData }) => ({
  userData,
  userCartData
});

export default connect(mapStateToProps)(Account);
