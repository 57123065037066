import React from 'react';
import {
    Grid,
    Paper,
    ButtonBase,
    Typography,
    CircularProgress,
    Box
} from '@material-ui/core';
import _ from 'lodash';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Favorite from '@material-ui/icons/Favorite';
import PlaceHolder from '../../../../assets/images/image_placeholder.png';


const ListItem = ({
    item,
    handleGridClick,
    convertToRs,
    storeName,
    userCartData,
    itemIdArray,
    addItem,
    removeItem,
    classes,
    handleWishList,
    isWishlisted
}) => {

    var quantity = 0;
    var cartSelectedVariants = _.filter(userCartData.cartData, { _id: item._id });
    if (item.variants.length)
        for (var i = 0; i < cartSelectedVariants.length; i++) {
            quantity = quantity + cartSelectedVariants[i].quantity;
        }
    else {
        var index = userCartData.cartData?.findIndex((x) => x._id === item._id);
        if (index > -1)
            quantity = userCartData.cartData[index].quantity;
    }
    let allImages = item.image
        ? [item.image, ...item.imagesList]
        : item.imagesList;



    return (

        <Box style={{
            // borderBottom: '0.5px solid #c8d2d858',
            // height: 100
        }} >
            <Paper key={item._id}
                // className={classes.paper} 
                style={{
                    ' -webkit-box-shadow': 'none',
                    '-moz-box-shadow': 'none',
                    boxShadow: ' none'
                }} >
                <Box py={1} display='flex' justifyContent='space-between'
                    style={{ flexWrap: 'nowrap', margin: 0 }}
                >
                    <Box position='relative' onClick={(e) => handleGridClick(e, item, storeName)}>
                        <ButtonBase
                            style={{
                                width: 92,
                                height: 92,
                                marginLeft: 8,
                                backgroundColor: '#f3f3f3',
                                borderRadius: 8
                            }}
                        // className={classes.image}
                        >
                            {!(allImages && allImages.length !== 0) ? (
                                <img src={PlaceHolder}></img>
                            ) : (
                                <img
                                    className="item-img"
                                    src={allImages[0]}
                                    // height={70}
                                    // width={70}
                                    onError={(e) => {
                                        e.target.src = PlaceHolder;
                                        e.target.height = '30px';
                                        e.target.style =
                                            'height:30px;width:30px;background-color:#f3f3f3;padding:20px; ';
                                    }}
                                />
                            )}

                            {item.mrp > item.price ? (
                                <Box position="absolute" bottom="-10px" p={1} fontWeight='bold'
                                    // style={{ fontSize: '10px', padding: 3, position: 'absolute', bottom: '-10px' }}
                                    className="discount-item-list"
                                >
                                    {convertToRs(item.mrp, item.price)} OFF
                                </Box>
                            ) : null}
                        </ButtonBase>
                    </Box>

                    <Box
                        px={2}
                        width="100%"
                        display='flex'
                        justifyContent='space-between'
                        className="list-detail">
                        <Box
                            display="flex"
                            alignItems="start"
                            flexDirection='column'
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => handleGridClick(e, item, storeName)}>
                            <Box >
                                <Typography
                                    fontWeight="fontWeightMedium"
                                    className='fontMedium'
                                >
                                    {item.itemType}
                                </Typography>
                            </Box>

                            <Typography
                                variant="body2"
                                gutterBottom
                                style={{
                                    fontSize: 14
                                }}
                                className="item-type"
                            >
                                {item.category}
                            </Typography>
                            {
                                item.tagsList && item.tagsList.length
                                    ? (
                                        <Box px={1} py="3px" className="taglist" borderRadius={5}>
                                            {item.tagsList[0]}
                                        </Box>
                                    ) : null
                            }
                            <Box pt="4px" pb="4px">
                                <Typography
                                    style={{
                                        fontSize: 16
                                    }}
                                    variant="body2"
                                    className="item-price fontMedium"
                                >
                                    &#8377; {item.price}
                                </Typography>

                                {
                                    item.mrp && item.mrp !== 0 && item.mrp > item.price ? (
                                        <Typography
                                            style={{
                                                fontSize: 16
                                            }}
                                            variant="body2"
                                            color="textSecondary"
                                            className='item-strike-through-price'
                                        >
                                            &#8377; {item.mrp}
                                        </Typography>
                                    ) : null
                                }

                            </Box>
                        </Box>
                        <Box id="hakuna">
                            <Grid container className="add-item-outer-container" style={{ maxHeight: "50%" }}>
                                {
                                    userCartData.cartData?.findIndex((x) => x._id === item._id) ===
                                        -1 ? (
                                        <Grid
                                            className="list-item-add-button"
                                            item
                                            onClick={
                                                itemIdArray.includes(item._id) &&
                                                    userCartData.onAddItemLoader
                                                    ? null
                                                    : () => addItem(item)
                                            }
                                        >
                                            {userCartData.onAddItemLoader ? (
                                                <>
                                                    {itemIdArray.includes(item._id) ? (
                                                        <Box
                                                            display='flex'
                                                            minWidth="55px"
                                                            justifyContent='center'
                                                            className="circular">
                                                            <CircularProgress size={20} />
                                                        </Box>
                                                    ) : (
                                                        <Typography
                                                            // variant="subtitle2"
                                                            className="addButton"
                                                        >
                                                            ADD
                                                        </Typography>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    <Typography className="addButton"
                                                    // variant="subtitle2"
                                                    >
                                                        ADD
                                                    </Typography>
                                                </>
                                            )}
                                        </Grid>
                                    ) : (
                                        <Box
                                            display='flex'
                                            className="add-item-inner-container"
                                            style={{ fontSize: '14px' }}
                                        >
                                            <Box
                                                onClick={
                                                    itemIdArray.includes(item._id) &&
                                                        userCartData.onAddItemLoader
                                                        ? null
                                                        : () => removeItem(item)
                                                }
                                                className="add-item-minus-container"
                                            >
                                                <Typography
                                                    textAlign="center" color='primary'
                                                >
                                                    {'-'}
                                                </Typography>
                                            </Box>
                                            <Box
                                            // display='flex' px="10px" justifyContent='center' 
                                            >
                                                {userCartData.onAddItemLoader ? (
                                                    <>
                                                        {itemIdArray.includes(item._id) ? (
                                                            <Box
                                                                minWidth="32px"
                                                                height="100%"
                                                                display='flex'
                                                                alignItems='center'
                                                                justifyContent='center'
                                                            >
                                                                <CircularProgress size={16} />
                                                            </Box>
                                                        ) : (
                                                            <Typography
                                                                className="add-item-counter"
                                                            >
                                                                {quantity}
                                                            </Typography>
                                                        )}
                                                    </>
                                                ) : (
                                                    <Typography
                                                        // className={classes.discount_text}
                                                        className="add-item-counter"
                                                    >
                                                        {quantity}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Box
                                                onClick={
                                                    itemIdArray.includes(item._id) &&
                                                        userCartData.onAddItemLoader
                                                        ? null
                                                        : () => addItem(item)
                                                }
                                                textAlign="center"
                                                className="add-item-plus-container"
                                            >
                                                <Typography color="primary"
                                                // className={classes.discount_text}
                                                >
                                                    {'+'}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    )}
                            </Grid>
                            <Box style={{ float: "right", marginTop: "1vh" }}
                                onClick={() => {
                                    handleWishList(item);
                                }}>
                                {isWishlisted ? <Favorite style={{ color: '#5c4db1', fontSize: 15 }} /> : <FavoriteBorderIcon style={{ color: '#5c4db1', fontSize: 15 }} />}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}
export default ListItem;


