import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Container,
  Box
} from '@material-ui/core';
import '../style.scss';
import AppBarContainer from './appBar';
import { useStyles } from '../style';
import { useLocation } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import { useFormik } from 'formik';
import csc from 'country-state-city';
import { useAccountHook } from '../hooks';
import { connect } from 'react-redux';
import { backTo } from '../../../../utils/history';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MainHeader from '../../../Header/mainHeader';
import InnerAppBar from '../../../Header/innerAppBar';


const validate = (values) => {
  const errors = {};
  if (!values.addName) {
    errors.addName = 'Required';
  } else if (!/^[a-zA-Z ]*$/.test(values.addName)) {
    // console.log('error!!!');
    errors.addName = 'Name is not valid';
  }
  if (!values.phoneno) {
    errors.phoneno = 'Required';
  } else if (!/^[6789]\d{9}$/.test(values.phoneno)) {
    errors.phoneno = 'Mobile number is not valid';
  }

  if (!values.pincode) {
    errors.pincode = 'Required';
  } else if (!/^[1-9][0-9]{5}$/.test(values.pincode)) {
    errors.pincode = 'Pincode is not valid';
  }

  if (!values.building) {
    errors.building = 'Required';
  }

  // if (!values.street) {
  //   errors.street = 'Required';
  // }

  if (!values.state) {
    errors.state = 'Required';
  }
  if (!values.city) {
    errors.city = 'Required';
  }

  return errors;
};

const Address = ({ userData, userCartData }) => {
  const classes = useStyles();
  const location = useLocation();
  const data = location.state;
  // console.log('datatattatatta', data);

  const [defaultAddress, setDefaultAddress] = useState(
    data ? data.default : false
  );

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const { updateUserAuthentication } = useAccountHook();
  const [savingAddress, setsavingAddress] = useState(false);
  const formik = useFormik({
    initialValues: {
      addName: data
        ? data.addName
        : userData.user
          ? userData.user.username
          : '',
      phoneno: data
        ? data.phoneno.toString().length === 12
          ? data.phoneno.toString().substr(2)
          : data.phoneno.toString().length === 13
            ? data.phoneno.toString().substr(3)
            : data.phoneno
        : userData.user
          ? userData.user.phoneno.toString().length === 12
            ? userData.user.phoneno.toString().substr(2)
            : userData.user.phoneno.toString().length === 13
              ? userData.user.phoneno.toString().substr(3)
              : userData.user.phoneno
          : '',
      pincode: data ? data.pincode : '',
      building: data ? data.building : '',
      street: data ? data.street : '',
      state: data
        ? data.state !== null
          ? data.state.charAt(0).toUpperCase() +
          data.state.slice(1).toLowerCase()
          : null
        : null,
      city: data ? data.city : ''
    },
    validate,
    onSubmit: (values) => {
      handleSave(values);
    }
  });
  var accountUserInfo = userData.user;

  useEffect(() => {
    accountUserInfo = userData.user;

    window.scrollTo(0, 0);
    let states = csc.getStatesOfCountry('101');
    setStates(states);
    if (formik.values.state) {
      let stateItem = states.filter((item) => {
        return item.name.toLowerCase() === formik.values.state.toLowerCase();
      });
      let cities = csc.getCitiesOfState(stateItem[0].id);
      setCities(cities);
    }
  }, []);

  const handleChange = () => {
    setDefaultAddress(!defaultAddress);
  };

  const findStateId = (name) => {
    if (name === null) {
      return;
    }

    states.forEach((currentItem) => {
      if (currentItem.name.toLowerCase() === name.toLowerCase()) {
        handleStateChange(currentItem.name, currentItem.id);
      }
    });
  };

  const handleStateChange = (name, id) => {
    formik.setFieldValue('state', name);
    let cities = csc.getCitiesOfState(id);
    setCities(cities);
    formik.setFieldValue('city', null);
  };

  const saveData = async (values) => {
    let framedAddress;

    if (defaultAddress) {
      framedAddress = accountUserInfo?.address.map((item) => {
        item['default'] = false;
        return item;
      });
      accountUserInfo.address = framedAddress;
    }

    var updateAddress = values;
    updateAddress['state'] = values.state;
    updateAddress['city'] = values.city;
    updateAddress['default'] = defaultAddress;

    if (!data) {
      accountUserInfo.address.push(updateAddress);
    } else {
      var index = accountUserInfo.address.findIndex(
        (item) => data._id === item._id
      );
      accountUserInfo.address[index] = updateAddress;
    }

    updateUserAuthentication(accountUserInfo);
  };

  const handleSave = async (values) => {
    // console.log(values);
    saveData(values).then(() => {
      setsavingAddress(false);
      setTimeout(backTo, 2000);
      // toastr.success('Address Saved Succesfully');
    });

    ///TODO Lat ang Long
  };

  if (savingAddress) {
    return (
      <div className="address-loader">
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box className="category-outer-container" minHeight="100vh">
      <MainHeader />

      {/* <AppBarContainer route={data ? 'Edit Address' : 'Add New Address'} /> */}

      {userData.loginSpinner ? (
        <div className="home-loader">
          <CircularProgress />
        </div>
      ) : (
          <Box >
            <Container className={classes.containerPadding}>

              <InnerAppBar userCartData={userCartData} backspace={true} title={data ? 'Edit Address' : "Add New Address"} />


              <form onSubmit={formik.handleSubmit}>
                <Grid
                  container
                  direction="column"
                  className={classes.AddressContainer}
                >
                  <Grid item xs={12}>
                    <Typography className={classes.label}>Full Name</Typography>
                    <TextField
                      autoComplete="off"
                      id="firstname"
                      placeholder="Full Name"
                      name="addName"
                      variant="outlined"
                      fullWidth
                      value={formik.values.addName}
                      InputLabelProps={{
                        shrink: false
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.inputField,
                          input: classes.resize
                        }
                      }}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    ></TextField>
                    {formik.errors.addName ? (
                      <Typography className={classes.error}>
                        {formik.errors.addName}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Typography className={classes.label}>Mobile Number</Typography>

                  <Grid className="left-inner-addon-address">
                    {/* <Typography className="prefix-text-address">+</Typography> */}
                    <input
                      className="left-inner-addon-input-address"
                      autoComplete="off"
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      name="phoneno"
                      type="number"
                      className="mobile-no-input"
                      value={formik.values.phoneno}
                      InputLabelProps={{
                        shrink: false
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.inputField,
                          input: classes.resize
                        }
                      }}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  {formik.errors.phoneno ? (
                    <Typography className={classes.error}>
                      {formik.errors.phoneno}
                    </Typography>
                  ) : null}
                  <Grid item xs={12}>
                    <Typography className={classes.label}>Pin Code</Typography>
                    <TextField
                      autoComplete="off"
                      id="outlined-basic"
                      placeholder="Pin Code"
                      name="pincode"
                      value={formik.values.pincode}
                      variant="outlined"
                      fullWidth
                      InputLabelProps={{
                        shrink: false
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.inputField,
                          input: classes.resize
                        }
                      }}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    ></TextField>
                    {formik.errors.pincode ? (
                      <Typography className={classes.error}>
                        {formik.errors.pincode}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.label}>
                      Flat, House no, Building
                    </Typography>
                    <TextField
                      autoComplete="off"
                      id="outlined-basic"
                      placeholder="Type Flat, House no, Building"
                      variant="outlined"
                      fullWidth
                      name="building"
                      value={formik.values.building}
                      InputLabelProps={{
                        shrink: false
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.inputField,
                          input: classes.resize
                        }
                      }}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    //onChange={(e) => setbuilding(e.target.value)}
                    ></TextField>
                    {formik.errors.building ? (
                      <Typography className={classes.error}>
                        {formik.errors.building}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.label}>
                      Area, Colony, Street
                    </Typography>
                    <TextField
                      autoComplete="off"
                      id="outlined-basic"
                      placeholder="Type Area, Colony, Street"
                      variant="outlined"
                      fullWidth
                      name="street"
                      value={formik.values.street}
                      InputLabelProps={{
                        shrink: false
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.inputField,
                          input: classes.resize
                        }
                      }}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                    ></TextField>
                    {formik.errors.street ? (
                      <Typography className={classes.error}>
                        {formik.errors.street}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} style={{ height: 40 }}>
                    <Typography className={classes.label}>State</Typography>
                    <Autocomplete
                      disablePortal={true}
                      value={formik.values.state}
                      options={states.map((s) => s.name)}
                      getOptionLabel={(stateItem) => stateItem}
                      onChange={(event, newValue) => {
                        // console.log(newValue);
                        // console.log(event);
                        findStateId(newValue);
                      }}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          type="text"
                          variant="outlined"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password' // disable autocomplete and autofill
                          }}
                        />
                      )}
                      className="dropdownContainer"
                    />
                    {formik.errors.state ? (
                      <Typography className={classes.error}>
                        {formik.errors.state}
                      </Typography>
                    ) : null}
                  </Grid>

                  <Grid item xs={12} style={{ height: 40 }}>
                    <Typography className={classes.label}>City/Town</Typography>
                    <Autocomplete
                      disablePortal={true}
                      value={formik.values.city}
                      disabled={formik.values.state ? false : true}
                      options={cities.map((c) => c.name)}
                      getOptionLabel={(city) => city}
                      onChange={(event, newValue) => {
                        // console.log(newValue);
                        formik.setFieldValue('city', newValue);
                      }}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          type="text"
                          variant="outlined"
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password' // disable autocomplete and autofill
                          }}
                        />
                      )}
                      className="dropdownContainer"
                    />
                    {formik.errors.city ? (
                      <Typography className={classes.error}>
                        {formik.errors.city}
                      </Typography>
                    ) : null}
                  </Grid>

                  <Grid item className={classes.defaultAddressContainer} direction="row">
                    <Checkbox
                      checked={defaultAddress}
                      color="primary"
                      inputProps={{ 'aria-label': 'Checkbox A' }}
                      size="medium"
                      onChange={handleChange}
                    />
                    <Typography variant="span" className={classes.defaultAddressText}>
                      Make this as my default address
                    </Typography>
                  </Grid>
                  <Box
                    py={3}
                  // className={classes.saveContainer}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                      type="submit"
                    // disabled={!(formik.isValid && formik.dirty)}
                    >
                      Save
                  </Button>
                  </Box>
                </Grid>
              </form>
            </Container>
          </Box>
        )}
    </Box>
  );
};

const mapStateToProps = ({ userData, userCartData }) => ({
  userData,
  userCartData
});

export default connect(mapStateToProps)(Address);
