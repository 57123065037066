import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './reducers';
import rootSaga from './sagas';
import history from '../utils/history';
import { persistStore } from 'redux-persist';

const reduxLogger = createLogger({});
const sagaMiddleware = createSagaMiddleware();
const routerHistoryMiddleware = routerMiddleware(history);
var middlewares = [sagaMiddleware, routerHistoryMiddleware];
if (process.env.NODE_ENV === 'development') {
  middlewares = [...middlewares, reduxLogger];
}
const enhancer = applyMiddleware(...middlewares);
const finalCreateStore = compose(enhancer)(createStore);
export const store = finalCreateStore(rootReducer);
export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
export default { store, persistor };
