import { takeLatest, put, call, select } from 'redux-saga/effects';
import actionTypes from '../actionTypes';
import * as ApiService from '../../services/apiService';
import {
  setLocalStorageTokens,
  clearLocalStorageTokens,
} from '../../utils/tokensHelper';
import { navigateToHome, backTo, replaceWithRoute } from '../../utils/history';
import {
  requestFirebaseUserSuccess,
  requestFirebaseUserFailure,
  requestUserCart,
  loginLoader,
  clearCart,
} from '../actions/userActions';
import { HOME_ROUTE, LOGIN_ACCOUNT } from '../../utils/routesConstants';
import { toastr } from 'react-redux-toastr';

function* getAuthData(action) {
  try {
    // yield put(loginLoader());
    const userStatus = yield call(login, action.payload);
    setLocalStorageTokens({ isLoggedIn: true });

    yield put(
      requestFirebaseUserSuccess({
        user: userStatus,
      })
    );

    const state = yield select();
    let prevUserCartData = state.userCartData.cartData;

    if (prevUserCartData.length > 0) {
      window.localStorage.setItem('syncCart', true);
    }

    yield put(requestUserCart());

    if (!userStatus.username) {
      replaceWithRoute(LOGIN_ACCOUNT);
    } else {
      // backTo();
    }
  } catch (error) {
    yield put(requestFirebaseUserFailure(error));
    toastr.error(error.message || 'Something went wrong!');
  }
}

function* updateAuthData(action) {
  try {
    yield put(loginLoader());
    const userStatus = yield call(profileUpdate, action.payload);
    yield put(
      requestFirebaseUserSuccess({
        user: userStatus,
      })
    );
    toastr.success('User Data Updated');
  } catch (error) {
    yield put(requestFirebaseUserFailure(error));
    toastr.error(error.message || 'Something went wrong!');
  }
}

export function* logout(action) {
  try {
    clearLocalStorageTokens();
    yield put(clearCart());
    window.localStorage.removeItem('deliveryOption');
    window.localStorage.removeItem('isLoggedIn');
    window.localStorage.removeItem('user');

    navigateToHome();

    // const storeId = window.sessionStorage.getItem ('storeId');

    // if (storeId === undefined || storeId === null) navigateTo (HOME_ROUTE);
    // else {
    //   navigateTo (`/s/${storeId}`);
    // }
  } catch (error) {
    // console.log(error);
  }
}

export default [
  takeLatest(actionTypes.GET_AUTH_DATA, getAuthData),
  takeLatest(actionTypes.UPDATE_AUTH_DATA, updateAuthData),
  takeLatest(actionTypes.LOGOUT, logout),
];

async function login(payload) {
  const { username, phone, uId } = payload;

  return new Promise(async function (resolve, reject) {
    const APIObj = {
      endPoint: 'secure/users',
      authenticationRequired: true,
      method: 'POST',
      body: JSON.stringify({
        phoneno: phone,
        username: username,
        uuid: uId,
      }),
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
      },
    };
    // console.log('apicallll');
    ApiService.callApi(APIObj)
      .then(res => {
        // console.log('apicallllres', res);

        if (res.status) {
          setLocalStorageTokens({
            user: res.user,
          });
          resolve(res.user);
        }
      })
      .catch(err => reject(err));
  });
}

async function profileUpdate(payload) {
  return new Promise(function (resolve, reject) {
    const APIObj = {
      endPoint: 'secure/users',
      authenticationRequired: true,
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
      },
    };
    ApiService.callApi(APIObj)
      .then(res => {
        if (res.status) {
          setLocalStorageTokens({
            user: res.user,
          });
          resolve(res.user);
        }
      })
      .catch(error => reject(error));
  });
}
