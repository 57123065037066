import React from 'react';

import '../styles.scss';

function ShimmerItem() {
  return (
    <div className="skeleton-container-store">
      <div className="image-loader-1 commom-image">
        <div className="background fast"></div>
      </div>
      <div className="title-loader-1">
        <div className="background slow"></div>
      </div>
      <div className="subtitle-loader-1">
        <div className="background slow"></div>
      </div>
      <div className="price-loader-1">
        <div className="background slow"></div>
      </div>
      <div className="button-loader-1">
        <div className="background fast"></div>
      </div>
    </div>
  );
}

export default ShimmerItem;
