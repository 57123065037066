import React from 'react';
import {
  IconButton,
  Typography,
  Box,
} from '@material-ui/core';
import '../style.scss';
import { useStyles } from '../style';
import { navigateTo } from '../../../../utils/history';
import { ADDRESS } from '../../../../utils/routesConstants';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const AddAddressButton = () => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Typography className={classes.addressHeadingText}>
          Address
        </Typography>
      </Box>
      <Box>
        <IconButton
          style={{ backgroundColor: 'transparent' }}
          onClick={() => navigateTo(ADDRESS)}
        >
          <AddCircleOutlineIcon className={classes.plusIcon} />
          <Typography className={classes.addAddress}>
            Add New Address
          </Typography>
        </IconButton>
      </Box>
    </Box>
  );
};

export default AddAddressButton;
