import {
  Box,
  Button,
  ClickAwayListener,
  Container,
  Grow,
  MenuItem,
  Paper,
  Menu,
  Popper,
  MenuList,
  Backdrop,
  Slide,
  AppBar,
  makeStyles,
  IconButton,
  Badge
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import { connect } from 'react-redux';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CartIcon from '@material-ui/icons/ShoppingCart';

import { navigateTo, navigateToHome } from '../../utils/history';
import { getLocalStorageTokens } from '../../utils/tokensHelper';
import { useAccountHook } from '../Tabs/Account/hooks';
import LogoImage from '../../assets/images/logo.svg';
import './style.scss';
import LoginScreen from '../Login/Components/LoginScreen';
import ShareIconButton from '../Header/shareIconButton';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import {
  ACCOUNT_ROUTE,
  ORDER_ROUTE,
  CART_ROUTE,
  WISHLIST
} from '../../utils/routesConstants';
const useStyles = makeStyles((theme) => ({
  // root: {
  //   flexGrow: 1,
  //   margin: '5px 5px 0px 5px',
  //   backgroundColor: '#5C4DB1',
  //   padding: 10
  // },
  badgeIcon: {
    left: 5,
    height: 17,
    lineHeight: 0.8,
    minWidth: 17,
    background: '#DBA719'
  },
  noEmail: {
    display: 'flex',
    flexGrow: 1,
    marginRight: 15
  },
  email: {
    display: 'flex',
    marginRight: 10
  }
}));
function Header({ userData, storeData, userCartData }) {
  const storeId = window.sessionStorage.getItem('storeId');
  const classes = useStyles();
  const history = useHistory();

  const [cartCount, setCartCount] = useState(0);
  const [userName, setUserName] = useState('User');

  useEffect(() => {
    const sum = userCartData.cartData
      .map((n) => n.quantity)
      .reduce((a, b) => a + b, 0);
    setCartCount(sum);
  }, [userCartData]);

  useEffect(() => {
    let userN = userData?.user?.username;
    if (userN) setUserName(userN);
  });

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const prevOpen = React.useRef(open);
  const { isLoggedIn } = getLocalStorageTokens();

  const [loginSlide, setLoginSlide] = useState(false);

  const loginHandler = () => {
    // handleClose(e);
    handleToggle();
    setLoginSlide(true);
    // handleClose(e);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const { logoutRequestHandler } = useAccountHook();

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const logoutHandler = (e) => {
    logoutRequestHandler();
    handleClose(e);
  };
  const slideClose = () => {
    setLoginSlide(false);
    // return <Redirect to={`/s/${storeId}`}
  };
  const myOrderHandler = () => {
    if (isLoggedIn) {
      navigateTo(ORDER_ROUTE);
    } else {
      loginHandler();
    }
  };
  const cartHandler = () => {
    navigateTo(CART_ROUTE)
  };

  return (

    <AppBar position="fixed" className="content-dsk" >
      <Box py="3px" className="stickyMenu">
        <Container>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {window.location.href.startsWith('https://apandukan.co/') ? (
              <Box className="logo " onClick={() => navigateToHome()}>
                <img src={LogoImage} />
              </Box>
            ) : (
                <Box></Box>
              )}
            {/* <Box className="logo " onClick={() => navigateToHome()}>
              <img src={LogoImage} />
            </Box> */}

            <Box className="headerMenu">
              <MenuItem
                className={
                  window.location.href.includes('/s/') ||
                    (window.location.href?.split("https://")[1]?.split('/').length < 3 &&
                      window.location.href?.split("https://")[1]?.split('/')[1] === '')
                    ? 'menu-selected' : ''
                }
                onClick={() => navigateToHome()}
              >
                <span>Home</span>
              </MenuItem>
              <MenuItem
                className={
                  window.location.href.endsWith('/order_details') ||
                    window.location.href.endsWith('/single-order')
                    ? 'menu-selected'
                    : ''
                }
                // onClick={() => navigateTo(ORDER_ROUTE)}
                onClick={myOrderHandler}
              >
                <span>My Order</span>
              </MenuItem>

              <Box
                className="header-actions"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <ShareIconButton
                    classVar=""
                    color="secondary"
                    storeData={storeData}
                    itemDetailsURL={false}
                  />
                </Box>
                <Box
                  className={
                    window.location.href.endsWith('/cart')
                      ? 'menu-selected'
                      : ''
                  }
                >
                  <IconButton
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigateTo(CART_ROUTE)
                    }
                  // onClick={() => history.push('/cart')}
                  >
                    <Badge
                      badgeContent={cartCount}
                      classes={{
                        anchorOriginTopRightRectangle: classes.badgeIcon
                      }}
                    >
                      <CartIcon className="header-cart-icon" />
                    </Badge>
                  </IconButton>
                </Box>


                <Box
                  className={
                    window.location.href.endsWith('/wishlist')
                      ? 'menu-selected'
                      : ''
                  }
                >
                  <IconButton
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigateTo(WISHLIST)}
                  >


                    <FavoriteBorderIcon className="header-cart-icon" />

                  </IconButton>
                </Box>
              </Box>


              <Box>
                <Button
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  color="secondary"
                  onClick={handleToggle}
                >
                  <span className="circle">
                    <AccountCircleIcon size="20" color="primary" />
                    {/* When we have actual image uploaded for the account, we can uncomment the below and use */}
                    {/* {
                                            isLoggedIn ? <img src={PersonImage} onError={(e) => { e.target.src = PlaceHolder; }} /> 
                                            : <AccountCircleIcon size="20" color="primary" />
                                        } */}
                  </span>
                  {isLoggedIn ? userName : 'User'}
                  <ArrowDropDownIcon />
                </Button>
                {/* <Menu
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom'
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose} style={{ zIndex: 1000 }}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            {isLoggedIn ? (
                              <>
                                <MenuItem
                                  onClick={() => navigateTo(ACCOUNT_ROUTE)}
                                >
                                  Account
                                </MenuItem>
                                <MenuItem onClick={logoutHandler}>
                                  Logout
                                </MenuItem>
                              </>
                            ) : (
                              <MenuItem onClick={loginHandler}>Login</MenuItem>
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Menu> */}
                <ClickAwayListener onClickAway={handleClose} style={{ zIndex: 1000 }}>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorRef.current}
                    keepMounted
                    open={Boolean(open)}
                    onClose={handleToggle}
                  >

                    <MenuList
                      autoFocusItem={open}
                      onKeyDown={handleListKeyDown}
                    >
                      {isLoggedIn ? (
                        <>
                          <MenuItem
                            onClick={() => {
                              handleToggle();
                              navigateTo(ACCOUNT_ROUTE)
                            }}
                          >
                            Account
                          </MenuItem>
                          <MenuItem onClick={logoutHandler}>
                            Logout
                          </MenuItem>
                        </>
                      ) : (
                          <MenuItem onClick={loginHandler}>Login</MenuItem>
                        )}
                    </MenuList>

                  </Menu>
                </ClickAwayListener>
              </Box>
            </Box>
          </Box>
          <Backdrop open={loginSlide} className="backdrop">
            <Slide direction="left" in={loginSlide} mountOnEnter unmountOnExit>
              <Box elevation={4} className="slideLogin">
                <LoginScreen handleClose={slideClose} />
              </Box>
            </Slide>
          </Backdrop>
        </Container>
      </Box>
    </AppBar>
  );
}
const mapStateToProps = ({ userData, storeData, userCartData }) => ({
  userData,
  storeData,
  userCartData
});

export default connect(mapStateToProps)(Header);
