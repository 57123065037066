import actionTypes from '../actionTypes';

// export const requestUserRequest = (userName, password) => {
//   return {
//     type: actionTypes.USER_REQUEST,
//     payload: { userName, password }
//   };
// };

export const requestFirebaseUserSuccess = (data) => {
  return {
    type: actionTypes.USER_SUCCESS,
    payload: data
  };
};
export const loginLoader = () => {
  return {
    type: actionTypes.USER_REQUEST
  };
};

export const requestFirebaseUserFailure = (errMsg) => {
  return { type: actionTypes.USER_FAILURE, payload: errMsg };
};

export const startLogout = () => {
  return {
    type: actionTypes.LOGOUT
  };
};

// ADD ITEM TO USER CART
export const requestAddItemToCart = (item) => {
  return {
    type: actionTypes.ADD_ITEM_TO_CART,
    payload: item
  };
};

export const addItemToCartSuccess = (userUpdatedCart) => {
  return {
    type: actionTypes.ADD_ITEM_TO_CART_SUCCESS,
    payload: userUpdatedCart
  };
};
export const addItemToCartFailure = (errMsg) => {
  return {
    type: actionTypes.ADD_ITEM_TO_CART_FAILURE,
    payload: errMsg
  };
};

export const addItemToCartRequestLoader = () => {
  return { type: actionTypes.ADD_ITEM_TO_CART_REQUEST, payload: {} };
};

// REMOVE ITEM TO USER CART
export const requestRemoveItemFromCart = (item) => {
  return {
    type: actionTypes.REMOVE_ITEM_FROM_CART,
    payload: item
  };
};

export const requestaddUserNotes = (note) => {
  return {
    type: actionTypes.ADD_USER_NOTE,
    payload: note
  };
};
export const updateUserNotes = (note) => {
  return {
    type: actionTypes.UPDATE_USER_NOTE,
    payload: note
  };
};

// Get Store data TO REDUCER ON INITIAL LOAD
export const requestStoreData = (storeId, loadingStatus = true) => {
  return {
    type: actionTypes.GET_STORE_DATA,
    payload: { storeId: storeId, loadingStatus: loadingStatus }
  };
};

export const getStoreDataRequest = (loadingStatus = true) => {
  return { type: actionTypes.STORE_API_REQUEST, payload: { loadingStatus } };
};

export const getStoreDataSuccess = (data) => {
  return {
    type: actionTypes.STORE_API_SUCCESS,
    payload: data
  };
};

export const getStoreDataFailure = (errMsg) => {
  return { type: actionTypes.STORE_API_FAILURE, payload: errMsg };
};

export const getUserAuthData = (data) => {
  return {
    type: actionTypes.GET_AUTH_DATA,
    payload: data
  };
};
// GET, POST, PUT USER CART FUNCTIONS
export const requestUserCart = () => {
  return { type: actionTypes.GET_USER_CART_DATA };
};

//UPDATE_USER_CART
export const updateUserCartLoading = () => {
  return {
    type: actionTypes.UPDATE_USER_CART_REQUEST
  };
};

export const updateUserCartSuccess = (userUpdatedCart) => {
  return {
    type: actionTypes.UPDATE_USER_CART_SUCCESS,
    payload: userUpdatedCart
  };
};

export const clearCart = () => {
  return {
    type: actionTypes.CART_CLEAR
  };
};
export const updateUserCartFailure = (errMsg) => {
  return {
    type: actionTypes.UPDATE_USER_CART_FAILURE,
    payload: errMsg
  };
};

export const updateUserAuthData = (data) => {
  return {
    type: actionTypes.UPDATE_AUTH_DATA,
    payload: data
  };
};

export const requestCartCheckout = (checkout_data) => {
  return {
    type: actionTypes.CART_CHECKOUT,
    payload: checkout_data
  };
};

// Get Order
export const requestGetAllOrders = () => {
  return {
    type: actionTypes.GET_ALL_ORDERS
  };
};
export const getAllOrdersLoader = () => {
  return {
    type: actionTypes.GET_ALL_ORDERS_REQUEST,
    payload: {}
  };
};

export const getAllOrdersSuccess = (data) => {
  return {
    type: actionTypes.GET_ALL_ORDERS_SUCCESS,
    payload: data
  };
};

export const getAllOrdersFailure = (errMsg) => {
  return {
    type: actionTypes.GET_ALL_ORDERS_FAILURE,
    payload: errMsg
  };
};

// Get Item details
export const requestItemDetails = (storeId, itemId) => {
  return {
    type: actionTypes.GET_ITEM_DETAIL,
    payload: {
      storeId: storeId,
      itemId: itemId
    }
  };
};
export const requestSetItemDetails = (item, currentStore) => {
  return {
    type: actionTypes.SET_ITEM_DETAIL,
    payload: {
      item: item,
      store: currentStore
    }
  };
};
export const itemDetailsLoader = () => {
  return {
    type: actionTypes.ITEM_DETAIL_REQUEST,
    payload: {}
  };
};

export const itemDetailsSuccess = (data) => {
  return {
    type: actionTypes.ITEM_DETAIL_SUCCESS,
    payload: data
  };
};

export const itemDetailsFailure = (errMsg) => {
  return {
    type: actionTypes.ITEM_DETAIL_FAILURE,
    payload: errMsg
  };
};

/// For variants update
export const requestUpdateVariants = (item) => {
  return {
    type: actionTypes.UPDATE_VARIANTS,
    payload: {
      item: item
    }
  };
};
export const updateVariantsLoader = () => {
  return {
    type: actionTypes.UPDATE_VARIANTS_REQUEST,
    payload: {}
  };
};

export const updateVariantsSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_VARIANTS_SUCCESS,
    payload: data
  };
};

export const updateVariantsFailure = (errMsg) => {
  return {
    type: actionTypes.UPDATE_VARIANTS_FAILURE,
    payload: errMsg
  };
};

/// For coupons fetching
export const getActiveCoupons = () => {
  return {
    type: actionTypes.GET_ALL_COUPONS,
    payload: {}
  };
};
export const getCouponsLoader = () => {
  return {
    type: actionTypes.GET_ALL_COUPONS_REQUEST,
    payload: {}
  };
};

export const getCouponsSuccess = (data) => {
  return {
    type: actionTypes.GET_ALL_COUPONS_SUCCESS,
    payload: data
  };
};

export const getCouponsFailure = (errMsg) => {
  return {
    type: actionTypes.GET_ALL_COUPONS_FAILURE,
    payload: errMsg
  };
};

export const requestInitiatePayment = (initiatePayment) => {
  return {
    type: actionTypes.INITIATE_PAYMENT,
    payload: initiatePayment
  };
};
export const paymentLoader = (initiatePayment) => {
  return {
    type: actionTypes.INITIATE_PAYMENT_REQUEST,
    payload: initiatePayment
  };
};

export const paymentSuccess = (data) => {
  return {
    type: actionTypes.INITIATE_PAYMENT_SUCCESS,
    payload: data
  };
};

export const paymentFailure = (errMsg) => {
  return {
    type: actionTypes.INITIATE_PAYMENT_FAILURE,
    payload: errMsg
  };
};



/// Payment Status
export const requestcheckPaymentStatus = (data) => {
  return {
    type: actionTypes.CHECK_PAYMENT_STATUS,
    payload: data
  };
};
export const paymentStatusLoader = () => {
  return {
    type: actionTypes.CHECK_PAYMENT_STATUS_REQUEST,
    payload: {}
  };
};

export const paymentStatusSuccess = (data) => {
  return {
    type: actionTypes.CHECK_PAYMENT_STATUS_SUCCESS,
    payload: data
  };
};

export const paymentStatusFailure = (errMsg) => {
  return {
    type: actionTypes.CHECK_PAYMENT_STATUS_FAILURE,
    payload: errMsg
  };

};
export const clearPaymentData = () => {
  return {
    type: actionTypes.CLEAR_PAYMENT_DATA,
    payload: {}
  };
}
export const getWishlist = () => {
  return {
    type: actionTypes.GET_WISHLIST,
    payload: {}
  };
}
export const addToWishlist = (item) => {
  return {
    type: actionTypes.ADD_TO_WISHLIST,
    payload: item
  };
}
export const updateWishlist = (data) => {
  return {
    type: actionTypes.UPDATE_WISHLIST,
    payload: data
  };
}