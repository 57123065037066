import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  containerPadding: {
    [theme.breakpoints.down("lg")]: {
      padding: 0,
      margin: 0,
    }
  }
  // appBar: {
  //   color: '#5C4DB1',
  //   padding: 10
  // },
  // appText: {
  //   color: 'white',
  //   fontSize: 17
  // },
  // btn: {
  //   color: 'white',
  //   backgroundColor: '#5C4DB1',
  //   // margin: 10,
  //   marginTop:10,
  //   minWidth: '100%'
  // },
  // loginbtn: {
  //   color: 'white',
  //   backgroundColor: '#5C4DB1',
  //   minWidth: '100%'
  // },
  // gridContainer: {
  //   minHeight: '90vh'
  // },
  // inputGrid: {
  //   margin: '10%'
  // },
  // back_icon: {
  //   color: 'white'
  // },
  // input: {
  //   minWidth: '100%',
  //   margin: 10,
  //   height:30,

  // }
}));
