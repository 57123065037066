import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  //appbar.js
  back_icon: {
    color: 'white'
  },
  text_flex: {
    flex: 1,
    justifyContent: 'center',
    display: 'flex'
  },
  text_flex_market: {
    flex: 1,
    justifyContent: 'space-between',
    display: 'flex'
  },
  cart_icon: {
    color: 'white',
    justify: 'space-between'
  },

  //order
  delivered_text: {
    color: '#02B875',
    paddingLeft: '2%',
    paddingTop: '2%',
    paddingBottom: '2%',
    fontSize: 14
  },
  grid_flex: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '1%'
  },
  //order_details.js
  order_text_size: {
    fontSize: 14,
    color: '#515151',
    fontWeight: 500,
    wordBreak: 'break'
  },
  status_grid: {
    color: 'white',
    borderRadius: 9,
    padding: '6px 9px 6px 9px',
    justifyContent: 'center',
    display: 'flex',
    fontSize: 12,
    alignItems: 'center'
  },
  text_align: {
    paddingLeft: '3%',
    fontSize: 16,
    color: '#515151',
    fontWeight: theme.typography.fontWeightBold,
    paddingTop: 5
  },
  text_status: {
    paddingLeft: '2%',
    fontSize: 14,
    color: '#515151'
  },
  invoice_align: {
    // paddingTop: '4%',
    // paddingBottom: '3%',
    // justifyContent: 'flex-end',
    // paddingRight: 15
  },
  text_invoice: {
    fontSize: 13,
    color: '#5C4DB1',
    width: 'max-content'
  },
  name_color: {
    color: '#4D4D4D'
  },
  text: {
    color: '#4D4D4D',
    fontSize: 12,
    fontWeight: theme.typography.fontWeightLight
  },
  addresstext: {
    color: '#4D4D4D',
    fontSize: 13,
    fontWeight: theme.typography.fontWeightLight
  },
  detail_text: {
    color: '#4D4D4D',
    width: ' max-content',
    fontSize: 15
  },
  cost: {
    fontSize: 14,
    color: 'rgb(51,51,51)'
  },
  refundButton: {
    backgroundColor: 'white !important',
    margin: '30px 15px 15px 15px',
    borderWidth: 1.5,
    textTransform: 'none',
    borderRadius: 6
  },
  grid_flex_select_address: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 10,
    paddingTop: 3,
    paddingRight: 10
  },
  labelRow: {
    alignItems: 'center',
    padding: '10px 10px 0px 0px'
  },
  label: {
    fontSize: 14,
    fontWeight: 500
  },
  policyButton: {
    textTransform: 'none',
    fontSize: 13,
    color: 'rgb(219,167,25)'
  },
  inputField: {
    padding: 0
  },
  selectGrid: {
    padding: '0px 20px 2px 2px'
  },
  inputFieldOutlined: {
    padding: '12px 11px',
    borderRadius: 5
  },
  notesGrid: {
    padding: '5px 20px 5px 1px'
  },
  notes: {
    padding: '13px 13px',
    height: 75,
    display: 'flex',
    alignItems: 'flex-start'
  },
  text_area: {},
  rounded: { borderRadius: 11 },
  menuItem: {
    paddingTop: 5,
    paddingBottom: 5,
    minHeight: 3,
    fontSize: 15
  },
  textItem: {
    color: '#4D4D4D',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightLight
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    height: '100%',
    overflow: 'hidden'
  },
  stopScrolling: {
    height: '100%',
    overflow: 'hidden'
  },
  status_grid_container: {
    display: 'flex',
    alignItems: 'center'
  },
  badgeIcon: {
    left: 5,
    height: 17,
    lineHeight: 0.8,
    minWidth: 17
  },
  rootBgColor: {
    [theme.breakpoints.down("md")]: {
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: 'white',
    }
  },
  cardBgColor: {
    [theme.breakpoints.down("md")]: {
      backgroundColor: 'white',
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: 'transparent',
    },
  },
  orderCard: {
    [theme.breakpoints.down("md")]: {
      backgroundColor: 'white',
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: '#f3f3f3',
    },
  },
  containerPadding: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      margin: 0,
    }
  },

  // orderItemSpacing: {
  //   [theme.breakpoints.down("md")]: {
  //     spacing: 1
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     spacing: 2
  //   },
  // },
  orderDetailsCard: {
    [theme.breakpoints.up("md")]: {
      border: "1px solid #e9e9e9",
      borderRadius: 5
    }
  },
  orderPriceCard11: {
    [theme.breakpoints.down("md")]: {
      borderBottom: "1px solid #e9e9e9"
    },
    [theme.breakpoints.up("md")]: {
      borderRight: "1px solid #e9e9e9",
      flex: '50%'
    }
  }

}));

