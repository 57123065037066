import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  startLogout,
  requestStoreData,
  requestAddItemToCart,
  requestRemoveItemFromCart,
  requestItemDetails, requestSetItemDetails
} from '../../../store/actions/userActions';

export const useHomeHook = () => {
  const dispatch = useDispatch();

  const logoutRequestHandler = useCallback(() => {
    dispatch(startLogout());
  }, [dispatch]);

  const getStoreData = useCallback(
    (storeId, loadingStatus = true) => {
      dispatch(requestStoreData(storeId, loadingStatus));
    },
    [dispatch]
  );

  const addItemToCart = useCallback(
    (item) => {
      dispatch(requestAddItemToCart([item]));
    },
    [dispatch]
  );

  const removeItemFromCart = useCallback(
    (item) => {
      dispatch(requestRemoveItemFromCart([item]));
    },
    [dispatch]
  );


  const getStoreItemDetail = useCallback(
    (storeId, itemId) => {
      dispatch(requestItemDetails(storeId, itemId));
    },
    [dispatch]
  );
  const setStoreItemDetail = useCallback(
    (item, currentStore) => {

      dispatch(requestSetItemDetails(item, currentStore));
    },
    [dispatch]
  );
  return {
    logoutRequestHandler,
    getStoreData,
    addItemToCart,
    removeItemFromCart,
    getStoreItemDetail,
    setStoreItemDetail
  };
};
