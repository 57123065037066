import React, { useState, useEffect } from 'react';
import { useStyles } from '../style';
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Box
} from '@material-ui/core';
import { backTo } from '../../../../utils/history';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

const LoginAppBarContainer = (props) => {
  const classes = useStyles();
  const [ cartCount, setCartCount] = useState(0);

  useEffect(() => {
    // console.log(props.userCartData);
    const sum = props.userCartData?.cartData
      .map((n) => n.quantity)
      .reduce((a, b) => a + b, 0);
    setCartCount(sum);
  }, [props.userCartData]);

  const handleSkip = () => {
    backTo();
  };
  return (
    <AppBar position="fixed">
      <Toolbar
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box className={classes.boxLeft}>
          {props.route !== 'Account' ? (
            <IconButton style={{ padding: 0 }}>
              <KeyboardBackspaceIcon
                className={classes.back_icon}
                onClick={() => backTo()}
              />
            </IconButton>
          ) : null}
          <Typography className={classes.text_flex} variant="subtitle1">
            <Box fontWeight="fontWeightMedium">
              <Typography color="secondary" className={classes.routeText}>
                {props.route}
              </Typography>
            </Box>
          </Typography>
        </Box>
        <Box className={classes.boxRight} onClick={handleSkip}>
          Skip
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default LoginAppBarContainer;
