import React from 'react';
import {
    Box,
    Grid,
    Typography,
    Paper,
    RadioGroup,
    FormControlLabel,
    Radio

} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const PaymentMethod = ({ onlineStore, classes, handlePaymentOption, paymentOption, orderAmount }) => {
    const codEnabled = onlineStore?.codEnabled ?? true;
    const onlinePaymentEnabled = onlineStore?.onlinePaymentEnabled ?? false;
    if (orderAmount <= 30)
        return (

            <Paper className={classes.item_paper} elevation={0}>
                <Grid xs={12} className="payment">
                    <Grid style={{ paddingRight: 4, display: 'flex' }}>
                        <ErrorOutlineIcon
                            style={{ color: '#DBA719', fontSize: 20 }}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography className={classes.paymentText}>
                            Payment Method
      </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.paymentText}>
                            <Box fontWeight="fontWeightMedium">
                                {'Cash'}
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        );
    if (codEnabled && onlinePaymentEnabled)
        return (
            <>
                <Typography className={classes.text}>
                    <Box fontWeight="fontWeightMedium">Payment Option</Box>
                </Typography>
                <Paper className={classes.deliveryDetailsPaper} elevation={0}>
                    <Grid>
                        <RadioGroup value={paymentOption} onChange={handlePaymentOption}>
                            <Grid container className={classes.radio_items}>
                                <Grid item>
                                    <FormControlLabel
                                        value="online"
                                        control={
                                            <Radio
                                                classes={{
                                                    root: classes.radio,
                                                    checked: classes.checked
                                                }}
                                            />
                                        }
                                        label="Online"
                                        classes={{ label: classes.label }}
                                    />
                                </Grid>
                                <Grid item>
                                </Grid>
                            </Grid>
                            <Grid xs={6}>
                                <FormControlLabel
                                    value="cash"
                                    control={
                                        <Radio
                                            classes={{
                                                root: classes.radio,
                                                checked: classes.checked
                                            }}
                                        />
                                    }
                                    label="Cash"
                                    classes={{ label: classes.label }}
                                />
                            </Grid>
                        </RadioGroup>
                    </Grid>
                </Paper>
            </>
        );
    else if (codEnabled || onlinePaymentEnabled)

        return (
            <Paper className={classes.item_paper} elevation={0}>
                <Grid xs={12} className="payment">
                    <Grid style={{ paddingRight: 4, display: 'flex' }}>
                        <ErrorOutlineIcon
                            style={{ color: '#DBA719', fontSize: 20 }}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography className={classes.paymentText}>
                            Payment Method
          </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.paymentText}>
                            <Box fontWeight="fontWeightMedium">
                                {codEnabled ? 'Cash' : "Online"}
                            </Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        );
    return <div />;

};

export default PaymentMethod;
