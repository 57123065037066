import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';
import history from '../../utils/history';

// If you want to use redux-form just run command `yarn add redux-form`
// and uncomment the commented code in this file

// import { reducer as formReducer } from 'redux-form';
import storeData from './storeData';
import userCartData from './userCartData';
import userData from './userAuth';
import userOrder from './userOrder';
import storeItemDetail from './storeItemDetails';
import payment from './payment';
import wishlist from './wishlist';


import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Any Redux Data you want to persist
//--> just add it in whiteList Below
//--> and define a " case 'persist/REHYDRATE' :  " into its reducer   --> ( eg : 'userCartData' reducer   )
const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['storeItemDetail']
};

const rootReducer = combineReducers({
  userCartData,
  userData,
  storeData,
  userOrder,
  storeItemDetail,
  payment,
  wishlist,
  router: connectRouter(history),
  toastr: toastrReducer
});

export default persistReducer(persistConfig, rootReducer);
