import actionTypes from '../actionTypes';

const initialState = () => {
  return {
    orderData: [],
    loader: true,
    error: false,
    errorMsg: {}
  };
};

export default function (state = initialState(), action = {}) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.GET_ALL_ORDERS_SUCCESS: {
      return {
        ...state,
        orderData: payload,
        loader: false,
        error: false
      };
    }

    case actionTypes.GET_ALL_ORDERS_REQUEST: {
      return {
        ...state,
        loader: true
      };
    }

    case actionTypes.GET_ALL_ORDERS_FAILURE: {
      return {
        ...state,
        error: true,
        loader: false,
        errorMsg: payload
      };
    }

    default:
      return state;
  }
}
