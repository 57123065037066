import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Button,
  TextField,
  Container
} from '@material-ui/core';
import '../style.scss';
import AppBarContainer from './appBar';
import { useStyles } from '../style';
import { useLocation } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import { Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import Backdrop from '@material-ui/core/Backdrop';
import {
  orderStatus,
  orderStatusColorCode
} from '../../../../utils/orderStatus';
import { Redirect } from 'react-router-dom';
import { HOME_ROUTE } from '../../../../utils/routesConstants';
import moment from 'moment';
import MainHeader from '../../../Header/mainHeader';
import InnerAppBar from '../../../Header/innerAppBar';
import { connect } from 'react-redux';

const Individual_Order = ({ userCartData }) => {
  const classes = useStyles();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [notes, setNotes] = useState('');

  var totalSaving = 0;
  var totalTaxes = 0;
  var total = 0;
  const data = location.state;

  const convertToRs = (cost) => {
    return `Rs.${Math.round(cost * 100) / 100}`;
    // return Intl.NumberFormat('en-IN', {
    //   style: 'currency',
    //   currency: 'INR'
    // }).format(cost);
  };
  const additionalChargesCalc = (additionalChargesList) => {
    var totalAdditionalCharges = 0;
    for (var i = 0; i < additionalChargesList.length; i++) {
      if (additionalChargesList[i].enabled)
        totalAdditionalCharges = totalAdditionalCharges + additionalChargesList[i].cost;


    }
    return totalAdditionalCharges;
  }
  const getTotalPrice = () => {
    if (data.additionalCharges?.length > 0)
      total = total + additionalChargesCalc(data?.additionalCharges);
    else {

      if (data?.deliveryCharges > 0) {
        total = total + data.deliveryCharges;
      }
      if (data?.packagingCharges > 0) {
        total = total + data.packagingCharges;
      }
    }
    if (data?.discount > 0) {
      total = total - data.discount;
    }
    if (totalTaxes > 0) total = total + totalTaxes;
    return total;
  };
  if (!data) return <Redirect to={HOME_ROUTE} />;
  const redirectionLink = `https://${window.location.hostname}/i/${data.storeId}/${data.firebaseId}`;
  return (
    <Box
      className="category-outer-container"
      height="100%"
      minHeight="calc(100vh)"
    >
      <MainHeader />
      {/* <AppBarContainer /> */}

      <Backdrop
        className={classes.backdrop}
        open={isOpen}
      // onClick={() => setIsLogin(false)}
      >
        <Slide direction="up" in={isOpen} timeout={1000}>
          {
            <Paper
              className="refundContainer"
              elevation={0}
              classes={{ rounded: classes.rounded }}
            >
              <Grid style={{ paddingBottom: 15 }}>
                <Grid className={classes.grid_flex_select_address}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      <Box fontWeight="fontWeightMedium">Refund/Return</Box>
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography onClick={() => setIsOpen(false)}>
                      <ClearIcon style={{ color: 'rgb(170,170,170)' }} />
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justify="space-between"
                  className={classes.labelRow}
                >
                  <Grid item>
                    <Typography className={classes.label}>
                      Select the reason
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button className={classes.policyButton} variant="text">
                      Refund/Return Policy
                    </Button>
                  </Grid>
                </Grid>
                <Grid className={classes.selectGrid}>
                  <Select
                    fullWidth
                    classes={{
                      root: classes.inputField,
                      outlined: classes.inputFieldOutlined
                    }}
                    onChange={(e) => setReason(e.target.value)}
                    value={reason}
                    variant="outlined"
                  >
                    <MenuItem className={classes.menuItem} value="Damaged">
                      Damaged
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value="WrongSize">
                      Wrong Size
                    </MenuItem>
                    <MenuItem className={classes.menuItem} value="Different">
                      Different
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid container className={classes.labelRow}>
                  <Grid item>
                    <Typography className={classes.label}>Note</Typography>
                  </Grid>
                </Grid>
                <Grid className={classes.notesGrid}>
                  <TextField
                    className={classes.text_area}
                    placeholder="Description"
                    multiline
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      classes: {
                        notchedOutline: classes.cssLabel,
                        multiline: classes.notes
                      }
                    }}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid style={{ paddingRight: 20 }}>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  style={{
                    border: '2px solid rgba(92, 77, 177, 0.5)',
                    borderRadius: 5,
                    textTransform: 'none'
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Paper>
          }
        </Slide>
      </Backdrop>

      <Container className={classes.containerPadding}>
        <InnerAppBar
          userCartData={userCartData}
          showInner={false}
          title="Order Details"
          bgColor={true}
          backspace={true}
        />
        <Box p={[0, 0, 3, 3]} mt={[3, 3, 0, 0]} className={classes.rootBgColor}>
          <Box
            display="flex"
            justifyContent="space-between"
            p={3}
            my={[0, 0, 1, 1]}
            className={classes.orderCard}
            // style={{ backgroundColor: '#f3f3f3' }}

            borderRadius={5}
          >
            <Box>
              <Typography
                className={classes.order_text_size}
                variant="subtitle1"
              >
                Order ID: #INV-{data.firebaseId}
              </Typography>
              <Typography className={classes.text} variant="subtitle1">
                {moment(data.createdAt).format('lll')}
              </Typography>
            </Box>
            <Box>
              <Box
                className={classes.status_grid}
                style={{ backgroundColor: orderStatusColorCode(data.status) }}
              >
                {orderStatus(data.status)}

              </Box>

            </Box>
          </Box>

          {data.paymentMode && data.paymentMode === 'online' &&

            <Box className={classes.cardBgColor} px={[2, 2, 0, 0]} py={1} mt={[1, 1, 0, 0]} >
              <Box display="flex" justifyContent="space-between">
                <Typography
                  style={{ fontSize: 14 }}
                  className={classes.price_details_text}
                  variant="subtitle1"
                >
                  Mode

                </Typography>
                <Typography

                  style={{ fontSize: 14, fontWeight: 'bold', color: 'green' }}
                  className={classes.price_details_text}
                  variant="subtitle1"
                >
                  {'Paid online'}
                </Typography>
              </Box>
            </Box>}

          <Box px={[2, 2, 0, 0]} py={1}>
            Items
          </Box>
          <Box className={classes.cardBgColor} px={[2, 2, 0, 0]} py={1}>
            {data.items.map((item, index) => {
              // console.log(item);
              total = total + item.quantity * item.price;
              if (item.mrp !== 0)
                totalSaving =
                  item.quantity * (totalSaving + (item.mrp - item.price));
              totalTaxes =
                totalTaxes + (item.priceWithGst - item.price) * item.quantity;

              return (
                <Box pb={1}>
                  <Box display="flex" justifyContent="space-between">
                    <Box className={classes.textItem} variant="subtitle1">
                      {item.itemType}
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      flex="0 0 50%"
                    >
                      <Box
                        className={classes.cost}
                        fontWeight="fontWeightMedium"
                      >
                        {convertToRs(item.price)}
                      </Box>

                      <Box
                        className={classes.cost}
                        fontWeight="fontWeightMedium"
                      >
                        {item.quantity}
                      </Box>

                      <Box
                        className={classes.cost}
                        fontWeight="fontWeightMedium"
                      >
                        {convertToRs(item.price * item.quantity)}
                      </Box>
                    </Box>
                  </Box>
                  {item.selectedVariants &&
                    item.selectedVariants.map((e) => {
                      return (
                        <Box>
                          <Grid
                            direction="row"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              paddingLeft: 20
                            }}
                          >
                            <Typography
                              style={{
                                paddingRight: 7,
                                fontSize: 12
                              }}
                            >
                              {e.type !== ''
                                ? `${e.type}: `
                                : e.weight !== ''
                                  ? e.weight
                                  : 'Size: '}
                            </Typography>
                            {e.type !== '' && e.type === 'Color' ? (
                              <div
                                style={{
                                  backgroundColor: e.value,
                                  width: 25,
                                  height: 12,
                                  borderColor: 'black',
                                  border: 'solid',
                                  borderWidth: 'thin'
                                }}
                              ></div>
                            ) : (
                                <Typography>
                                  {e.type !== ''
                                    ? e.value
                                    : e.weight !== ''
                                      ? ''
                                      : e.size}
                                </Typography>
                              )}
                          </Grid>
                          {e.type === '' && e.weight === '' && (
                            <Grid
                              direction="row"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                paddingLeft: 20
                              }}
                            >
                              <Typography
                                style={{
                                  paddingRight: 7,
                                  fontSize: 12
                                }}
                              >
                                {'Colour: '}
                              </Typography>
                              <div
                                style={{
                                  backgroundColor: e.colour,
                                  width: 25,
                                  height: 12,
                                  borderColor: 'black',
                                  border: 'solid',
                                  borderWidth: 'thin'
                                }}
                              ></div>
                            </Grid>
                          )}
                        </Box>
                      );
                    })}
                </Box>
              );
            })}
          </Box>

          <Box
            px={[2, 2, 0, 0]}
            py={1}
            fontWeight="fontWeightMedium"
            className="paper-align"
            variant="subtitle1"
          >
            {data.deliveryMode === 'self-pickup'
              ? 'Delivery type'
              : 'Delivery Address'}
          </Box>

          <Box className={classes.cardBgColor} px={[2, 2, 0, 0]} pb={1}>
            {data.deliveryMode === 'delivery' && data.deliveryAddress.pincode && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  className={classes.detail_text}
                  fontWeight="fontWeightMedium"
                >
                  {data.deliveryAddress.addName}
                </Box>

                <Box
                  className={classes.detail_text}
                  variant="subtitle1"
                  fontWeight="fontWeightMedium"
                >
                  {data.deliveryAddress.phoneno}
                </Box>
              </Box>
            )}

            <Box className={classes.addresstext} variant="subtitle1" pt={1}>
              {data.deliveryMode === 'delivery' &&
                data.deliveryAddress.pincode ? (
                  <Typography>
                    {`Building/Flat No: ${data.deliveryAddress.building}, ${data.deliveryAddress.street}, ${data.deliveryAddress.city}, ${data.deliveryAddress.state} - ${data.deliveryAddress.pincode}.`}
                  </Typography>
                ) : data.deliveryMode === 'self-pickup' ? (
                  <Typography>{'Self Pickup'}</Typography>
                ) : (
                    <Typography>{'Address is not added during order'}</Typography>
                  )}
            </Box>
          </Box>

          {data.customerNotes && (
            <Box
              className="paper-align"
              px={[2, 2, 0, 0]}
              py={1}
              variant="subtitle1"
              fontWeight="fontWeightMedium"
            >
              Order Instructions
            </Box>
          )}

          <Box className={classes.cardBgColor} px={[2, 2, 0, 0]} py={1}>
            {data.customerNotes && (
              <Box className="paper-align">
                <Box>
                  <Box
                    className={classes.detail_text}
                    fontWeight="fontWeightMedium"
                  >
                    {data.deliveredTo}
                  </Box>

                  <Box
                    fontWeight="fontWeightMedium"
                    style={{ paddingLeft: '3%' }}
                    className={classes.detail_text}
                    variant="subtitle1"
                  >
                    {data.phoneNo}
                  </Box>
                </Box>
                <Box>
                  <Typography
                    className={classes.addresstext}
                    variant="subtitle1"
                  >
                    {data.customerNotes}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={[2, 2, 0, 0]}
            py={1}
          >
            <Box className="paper-align" fontWeight="fontWeightMedium">
              Order Details
            </Box>
            <Box className={classes.invoice_align}>
              <Box
                fontWeight="fontWeightMedium"
                className={classes.text_invoice}
              >
                <Button
                  href={redirectionLink}
                  color="primary"
                  style={{ fontSize: 12, textDecoration: 'none' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View invoice
                </Button>
              </Box>
            </Box>
          </Box>

          <Box
            display="flex"
            className={[classes.cardBgColor, classes.orderDetailsCard]}
            // p={[2, 2, 0, 0]}
            flexWrap={['wrap', 'wrap', 'nowrap', 'nowrap']}
          >
            <Box
              flex={['100%', '100%', '50%', '50%']}
              p={2}
              className={[classes.orderPriceCard11]}
            >
              <Box display="flex" justifyContent="space-between">
                <Typography
                  style={{ fontSize: 14 }}
                  className={classes.price_details_text}
                  variant="subtitle1"
                >
                  Items Cost
                </Typography>
                <Typography
                  fontWeight="fontWeightMedium"
                  style={{ fontSize: 14 }}
                  className={classes.price_details_text}
                  variant="subtitle1"
                >
                  {convertToRs(total)}
                </Typography>
              </Box>

              {totalSaving > 0 && (
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    style={{ fontSize: 14 }}
                    className={classes.price_details_text}
                    variant="subtitle1"
                  >
                    Your Savings
                  </Typography>

                  <Typography
                    style={{ color: '#DBA719', fontSize: 14 }}
                    className={classes.price_details_text}
                    fontWeight="fontWeightMedium"
                  >
                    {convertToRs(totalSaving)}
                  </Typography>
                </Box>
              )}

              {data.additionalCharges && data.additionalCharges.length ? <div>

                {data.additionalCharges.map((item) => {
                  return (<Box display="flex" justifyContent="space-between">
                    <Typography
                      style={{ fontSize: 14 }}
                      className={classes.price_details_text}
                      variant="subtitle1"
                    >
                      {item.name}
                    </Typography>

                    <Typography
                      style={{ fontSize: 14 }}
                      className={classes.price_details_text}
                      fontWeight="fontWeightMedium"
                    >
                      {convertToRs(item.cost)}
                    </Typography>
                  </Box>);
                })}

              </div> : <div>
                  {data.deliveryCharges > 0 && (
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        style={{ fontSize: 14 }}
                        className={classes.price_details_text}
                        variant="subtitle1"
                      >
                        Delivery Charge
                  </Typography>

                      <Typography
                        style={{ fontSize: 14 }}
                        className={classes.price_details_text}
                        fontWeight="fontWeightMedium"
                      >
                        {convertToRs(data.deliveryCharges)}
                      </Typography>
                    </Box>
                  )}

                  {data.packagingCharges > 0 && (
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        style={{ fontSize: 14 }}
                        className={classes.price_details_text}
                        variant="subtitle1"
                      >
                        Packaging Charges
                  </Typography>

                      <Typography
                        style={{ fontSize: 14 }}
                        className={classes.price_details_text}
                        fontWeight="fontWeightMedium"
                      >
                        {convertToRs(data.packagingCharges)}
                      </Typography>
                    </Box>
                  )}


                </div>}


              {totalTaxes > 0 && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    style={{ fontSize: 14 }}
                    className={classes.price_details_text}
                    variant="subtitle1"
                  >
                    Taxes
                  </Typography>

                  <Typography
                    style={{ fontSize: 14 }}
                    className={classes.price_details_text}
                    fontWeight="fontWeightMedium"
                  >
                    {convertToRs(totalTaxes)}
                  </Typography>
                </Box>
              )}

              {data.discount > 0 && (
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    style={{ fontSize: 13, color: '#FFA500' }}
                    className={classes.price_details_text}
                    variant="subtitle1"
                  >
                    Coupon discount ({data.couponCode})
                  </Typography>

                  <Typography
                    style={{ fontSize: 14 }}
                    className={classes.price_details_text}
                    fontWeight="fontWeightMedium"
                  >
                    {convertToRs(data.discount)}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box
              display="flex"
              alignItems="center"
              flex="50%"
              p={2}
            // className="paper-align"
            // style={{ borderTop: '1.5px solid rgb(235,235,235)' }}
            >
              <Box display="flex" width="100%" justifyContent="space-between">
                <Typography
                  style={{ fontSize: 15 }}
                  className={classes.price_details_text}
                  fontWeight="fontWeightMedium"
                >
                  Total
                </Typography>

                <Typography
                  style={{ fontSize: 15 }}
                  className={classes.price_details_text}
                  fontWeight="fontWeightMedium"
                >
                  {convertToRs(getTotalPrice())}
                </Typography>
              </Box>
            </Box>
          </Box>

          {data.status == 'Delivered' ? (
            <Button
              fullwidth
              onClick={() => setIsOpen(true)}
              variant="outlined"
              color="primary"
              className={classes.refundButton}
              size="large"
            >
              Refund/Return
            </Button>
          ) : null}
        </Box>
      </Container>
    </Box>
  );
};

const mapStateToProps = ({ userCartData }) => ({
  userCartData
});

export default connect(mapStateToProps)(Individual_Order);
