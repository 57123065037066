import React, { useEffect, useState } from 'react';
import { AppBar, Typography, Button, Grid } from '@material-ui/core';
import { useStyles } from './style';
import './style.scss';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ConfitmationTick from '../../assets/images/success.png';
import FailureCross from '../../assets/images/failed.png';
import Loader from '../../assets/images/progress.png';

import { Redirect } from 'react-router-dom';
import { CART_ROUTE } from '../../utils/routesConstants';
import { replaceToHome, replaceWithRoute } from '../../utils/history';
import { usePaymentStatusHook } from './hooks';

const OrderConfirmation = ({ payment }) => {
    const classes = useStyles();
    const location = useLocation();
    const { checkPaymentStatus } = usePaymentStatusHook();
    const orderId = window.sessionStorage.getItem('orderId');
    const data = location.state;
    useEffect(() => {
        // console.log("Before Payment error", orderId, data)

        checkPaymentStatus(data);
    }, [])

    useEffect(() => {

        if (payment.paymentStatusError) {
            // console.log("After Payment error", payment)
            setInterval(function () { replaceWithRoute(CART_ROUTE); }, 6000);

        }
    }, [payment])

    // if (!data) return <Redirect to={HOME_ROUTE} />;


    if (payment.paymentStatusLoader)
        return <div style={{ display: 'flex', justifyContent: 'center', height: '80vh' }}>
            < CenterComp
                classes={classes}
                img={Loader}
                title={'Your Payment is processing.'}
                subtitle={'Please wait until the payment completed, we will take you back to the order details page.'}
            ></CenterComp>

        </div>
    if (payment.paymentStatusError)
        return <div style={{ display: 'flex', justifyContent: 'center', height: '80vh' }}>
            < CenterComp
                classes={classes}
                img={FailureCross}
                title={' Your Payment has been Cancelled.'}
                subtitle={`There is issue occured while making payment. If any amount got detected, dont worry! it will get reverted. Redirecting you to Cart...`}
            >
            </CenterComp>


        </div>
    const redirectionLink = `https://${window.location.hostname}/i/${payment?.paymentData.storeId}/${payment?.paymentData.firebaseId}`;

    return (
        <div className="out-div">
            <AppBar className={classes.appBar} position="static">
                <Typography className={classes.appText} variant="h6">
                    Order Placed
        </Typography>
            </AppBar>
            <div
                style={{ display: 'flex', justifyContent: 'center', height: '80vh' }}
            >

                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                    spacing={2}
                >

                    < CenterComp
                        classes={classes}
                        img={ConfitmationTick}
                        title={`${payment?.paymentData.storeName} has received your order.`}
                        subtitle={'Thank you for shopping!'}
                    ></CenterComp>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            href={redirectionLink}
                            color="primary"
                            style={{ fontSize: 12, textDecoration: 'none' }}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Typography
                                className={classes.underConstructionText}
                                style={{ marginTop: 20 }}
                                align="center"
                                variant="subtitle2"
                            >
                                View invoice
              </Typography>
                        </Button>
                    </div>


                    <Grid item className={classes.grid}>
                        <Button
                            className={classes.button}
                            startIcon={<ShoppingCartIcon className={classes.back_icon} />}
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                replaceToHome();
                            }}
                        >
                            Continue Shopping
            </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
const mapStateToProps = ({ payment }) => ({
    payment
});

export default connect(mapStateToProps)(OrderConfirmation);




const CenterComp = ({ img, title, subtitle, classes, redirectionLink }) => {
    return (
        <Grid container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={2}>
            <Grid item container alignItems="center" justify="center">
                <img
                    className={classes.responsiveImg}
                    src={img}
                    alt={img}
                />
            </Grid>
            <Grid item>
                <Typography
                    className={classes.underConstructionText}
                    align="center"
                    variant="subtitle1"
                >
                    {title}
                </Typography>

                <Typography
                    className={classes.underConstructionText}
                    align="center"
                    variant="subtitle2"
                >
                    {subtitle}
                </Typography>
            </Grid></Grid>
    );
};


