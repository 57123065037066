import httpStatus from 'http-status';
import fetch from 'node-fetch';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import { isNilOrEmpty } from '../utils/helper';
import ReduxStore from '../store';
import { getFirebaseToken } from './authService';

const getResponseBody = (contentType = '', response) => {
  if (contentType.includes('text/plain')) {
    return response.text();
  }
  if (contentType.match('text/*')) {
    return response.text();
  }
  if (contentType.includes('application/json')) {
    return response.json();
  }
  if (contentType.includes('application/x-www-form-urlencoded;charset=UTF-8')) {
    return response;
  }
  if (contentType.match('application/*')) {
    return response.blob();
  }
  if (contentType.match('audio/*')) {
    return response.blob();
  }
  if (contentType.match('image/*')) {
    return response.blob();
  }
  if (contentType.match('video/*')) {
    return response.blob();
  }
  if (contentType.match('message/*')) {
    return response.blob();
  }
  if (contentType.match('Aplication/*')) {
    return response.blob();
  }
  if (contentType.match('x-world/*')) {
    return response.blob();
  }
  return response.text();
};

const handleSuccessResponse = async (contentType, response) => {
  let returnObj = {};

  if (response.status === httpStatus.NO_CONTENT) {
    return returnObj;
  }

  if (contentType.includes('application/octet-stream')) {
    const blobData = await getResponseBody(contentType, response);
    returnObj = { url: URL.createObjectURL(blobData) };
  } else {
    returnObj = await getResponseBody(contentType, response);
  }
  return returnObj;
};

const handleFailureResponse = async (contentType, response) => {

  let returnObj = {};
  let errorDataFromServer = null;
  if (contentType.includes('application/octet-stream')) {
    const blobData = await getResponseBody(contentType, response);
    errorDataFromServer = { url: URL.createObjectURL(blobData) };
  } else {
    errorDataFromServer = await getResponseBody(contentType, response);
  }
  if (_.isNil(errorDataFromServer)) {
    returnObj.message = httpStatus[response.status];
  } else {
    returnObj = errorDataFromServer;

  }

  return returnObj;
};

const responseHandler = async (response, resolve, reject) => {
  const metaData = {
    responseStatus: response.status
  };
  let returnObj = {};
  try {
    let contentType = response.headers.get('content-type') || '';
    if (contentType) {
      contentType = contentType.toLowerCase();
    }

    if (response.ok) {
      returnObj = await handleSuccessResponse(contentType, response);
      returnObj = _.merge(returnObj, metaData);

      return resolve(returnObj);
    }
    returnObj = await handleFailureResponse(contentType, response);
    returnObj = _.merge(returnObj, metaData);

    return reject(returnObj);
  } catch (error) {
    returnObj.error = error.message ? error.message : true;
    returnObj = _.merge(returnObj, metaData);

    return reject(returnObj);
  }
};

function getBaseApiEndPoint() {
  return process.env.REACT_APP_SERVER_BASE_URL;
}

async function handleNetworkCall(apiObject) {
  const fetchObject = {};
  let body = {};
  fetchObject.method = apiObject.method ? apiObject.method : 'GET';

  let isAuthenticationRequired = true;
  if (typeof apiObject.authenticationRequired === 'boolean') {
    isAuthenticationRequired = apiObject.authenticationRequired;
  }

  fetchObject.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  };

  fetchObject.headers = apiObject.headers
    ? { ...fetchObject.headers, ...apiObject.headers }
    : { ...fetchObject.headers };
  if (apiObject.removeHeader) {
    delete fetchObject.headers['Content-Type'];
    delete fetchObject.headers.Accept;
  }
  body = apiObject.body;
  // body = apiObject.body ? JSON.stringify(apiObject.body) : {};

  if (fetchObject.method === 'GET') {
    // fetchObject.body = undefined;
  } else {
    fetchObject.body = body;
  }

  if (isAuthenticationRequired) {
    if (isNilOrEmpty(fetchObject.headers.authorization)) {
      const accessToken = await getFirebaseToken();

      //console.log(`access token before `, accessToken);

      fetchObject.headers.authorization = `Bearer ${accessToken}`;
      // fetchObject.mode = 'cors';
    }
  }

  var url = `${getBaseApiEndPoint()}${apiObject.endPoint}`;
  return new Promise(async (resolve, reject) => {
    try {
      var fetchResult;
      // console.log(url, fetchObject, "url, fetchObject");
      if (fetchObject.method === 'GET') {
        fetchResult = await fetch(url, fetchObject);
      } else {
        fetchResult = await fetch(url, fetchObject);
      }
      // console.log(fetchResult, "err,fetchResult fetchObject");

      return responseHandler(fetchResult, resolve, reject);
    } catch (err) {
      // console.log(err, "err, fetchObject");

      return reject({
        error: 'someThing Unexpected Happened',
        msg: err.message || 'Something Went Wrong'
      });
    }
  });
}

var refreshCount = 0;
export function callApi(apiObject) {
  return new Promise(async (resolve, reject) => {
    var prevApiObject = apiObject;
    var online = window.navigator.onLine;

    try {
      if (online) {
        const response = await handleNetworkCall(apiObject);
        refreshCount = 0;
        resolve(response);
      } else {
        throw new Error('Please Check Your Internet Connection')

      }
    } catch (err) {
      if (err.responseStatus === httpStatus.INTERNAL_SERVER_ERROR) {
        toastr.error(`An error has ocurred, please try again.`);
      }
      if (err.responseStatus === httpStatus.UNAUTHORIZED) {
        refreshCount = refreshCount + 1;
        if (refreshCount < 3) {
          // var result = await getFirebaseToken();
          // console.log('access token m here result', result);
          var response = await callApi(prevApiObject);
          return resolve(response);
        } else {
          refreshCount = 0;
          toastr.error('Login Token expired, please Login again.');
          return resolve([]);
        }
        // ReduxStore.dispatch(startLogout());
      }
      reject(err);
    }
  });
}

export function callApiParallel(apiObject) {
  const responseObj = {
    showError: false,
    message: '',
    response: []
  };
  return new Promise(async (resolve, reject) => {
    try {
      const response = await handleNetworkCall(apiObject);
      // resolve(response);
      resolve({ ...responseObj, response });
    } catch (err) {
      if (err.responseStatus === httpStatus.UNAUTHORIZED) {
        toastr.error('Login Token expired, please Login again.jjjjjjj');

        // ReduxStore.dispatch(startLogout());
      }
      // resolve(err);
      resolve({ ...responseObj, showError: false });
    }
  });
}
export default callApi;
