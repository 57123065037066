import React from 'react';
import {
    Avatar,
    InputLabel,
    Box
} from '@material-ui/core';

const ColourSelectItem = ({ colorList, selectedItem, handleVarients }) => {
    return (
        <Box >
            <InputLabel
                style={{ paddingTop: 10, paddingBottom: 10, fontWeight: '500', color: '#333333' }}
            >
                Colour
            </InputLabel>
            <Box display="flex" flexWrap='wrap'>
                {colorList.map((a, i) => (
                    <Avatar
                        variant='square'
                        style={{
                            backgroundColor: a,
                            width: 60,
                            height: 60,
                            zIndex: 0,
                            border:
                                selectedItem && a === selectedItem
                                    ? '3px solid #5C4DB1'
                                    : '2px solid rgb(235,235,235)',
                            // borderRadius: 25,
                            marginRight: 10,
                            marginBottom: 10,
                            color: a
                        }}
                        onClick={() => {
                            handleVarients({ colorSelected: a });
                        }}
                    >
                        {a}
                    </Avatar>
                ))}
            </Box>
        </Box>
    );
}
export default ColourSelectItem;
