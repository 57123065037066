import _ from 'lodash';

export const oldSchemaToNewSchemaVariants = async (items) => {
  if (items !== undefined && items !== null && items.length > 0) {
    for (var i = 0; i < items.length; i++) {
      var variantList = [];
      const sizeList = _.compact(
        items[i].variants?.map((e) => {
          if (e.type.trim().toLowerCase() === 'size') return e.list;
        })
      );
      const colorList = _.compact(
        items[i].variants?.map((e) => {
          if (e.type.trim().toLowerCase() === 'color') return e.list;
        })
      );

      var mergedSizeList = [].concat.apply([], sizeList);
      var mergedColorList = [].concat.apply([], colorList);

      for (var j = 0; j < mergedColorList.length; j++) {
        let _new = {
          colour: mergedColorList[j],
          units: '',
          cp: 0,
          mrp: 0,
          sp: items[i].price,
          weight: '',
          stock: 0,
          lowStock: 0,
          images: [],
          isDeleted: false,
          isDisabled: false,
          type: '',
          list: [],
          value: ''
        };

        if (mergedSizeList.length)
          for (var k = 0; k < mergedSizeList.length; k++) {
            _new['varientId'] = randomString(10);
            _new['size'] = mergedSizeList[k];

            variantList.push(_new);
          }
        else {
          _new['varientId'] = randomString(10);
          variantList.push(_new);
        }
      }
      if (!mergedColorList.length) {
        for (var k = 0; k < mergedSizeList.length; k++) {
          let _new = {
            varientId: randomString(10),
            colour: '',

            units: '',
            cp: 0,
            mrp: 0,
            sp: items[i].price,
            weight: '',
            stock: 0,
            lowStock: 0,
            images: [],
            isDeleted: false,
            isDisabled: false,
            type: '',
            list: [],
            value: '',
            size: mergedSizeList[k]
          };
          variantList.push(_new);
        }
      }
      if (
        variantList !== undefined &&
        variantList !== null &&
        variantList.length > 0
      ) {
        items[i].variants = variantList;
      }
    }
  }
  return items;
};

const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXY0123456789';
const randomString = (strlen) => {
  let result = '';
  for (var i = strlen; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};
