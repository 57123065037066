import React from 'react';
import { Paper, Typography, Box, Container } from '@material-ui/core';
import { connect } from 'react-redux';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import { navigateTo, } from '../../../../utils/history';
import { CART_ROUTE } from '../../../../utils/routesConstants';

const ProceedToCart = ({ userCartData, storeData, shareText }) => {
    if (!userCartData.cartData.length)
        return (
            <div></div>
            // <Box position="fixed" bottom="70px" right={20}>
            //     <a
            //         className="whats-app"
            //         href={`https://api.whatsapp.com/send?text=${shareText}&phone=91${storeData.storeData.mobileNumber}`}
            //         // href={`https://wa.me?phone=91${storeData.storeData.mobileNumber}&text=${shareText}`}
            //         target="_blank"
            //         rel="noopener noreferrer"
            //     >
            //         <WhatsAppIcon />
            //     </a>
            // </Box>
        )
    var totalPrice = 0;
    userCartData.cartData.map((data, index) => {
        totalPrice += data.quantity * data.price;
    });
    return (
        <Paper
            className="bottom-cart"
            style={{
                border: '0.5px solid #EBEBEB',
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Container >
                <Box display="flex" justifyContent="space-between" width='100%' py={2} >
                    <Box>
                        <Box display='flex' alignItems="center" flexDirection='row' >
                            <Typography style={{ fontWeight: 'bold' }}>Rs.{totalPrice}</Typography>
                        </Box>
                        <Typography style={{ fontSize: 14 }}>{userCartData.cartData.length}  Items total</Typography>
                    </Box>
                    <Box display='flex' alignItems='center' py={1} px={2} className="proceedToCartButton"
                        onClick={() => navigateTo(CART_ROUTE)}
                    >
                        <Typography style={{ fontSize: 14 }}>
                            Proceed to Cart
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Paper>
    );
}
const mapStateToProps = ({ userCartData }) => ({
    userCartData
});
export default connect(mapStateToProps)(ProceedToCart);
