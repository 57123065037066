import actionTypes from '../actionTypes';

const initialState = () => {
    return {
        initiatePaymentLoader: false,
        paymentStatusLoader: false,
        paymentStatusError: false,
        paymentRedirectData: {},
        paymentData: {},
        initiatePaymentError: false,
        tempCheckoutdata: {},
        totalAmountToBePaid: 0

    };
};

export default function (state = initialState(), action = {}) {
    const { type, payload } = action;

    switch (type) {
        case actionTypes.INITIATE_PAYMENT_SUCCESS: {
            return {
                ...state,
                paymentRedirectData: payload.paymentLink,
                totalAmountToBePaid: payload.amount,
                initiatePaymentLoader: false,
                initiatePaymentError: false,
                paymentStatusError: false,
            };
        }
        case actionTypes.INITIATE_PAYMENT_REQUEST: {
            return {
                ...state,
                initiatePaymentLoader: true,
                tempCheckoutdata: payload
            };
        }
        case actionTypes.INITIATE_PAYMENT_FAILURE: {
            return {
                ...state,
                initiatePaymentError: true,
                initiatePaymentLoader: false,

            };
        }

        case actionTypes.CHECK_PAYMENT_STATUS_REQUEST: {
            return {
                ...state,
                paymentStatusLoader: true
            };
        }
        case actionTypes.CHECK_PAYMENT_STATUS_SUCCESS: {
            return {
                ...state,
                paymentData: payload,
                paymentStatusLoader: false,

                paymentStatusError: false

            };
        }
        case actionTypes.CHECK_PAYMENT_STATUS_FAILURE: {
            return {
                ...state,
                paymentStatusLoader: false,
                paymentStatusError: true
            };
        }
        case actionTypes.CLEAR_PAYMENT_DATA: {
            return {
                ...state,
                tempCheckoutdata: {},
                paymentRedirectData: '',
                totalAmountToBePaid: 0,
                paymentStatusLoader: false,
                initiatePaymentError: false,
                paymentStatusError: false,
                initiatePaymentLoader: false,
                // paymentData: {}
            };
        }


        default:
            return state;
    }
}
