import React from 'react';
import {
  Box,
  Grid,
  InputLabel,
} from '@material-ui/core';
import _ from 'lodash';

const SizeSelectItem = ({
  sizeList,
  selectedItem,
  handleVarients,
  uniqueOptionAvailable,
  type,
  multipleVariantCheck = false
}) => {

  return (
    <Grid >
      <InputLabel
        style={{ paddingTop: 10, paddingBottom: 10, fontWeight: '500', color: '#333333' }}
      >
        {type === 'weightSelected' ? 'Weight/Portion' : 'Size'}
      </InputLabel>
      <Box
        display='flex'
        flexWrap='wrap'
      >
        {sizeList.map((a, i) => (
          <Box
            style={{
              // backgroundColor: a,
              paddingTop: 5,
              paddingRight: 20,
              paddingBottom: 5,
              paddingLeft: 20,
              border:
                selectedItem && a === selectedItem
                  ? '3px solid #5C4DB1'
                  : '2px solid rgb(235,235,235)',
              marginRight: 10,
              marginBottom: 10,
              backgroundColor: multipleVariantCheck ? (uniqueOptionAvailable?.length == 0 || _.find(uniqueOptionAvailable, { size: a })) ? "white" : "lightgrey" : "white",
            }}
            onClick={() => {
              var ob = {};
              ob[type] = a;
              handleVarients(ob);

            }}
          >
            {a}
          </Box>


        ))}
      </Box>
    </Grid>
  );
}
export default SizeSelectItem;
