import React from 'react';
import ShimmerItem from './shimmerItem';

import '../styles.scss';

function ShimmerSkeleton() {
  return (
    <div>
      <div className="skeleton-container-store">
        <div className="storelogo">
          <div className="background fast"></div>
        </div>
        <div className="storename-loader-1">
          <div className="background slow"></div>
        </div>
        <div className="subtitle-loader-email">
          <div className="background slow"></div>
        </div>
        <div className="subtitle-loader-contact">
          <div className="background slow"></div>
        </div>
        <div className="cart-loader-1">
          <div className="background fast"></div>
        </div>
      </div>
      <div className="skeleton-container-store">
        <div className="tab1 tab">
          <div className="background fast"></div>
        </div>
        <div className="tab2 tab">
          <div className="background slow"></div>
        </div>
        <div className="tab3 tab">
          <div className="background slow"></div>
        </div>
        <div className="tab4 tab">
          <div className="background slow"></div>
        </div>
        <div className="tab5 tab">
          <div className="background slow"></div>
        </div>
      </div>
      <ShimmerItem></ShimmerItem>
      <ShimmerItem></ShimmerItem>
      <ShimmerItem></ShimmerItem>
      <ShimmerItem></ShimmerItem>
      <ShimmerItem></ShimmerItem>
      <ShimmerItem></ShimmerItem>
      <ShimmerItem></ShimmerItem>
      <ShimmerItem></ShimmerItem>
      <ShimmerItem></ShimmerItem>
      <ShimmerItem></ShimmerItem>
      <ShimmerItem></ShimmerItem>
    </div>
  );
}

export default ShimmerSkeleton;
