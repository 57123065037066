import _ from 'lodash';
import moment from 'moment';

export const isNilOrEmpty = value =>
  _.isNil(value) || _.isEmpty(value) || _.isNull(value) || _.isNaN(value);

export const isPresent = value => !isNilOrEmpty(value);

// export const changeDate = date => {
//   let monthNames = [
//     'Jan',
//     'Feb',
//     'Mar',
//     'Apr',
//     'May',
//     'Jun',
//     'Jul',
//     'Aug',
//     'Sep',
//     'Oct',
//     'Nov',
//     'Dec',
//   ];
//   var date = new Date(date);
//   var hours = date.getHours();
//   var minutes = date.getMinutes();
//   var ampm = hours >= 12 ? 'PM' : 'AM';
//   hours = hours % 12;
//   hours = hours ? hours : 12; // the hour '0' should be '12'
//   minutes = minutes < 10 ? '0' + minutes : minutes;
//   var strTime = hours + ':' + minutes + ' ' + ampm;
//   var month = date.getMonth();
//   month = monthNames[month];
//   var dt = date.getDate();

//   return dt + ' ' + month + ', ' + strTime;
// };

// export const storeAvalibilityCheck = onlineStoreData => {
//   onlineStoreData['sat'] = {
//     endEpoch: 1609684513,
//     endTime: 'TimeOfDay(21:30)',
//     isAvailable: true,
//     startEpoch: 1609784513,
//     startTime: 'TimeOfDay(09:30)',
//   };
//   console.log('kkkkkkkkkk', onlineStoreData);

//   let currentDay = moment().format('dddd').toLowerCase().substr(0, 3);
//   let days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
//   let currentDayIdx = days.findIndex(ele => {
//     return ele === currentDay;
//   });
//   console.log('kkkkkkkkkk', currentDay, currentDayIdx);

//   let finalDayIdx = currentDayIdx + 1;
//   let currentSrtEpoch = moment();
//   let tdySrtEpoch = moment.unix(
//     onlineStoreData[days[currentDayIdx]].startEpoch
//   );
//   let tdySrtEpochHr = tdySrtEpoch.format('HH');
//   let currentSrtEpochHr = currentSrtEpoch.format('HH');
//   if (Number(currentSrtEpochHr) < Number(tdySrtEpochHr)) {
//     finalDayIdx = currentDayIdx;
//   }

//   for (let i = finalDayIdx; i < days.length; ++i) {
//     if (!onlineStoreData[days[i]].isAvailable) {
//       ++finalDayIdx;
//     } else {
//       break;
//     }
//   }
//   console.log(finalDayIdx, 'kkkkkkkkkk');

//   if (onlineStoreData[days[finalDayIdx]]) {
//     let nextDayStartHrEpoch = moment.unix(
//       onlineStoreData[days[finalDayIdx]].startEpoch
//     );
//     let nextDayStartHr = nextDayStartHrEpoch.format('HH');
//     let nextDayStartMin = nextDayStartHrEpoch.format('mm');

//     if (finalDayIdx - currentDayIdx > 1) {
//       return [finalDayIdx - currentDayIdx, ''];
//       // setMoreThan24(finalDayIdx - currentDayIdx);
//     } else {
//       const interval = setInterval(() => {
//         let currentHr = currentSrtEpoch.format('HH');
//         let currentMin = currentSrtEpoch.format('mm');

//         console.log('kkkkkkkkkk', currentHr);

//         // setTimer(currentSrtEpoch + 1);

//         let diffHrs = 24 - Number(currentHr) + Number(nextDayStartHr);
//         let diffMin = Math.abs(Number(nextDayStartMin) - Number(currentMin));
//         if (diffMin > 60) {
//           diffHrs += diffMin % 60;
//           diffMin -= diffMin % 60 * 60;
//         }
//         // setTimerValue(
//         //   `${diffHrs}:${diffMin < 10 ? `0${diffMin}` : diffMin}:00`
//         // );

//         if (currentSrtEpoch + 1 === nextDayStartHrEpoch) {
//           clearInterval(interval);
//         }
//         return ['', `${diffHrs}:${diffMin < 10 ? `0${diffMin}` : diffMin}:00`];
//       }, 1000);
//       return () => {
//         clearInterval(interval);
//       };
//     }
//   }
// };
export const getDocId = () => {
  const currentTimestamp = Math.ceil(Date.now() / 1000);


  const timezone = "Asia/Kolkata";
  const dateTime = moment.unix(currentTimestamp).utc();//.tz(timezone);
  const month = dateTime.format("MM");
  const date = dateTime.format("DD");
  const year = dateTime.format("YY");
  const randomInt = Math.floor(Math.random() * Math.floor(9999));
  return Number(`${year}${month}${date}${randomInt}`);
}