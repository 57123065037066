import actionTypes from '../actionTypes';
import { getLocalStorageTokens } from '../../utils/tokensHelper';

const initialState = () => {
  const { user, isLoggedIn } = getLocalStorageTokens();

  return {
    user,
    isLoggedIn,
    loginSpinner: false,
    error: false,
    cartSync: false,
    errMsg: {}
  };
};

export default function (state = initialState(), action = {}) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.USER_SUCCESS: {
      return {
        ...state,
        user: payload.user,
        isLoggedIn: true,
        loginSpinner: false,
        error: false
      };
    }
    case actionTypes.USER_FAILURE: {
      return {
        ...state,
        loginSpinner: false,
        isLoggedIn: false,
        error: true,
        errMsg: payload.errMsg
      };
    }
    case actionTypes.USER_REQUEST: {
      return {
        ...state,
        loginSpinner: true,
        error: false
      };
    }
    case actionTypes.LOGOUT:
      return initialState;

    default:
      return state;
  }
}
