import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  InputBase,
  Container,
} from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import noOrdersFound from '../../../../src/assets/images/no_orders.png';
import { orderStatus, orderStatusColorCode } from '../../../utils/orderStatus';
import { navigateToWithState } from '../../../utils/history';
import { INDIVIDUAL_ORDER } from '../../../utils/routesConstants';
import LoginScreen from '../../Login/Components/LoginScreen';
import { getLocalStorageTokens } from '../../../utils/tokensHelper';
import ShimmerSkeleton from '../Home/components/shimmer';
import '../Order_Detail/style.scss';
import AppBarContainer from './components/appBar';
import InnerAppBar from '../../Header/innerAppBar'
import { useOrderHook } from './hooks';
import { useStyles } from './style';
import MainHeader from '../../Header/mainHeader';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';

const Order_Details = ({ userData, userOrder, userCartData }) => {
  const { getAllOrders } = useOrderHook();
  const { isLoggedIn } = getLocalStorageTokens();
  const classes = useStyles();
  const [allOrders, setAllOrders] = useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [orderList, setOrderList] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    setHeight(window.innerHeight);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  useEffect(() => {
    // console.log(window.pageYOffset);
  })

  useEffect(
    () => {
      // if (!userOrder.orderData.length){
      if (isLoggedIn) getAllOrders();
      // }
    },
    [userData.user]
  );

  useEffect(
    () => {
      if (allOrders.length > 20)
        setOrderList(allOrders.slice(0, 19))
      else
        setOrderList(allOrders);

    },
    [allOrders]
  );

  useEffect(
    () => {
      if (allOrders) {
        setSearchText('');
        setAllOrders([...userOrder.orderData].reverse());
      }
    },
    [userOrder]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!isLoggedIn) {
    return <LoginScreen />;
  }

  let loadMore = () => {
    // console.log("load More");
    if (orderList.length < allOrders.length) {
      var clonelist = _.clone(orderList, true);
      var sliceProduct = allOrders.slice(clonelist.length, clonelist.length + 20);
      var productConcat = _.concat(clonelist, sliceProduct);
      setOrderList(productConcat)
    }

  }

  const handleSearch = _.debounce(text => {
    setSearchText(text);
    var filtered = userOrder.orderData.filter(function (str) {
      let invId = `#INV-${str.firebaseId}`;
      if (str.storeName.length !== 0)
        return (
          str.storeName.toLowerCase().indexOf(text.toLowerCase()) !== -1 ||
          invId.toLowerCase().indexOf(text.toLowerCase()) !== -1
        );
    });
    setAllOrders(filtered.reverse());
  }, 300);

  var isMobile = true;
  if (width > 1024) isMobile = false;

  return (
    <Box className="main-div-in" >
      {/* <AppBarContainer route="order" userCartData={userCartData} /> */}
      <MainHeader />
      {userOrder.loader
        ? <ShimmerSkeleton />
        : userOrder.error || !userOrder.orderData.length
          ? <Container>
            <div className="home-loader">
              <img src={noOrdersFound} />
              <br />
              <p style={{ textAlign: 'center' }}>No Orders found!!</p>
            </div>
          </Container>
          : <Container>
            {/* <div className="home-loader">{userOrder.errorMsg.error || "SomeThing Went Wrong"}</div> */}
            <InnerAppBar userCartData={userCartData} showInner={true} title="My Orders" bgColor={true} backspace={false} />
            <Box
              mt={[2, 2, 4, 4]}
              minHeight="75vh"
              // p={[1, 1, 3, 3]}
              px={[1, 1, 3, 3]}
              className={classes.rootBgColor}
            >
              <Box
                py={[1, 1, 3, 3]}
                width="100%"
                display="flex"
                alignItems={['flex-start', 'flex-start', 'center', 'center']}
                flexDirection={[
                  'column-reverse',
                  'column-reverse',
                  'row',
                  'row',
                ]}
                justifyContent="space-between"
                flexWrap={['wrap', 'wrap', 'nowrap', 'nowrap']}
              >
                {/* <SearchIcon className="search-icon" /> */}
                <Box fontWeight="fontWeightMedium" color="black.darkGray">
                  My Order
                </Box>
                <Box
                  width={['100%', '100%', '40%', '40%']}
                  pb={[1, 1, 0, 0]}
                  display={['block', 'block', 'none', 'none']}
                >
                  <InputBase
                    className="headerInput searchbox"
                    fullWidth
                    placeholder="Search by store name / order Id"
                    onChange={e => handleSearch(e.target.value)}
                  />
                </Box>

                <Box
                  width={['100%', '100%', '40%', '40%']}
                  pb={[1, 1, 0, 0]}
                  display={['none', 'none', 'block', 'block']}
                  textAlign="right"
                >
                  <InputBase
                    className="headerInput1 searchbox"
                    fullWidth
                    placeholder="Search by store name / order Id"
                    onChange={e => handleSearch(e.target.value)}
                  />
                </Box>
              </Box>

              {searchText.length > 0 && allOrders.length === 0
                ? <Box className="home-loader">
                  <Box textAlign="center">No search results found!!</Box>
                </Box>
                : <InfiniteScroll dataLength={orderList.length}
                  next={loadMore}
                  hasMore={orderList.length < allOrders.length}
                  loader={<h4>Loading...</h4>}
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  onScroll={(y) => {
                    // console.log(y);
                    if (window.pageYOffset < 60) {
                      setOrderList(allOrders.slice(0, 19))
                    }

                  }}
                ><Grid
                  container
                // className={classes.orderItemSpacing}
                // spacing={2}
                >
                    {orderList.map((data, index) => {
                      let colorCode = orderStatusColorCode(data.status);
                      return (
                        <Grid item xs={12} md={6}>
                          <Box
                            mx={[0, 0, 1, 1]}
                            my={1}
                            display="flex"
                            justifyContent="space-between"
                            flexWrap={['wrap', 'wrap', 'nowrap', 'nowrap']}
                            p={2}
                            border={[
                              '0',
                              '0',
                              '1px solid #E1E1E1',
                              '1px solid #E1E1E1',
                            ]}
                            // borderColor="secondary.border"
                            borderRadius={5}
                            className={[
                              classes.cardBgColor,
                              'cursor-pointer',
                            ]}
                            onClick={() =>
                              navigateToWithState(INDIVIDUAL_ORDER, data)}
                          >
                            <Box>
                              <Typography
                                className={classes.order_text_size}
                                fontWeight="fontWeightMedium"
                              >
                                {data.storeName}
                              </Typography>
                              <Typography
                                className={classes.text}
                              // style={{ color: '#5D4DB1' }}
                              >
                                #INV-{data.firebaseId}
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-end"
                            >
                              <Box display="flex" alignItems="center">
                                <Box
                                  className="order-color-code"
                                  style={{ background: colorCode }}
                                />
                                <Typography
                                  className={classes.order_text_size}
                                >
                                  {orderStatus(data.status)}
                                </Typography>
                              </Box>
                              <Typography className={classes.text}>
                                {' '}
                                {moment(data.createdAt).format('lll')}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}

                  </Grid></InfiniteScroll>}
            </Box>
            {!isMobile && visible ?
              <Box className="back-to-top-button" onClick={scrollToTop}>
                <ArrowUpwardSharpIcon style={{ fontSize: '30px', }} />
              </Box>
              : <></>}
          </Container>}
    </Box>
  );
};

// export default Order_Details;
const mapStateToProps = ({ userData, userOrder, userCartData }) => ({
  userData,
  userOrder,
  userCartData,
});

export default connect(mapStateToProps)(Order_Details);

// <Paper
//   elevation={0.5}
//   className="main-align-order-item"
//   onClick={() => navigateToWithState(INDIVIDUAL_ORDER, data)}
// >
//   <Grid
//     className="grid-bottom-style"
//     item
//     container
//     direction="row"
//   >
//     <Grid item xs={7}>
//       <Typography className={classes.order_text_size}>
//         <Box fontWeight="fontWeightMedium">
//           {data.storeName}
//         </Box>
//       </Typography>
//     </Grid>
//     <div style={{ display: 'flex', alignItems: 'center' }}>
//       <Grid
//         item
//         className="order-color-code"
//         style={{ background: colorCode }}
//       ></Grid>
//       <Grid item>
//         <Typography className={classes.order_text_size}>
//           {orderStatus(data.status)}
//         </Typography>
//       </Grid>
//     </div>
//   </Grid>
//   <Grid
//     sm
//     item
//     container
//     direction="row"
//     justify="space-between"
//     style={{
//       display: 'flex',
//       justifyContent: 'space-between'
//     }}
//   >
//     <Grid item>
//       <Typography
//         className={classes.text}
//         style={{ color: '#5D4DB1' }}
//       >
//         #INV-{data.firebaseId}
//       </Typography>
//     </Grid>
//     <Grid item>
//       <Typography className={classes.text}>
//         {moment(data.createdAt).format('lll')}
//       </Typography>
//     </Grid>
//   </Grid>
// </Paper>
