import React, {Component} from 'react';
import ImageGallery from 'react-image-gallery';
import {Box} from '@material-ui/core';

import 'react-image-gallery/styles/css/image-gallery.css';
import MyReactImageMagnify from './imageMagnifier';

class MyImageGallery extends Component {
  constructor (props) {
    super (props);
    this.state = {modelOpen: false};
    this.imageState = {loaded: false};
  }
  imageClickHandler (e) {
    this.setState ({modelOpen: true});
  }

  myRenderItem (image) {
    return <MyReactImageMagnify {...this.props} image={image.original} />;
  }
  render () {
    var imagesObject = [];

    this.props.imageItems.map (i => {
      imagesObject.push ({
        original: i,
        thumbnail: i,
        originalClass: 'originalImage',
        thumbnailClass: 'thumbnailImage',
      });
    });
    this.imageState.loaded = true;

    const properties = {
      thumbnailPosition: 'left',
      showNav: false,
      showFullscreenButton: false,
      useBrowserFullscreen: true,
      showPlayButton: false,
      renderItem: this.myRenderItem.bind (this),
      items: imagesObject,
      slideOnThumbnailOver: true,
      // onClick: this.imageClickHandler.bind(this)
    };

    return (
      <Box>
        {this.imageState.loaded
          ? <ImageGallery
              {...properties}
              display="flex"
              justifyContent="center"
              alignItems="center"
              lazyLoad="true"
              showNav="true"
              makeStyles={{backgroundColor: 'transparent'}}
            />
          : <div className="flex-center pt-200">
              <div className="loader small-loader" />
            </div>}
        <div />

      </Box>
    );
  }
}

export default MyImageGallery;
