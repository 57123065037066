import { Typography, Button, Box } from '@material-ui/core';
import OtpInput from 'react-otp-input';
import React from 'react';
import '../style.scss';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toastr } from 'react-redux-toastr';

const Verification = ({
  code,
  setCode,
  onVerificationCodeSubmit,
  userData,
  disable
}) => {

  const handleSubmit = (e) => {
    e.preventDefault();
    if (code && /^[0-9]{6}$/.test(code)) {
      onVerificationCodeSubmit();
    } else {
      toastr.error('Enter OTP to login');
    }
  };
  return (
    <Box display='flex'
      width='100%'
      justifyContent='center'
    >
      {/* <React.Fragment> */}
      {userData.loginSpinner ? (
        <div className="home-loader">
          <CircularProgress />
        </div>
      ) : (
          <Box display='flex' flexDirection='column' width='100%'>
            <form onSubmit={handleSubmit}>
              {/* <Box alignContent="flex-start" pb={5}>
                <Typography variant="h6" >
                  Please Login{' '}
                </Typography>
              </Box> */}
              <Box alignContent="flex-start" pb={2} fontWeight={500}>
                <Typography >
                  Enter the OTP
                </Typography>
              </Box>
              <OtpInput
                containerStyle={{ height: 'auto', marginBottom: 14, justifyContent: 'space-between', flex: '1 1 0' }}
                inputStyle={{
                  width: '100%',
                  height: '3em',
                  border: '1px solid rgb(225,225,225)',
                  borderRadius: 4
                }}
                value={code}
                isInputNum={true}
                onChange={(otp) => {
                  setCode(otp);
                }}
                separator={<div>&nbsp;</div>}
                numInputs={6}
              />
              <Button
                type="submit"
                mt={1}
                width="100%"
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                disabled={disable}
              // startIcon={<CircularProgress size={15} />}
              >
                {disable ? <CircularProgress size={16} /> : null}
                <Box pl={1}>Login</Box>
              </Button>
            </form>
          </Box>
        )}
      {/* </React.Fragment> */}
    </Box>
  );
};
const mapStateToProps = ({ userData }) => ({
  userData
});

export default connect(mapStateToProps)(Verification);
