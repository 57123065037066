import { takeLatest, put, call } from 'redux-saga/effects';
import actionTypes from '../actionTypes';
import {
  getAllOrdersLoader,
  getAllOrdersSuccess,
  getAllOrdersFailure
} from '../actions/userActions';
import { getLocalStorageTokens } from '../../utils/tokensHelper';
import * as ApiService from '../../services/apiService';
import { toastr } from 'react-redux-toastr';
import { oldSchemaToNewSchemaVariants } from '../../services/varientsSchemamapping'

function* getAllOrders() {
  try {
    // yield put(getAllOrdersLoader());
    const allOrders = yield call(get_order_data);
    // console.log(allOrders, 'itemsitemsitems')
    for (var i = 0; i < allOrders.data?.length; i++) {

      allOrders.data[i].items = yield oldSchemaToNewSchemaVariants(allOrders.data[i].items);
    }
    // console.log(allOrders, 'itemsitemsitemsafter')



    yield put(getAllOrdersSuccess(allOrders.data));
  } catch (error) {
    yield put(getAllOrdersFailure(error));
    if (error.responseStatus !== 404)
      toastr.error(error.message || error.error || 'Something went wrong!');
  }
}

export default [takeLatest(actionTypes.GET_ALL_ORDERS, getAllOrders)];

async function get_order_data() {
  const { user } = getLocalStorageTokens();
  const uId = user.uuid;

  return new Promise(async function (resolve, reject) {
    try {
      const APIObj = {
        endPoint: `secure/orders/${uId}`,
        authenticationRequired: true,
        method: 'GET'
      };
      var responseJson = await ApiService.callApi(APIObj);
      resolve(responseJson);
    } catch (err) {
      reject(err);
    }
  });
}
