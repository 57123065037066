import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import ReduxToastr from 'react-redux-toastr';
import history from '../../utils/history';
import {store, persistor} from '../../store';
import AppRoutes from '../../routes';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {PersistGate} from 'redux-persist/integration/react';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './styles.scss';

function App () {
  const theme = createMuiTheme ({
    palette: {
      primary: {
        main: '#5C4DB1',
      },
      secondary: {
        main: '#FFFFFF',
        border: '#E1E1E1',
      },
      black: {
        dark: '#000000',
        darkGray: '#333333',
        darkLight: '#4d4d4d',
      },
      white: {
        main: '#fff',
      },
    },
    typography: {
      fontFamily: `"Poppins"`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      button: {
        textTransform: 'none',
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <div className="app-container">
          <ConnectedRouter history={history}>
            <PersistGate persistor={persistor}>
              <AppRoutes />
            </PersistGate>
          </ConnectedRouter>

          <ReduxToastr
            timeOut={4000}
            newestOnTop={false}
            preventDuplicates
            position="bottom-left"
            getState={state => state.toastr}
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
        </div>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
