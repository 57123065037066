import React from 'react';
import PlaceHolder from '../../../assets/images/image_placeholder.png';

function ImagePlaceholder() {
    return (
        <div
            style={{
                width: '100%',
                height: '50vh',
                backgroundColor: 'lightgrey',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <img
                alt=""
                style={{
                    width: 50,
                    height: 50
                }}
                src={PlaceHolder}
            />
        </div>
    );
}
export default ImagePlaceholder;
