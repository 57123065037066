import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { requestGetAllOrders } from '../../../store/actions/userActions';

export const useOrderHook = () => {
  const dispatch = useDispatch();

  const getAllOrders = useCallback(() => {
    dispatch(requestGetAllOrders());
  }, [dispatch]);

  

  return { getAllOrders, };
};
