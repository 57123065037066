import React from 'react';
import { AppBar, Typography, InputBase, Grid } from '@material-ui/core';
import { useMarketHomeHook } from './hooks';
import { useStyles } from './style';
import './style.scss';
import Category from './Components/Category';
import { getStoreIdBasedOnDomain } from '../../utils/domainStoreIdMapping';
import { useParams } from 'react-router-dom';
import UnderConstruction from '../../assets/images/loginimage.png';
import Tab from '../Tabs';

const MarketPlace = () => {
  const classes = useStyles();
  var isCustom = getStoreIdBasedOnDomain();
  const { storeId } = useParams();


  if (storeId === undefined && isCustom !== undefined) {
    window.sessionStorage.setItem('storeId', storeId);
    return <Tab></Tab>;
  }
  return (
    <div className="out-div">
      <AppBar className={classes.appBar} position="static">
        <Typography className={classes.appText} variant="h6">
          Marketplace
        </Typography>
      </AppBar>
      <div className="inputContainer">
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={2}
          style={{ minHeight: '100vh' }}
        >
          <Grid item container alignItems="center" justify="center">
            <img
              className={classes.responsiveImg}
              src={UnderConstruction}
              alt={UnderConstruction}
            />
          </Grid>
          <Grid item>
            <Typography className={classes.underConstructionText} variant="h6">
              We are preparing something amazing for you. Please come back later!
            </Typography>
          </Grid>
        </Grid>
      </div>

      {/* <div className="inputContainer">
            <InputBase placeholder="Search by item, service or category" className="headerInput" />
            <Category />
        </div>

      {/* Market Place

         <div style={{ fontSize: 20, color: "red" }} onClick={getVisitedStore}>
            Go to earlier visited store!!!
         </div> */}
    </div>
  );
};

export default MarketPlace;
