export const orderStatus = (status) => {
  switch (status) {
    case 'order_created':
      return 'In Process';
    case 'order_cancelled':
      return 'Cancelled';
    case 'order_completed':
      return 'Completed';
    case 'payment_pending':
      return 'Payment Pending';
    case 'shipped':
      return 'Shipped';
    case 'accepted':
      return 'Accepted';
    case 'refund_return':
      return 'Return/Refund';
    case 'rejected':
      return 'Declined';
    case 'delivered':
      return 'Delivered';
    default:
      return 'Declined';
  }
};

export const orderStatusColorCode = (status) => {
  switch (status) {
    case 'order_created':
      return '#DBA719';
    case 'order_cancelled':
      return '#707070';
    case 'order_completed':
      return '#02B875';
    case 'payment_pending':
      return '#D9512C';
    case 'shipped':
      return '#5C4DB1';
    case 'accepted':
      return '#00BDD9';
    case 'refund_return':
      return '#995D4B';
    case 'rejected':
      return '#FF4500';
    case 'delivered':
      return '#02B875';
    default:
      return '#FF4500';
  }
};
