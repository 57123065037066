const asyncActions = [
  'USER',
  'CHECKOUT',
  'GET_ALL_ORDERS',
  'UPDATE_USER_CART',
  'STORE_API',
  'ADD_ITEM_TO_CART',
  'ITEM_DETAIL',
  'UPDATE_VARIANTS',
  'GET_ALL_COUPONS',
  'INITIATE_PAYMENT',
  'CHECK_PAYMENT_STATUS'
];

export default asyncActions;
