import actionTypes from '../actionTypes';

const initialState = () => {
  return {
    storeData: [],
    loader: true,
    error: false,
    errorMsg: {},
    storeAvailability: false,
    storeDayAvaibility: false,
    storeCurrentDayData: {}
  };
};

export default function (state = initialState(), action = {}) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.STORE_API_SUCCESS: {
      window.localStorage.setItem('storeName', payload.storeData.storeName);
      return {
        ...state,
        storeData: payload.storeData,
        loader: false,
        error: false,
        storeAvailability: payload.storeAvailability,
        storeDayAvaibility: payload.storeDayAvaibility,
        storeCurrentDayData: payload.storeCurrentDayData
      };
    }

    case actionTypes.STORE_API_REQUEST: {
      return {
        ...state,
        loader: payload.loadingStatus
      };
    }

    case actionTypes.STORE_API_FAILURE: {
      return {
        ...state,
        error: true,
        loader: false,
        errorMsg: payload
      };
    }

    default:
      return state;
  }
}
