import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    requestcheckPaymentStatus,
    requestUserCart,
    requestCartCheckout,
} from '../../store/actions/userActions';

export const usePaymentStatusHook = () => {
    const dispatch = useDispatch();
    const getUsercart = useCallback(() => {
        dispatch(requestUserCart());
    }, [dispatch]);

    const cartCheckout = useCallback(
        (checkout_data) => {
            dispatch(requestCartCheckout(checkout_data));
        },
        [dispatch]
    );

    const checkPaymentStatus = useCallback(
        (data) => {
            dispatch(requestcheckPaymentStatus(data));
        },
        [dispatch]
    );
    return {
        getUsercart,
        cartCheckout,
        checkPaymentStatus
    };
};
