import moment from 'moment';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Typography,
  CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const Coupons = ({ handleClose, isMobile, userCartData, callback }) => {
  const [selectedCoupon, setSelectedCoupon] = React.useState(null);

  const handleChange = (e, doc) => {
    if (e.target.checked) setSelectedCoupon(doc);
    else setSelectedCoupon(null);
  };

  const handleSubmit = (e) => {
    if (selectedCoupon) callback(selectedCoupon);
  };

  return (
    <Box
      display="flex"
      height="100%"
      width="100%"
      position="relative"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box height="100%" overflow="scroll">
        <Box>
          <Box
            width="100%"
            mt={isMobile ? '10px' : '55px'}
            justifyContent="space-between"
            alignItems="center"
            display="flex"
          >
            <Box pl={2} fontSize="18px" fontWeight="500">
              Apply Coupon
            </Box>
            <IconButton onClick={handleClose}>
              <CloseIcon size="18" />
            </IconButton>
          </Box>
          <Box borderBottom="1px solid #D3D3D3" marginX="15px"></Box>

          {/* <Box p={2} position="relative" display="flex">
            <InputBase placeholder="Enter coupon code" className="inputCheck" />
            <Button className="checkButton">Check</Button>
          </Box> */}
        </Box>
        <Box
          height={isMobile ? 'calc(100% - 170px)' : 'calc(100% - 230px)'}
          overflow="scroll"
          p={2}
        >
          {userCartData?.couponsLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={isMobile ? '30vh' : '60vh'}
            >
              <CircularProgress />
            </Box>
          ) : userCartData?.coupons?.length == 0 ||
            userCartData?.couponsError ? (
            <Typography
              align="center"
              style={{
                paddingTop: isMobile ? '150px' : '350px'
              }}
            >
              {userCartData?.couponsError
                ? userCartData?.couponsErrMsg
                : 'No coupons found!'}
            </Typography>
          ) : (
            userCartData?.coupons?.map((one) => {
              let _expireDateTime = moment
                .unix(one.endEpoch)
                .format('MMMM Do YYYY, h:mm a');

              return (
                // <Box className="couponItem active" p={2} mb={2}>
                <Box className="couponItem" p={2} mb={2}>
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      color="primary"
                      checked={one.couponId === selectedCoupon?.couponId}
                      onChange={(e) => handleChange(e, one)}
                    />
                    <Box>
                      <Box className="coupon-name" p={1}>
                        {one.code}
                      </Box>
                    </Box>
                  </Box>
                  <Box ml={5}>
                    {one.isPercentage ? (
                      <Typography>Flat {one.discountValue}% off</Typography>
                    ) : (
                      <Typography>Upto Rs.{one.discountValue} off</Typography>
                    )}
                    <Typography>
                      Expires on{' '}
                      <span style={{ color: 'grey', fontSize: '15px' }}>
                        {_expireDateTime}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              );
            })
          )}
        </Box>
      </Box>

      <Box display="flex" p={2}>
        <Button variant="outlined" fullWidth onClick={handleClose}>
          {userCartData?.coupons?.length > 0 ? 'Cancel' : 'Close'}
        </Button>
        {userCartData?.coupons?.length > 0 && <Box paddingLeft="20px"></Box>}
        {userCartData?.coupons?.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            ml={1}
            onClick={handleSubmit}
          >
            Apply
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Coupons;
