import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Divider, Button } from '@material-ui/core';
import EmailIcon from '../../../../assets/images/email.svg';
import CustomerSupport from '../../../../assets/images/customer_support.svg';
import DeliveryTruck from '../../../../assets/images/delivery_truck.svg';
import Surface from '../../../../assets/images/surface.svg';
import Location from '../../../../assets/images/location.svg';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const Footer = ({ classes, storeData, userCartData }) => {
  var isMobile = true;
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  if (width > 840) isMobile = false;
  return (
    <div className={classes.root} style={{ width: "100%", overflow: "hidden" }}>      <Grid
      container
      spacing={3}
      justify="center"
      direction={isMobile ? 'column' : 'row'}
    >
      <Grid item xs={isMobile ? 12 : 3}>
        <Paper className={classes.paper}>
          <Tile
            icon={DeliveryTruck}
            title={'Home Delivery'}
            subtitle={'Super-fast delivery service'}
          ></Tile>
        </Paper>
      </Grid>
      <Grid item xs={isMobile ? 12 : 3}>
        <Paper className={classes.paper}>
          <Tile
            icon={Surface}
            title={'Payment Options'}
            subtitle={'Online Payment, COD, Self-pickup'}
          ></Tile>
        </Paper>
      </Grid>
      <Grid item xs={isMobile ? 12 : 3}>
        <Paper className={classes.paper}>
          <Tile
            icon={CustomerSupport}
            title={'Customer Support'}
            subtitle={`+91${storeData?.storeData?.mobileNumber}`}
          ></Tile>
        </Paper>
      </Grid>
    </Grid>

      <Divider
        style={{ backgroundColor: '#ebebeb', marginTop: 30, opacity: 0.3 }}
      />
      <Grid
        container
        spacing={3}
        justify="center"
        direction={isMobile ? 'column' : 'row'}
      >
        <Grid item xs={isMobile ? 12 : 4}>
          <Grid
            item
            xs={12}
            style={{
              marginBottom: 15,
              marginTop: 15,
              color: 'white',
              padding: 10
            }}
          >
            <Typography variant="body1">Store Details</Typography>
          </Grid>
          <Paper className={classes.paper}>
            <Tile
              icon={Location}
              title={storeData.storeData?.city?.name}
              subtitle={storeData.storeData?.storeAddress}
              isMobile={isMobile}
            ></Tile>
          </Paper>
        </Grid>
        <Grid item xs={1}></Grid>

        <Grid
          container
          item
          xs={isMobile ? 12 : 4}
          justify="space-around"
          alignItems="center"
        >
          {storeData?.storeData?.storeEmail && (
            <Button
              className={classes.button}
              variant="contained"
              onClick={() => {
                window.location.href = `mailto:${storeData?.storeData?.storeEmail}`;
              }}
            >
              <img style={{ marginRight: 10 }} src={EmailIcon}></img>
              {/* <EmailIcon className={classes.back_icon} />{' '} */}
              Email Us
            </Button>
          )}

          {storeData?.storeData?.mobileNumber && (
            <Button
              className={classes.button}
              variant="contained"
              style={{ backgroundColor: '#00CC48' }}
              target="_blank"
              rel="noopener noreferrer"
              href={`https://api.whatsapp.com/send?text=Hello&phone=91${storeData?.storeData?.mobileNumber}`}
            >
              <WhatsAppIcon style={{ marginRight: 5 }} />
              Let's Chat
            </Button>
          )}
        </Grid>
      </Grid>
      {isMobile ? (
        <div style={{ marginBottom: 80 }}></div>
      ) : null}
      {userCartData.cartData?.length ? (
        <div style={{ marginTop: 150 }}></div>
      ) : null}
    </div>
  );
};
export default Footer;

const Tile = ({ icon, title, subtitle, isMobile }) => {
  return (
    <Grid container direction="row">
      <Grid
        item
        style={{
          marginRight: 15,
          backgroundColor: '#F4F4F4',
          width: 45,
          height: 40,
          padding: 6,
          borderRadius: 5
        }}
        justify="center"
        alignItems="center"
      >
        <img style={{ width: 35, height: 25 }} src={icon}></img>
      </Grid>
      <Grid item>
        <Typography variant="body2">{title}</Typography>
        <Grid style={{ maxWidth: isMobile ? 250 : 350 }}>
          <Typography variant="caption">{subtitle}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};