import React, { useEffect, useState } from 'react';
import {
    AppBar,
    IconButton,
    Toolbar,
    Typography,
    Box,
    Badge,
    makeStyles,
} from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CartIcon from '@material-ui/icons/ShoppingCart';
import { backTo } from '../../utils/history';
import history from '../../utils/history'

const useStyles = makeStyles((theme) => ({
    badgeIcon: {
        left: 5,
        height: 17,
        lineHeight: 0.8,
        minWidth: 17,
        background: '#DBA719'
    }
})
)

// import { useStyles } from '../style';
const InnerAppBar = ({ bgColor, backspace, title, showInner, userCartData }) => {

    const classes = useStyles();

    const [cartCount, setCartCount] = useState(0);

    useEffect(() => {
        const sum = userCartData.cartData
            .map((n) => n.quantity)
            .reduce((a, b) => a + b, 0);
        setCartCount(sum);
    }, [userCartData]);
    return (
        <Box pt={['56px', '56px', 5, 5]}>
            <Box display={['block', 'block', 'none', 'none']}>
                <AppBar position="fixed" top='0'>
                    <Toolbar >
                        <Box display="flex" alignItems='center' justifyContent="space-between" width="100%">
                            <Box display='flex' alignItems='center'>
                                {backspace ? (
                                    // <IconButton>
                                    <KeyboardBackspaceIcon
                                        //   className={classes.back_icon}
                                        color="secondary"
                                        onClick={() => backTo()}
                                    />
                                    // </IconButton>
                                )
                                    : null}

                                <Typography
                                    //   className={classes.text_flex}
                                    color="secondary"
                                    variant="subtitle1"
                                >
                                    <Box fontWeight="fontWeightMedium" px={2}>{title}</Box>
                                </Typography>
                            </Box>
                            {title === 'Cart' ? null :
                                <IconButton
                                    variant="outlined"
                                    color="secondary"
                                    className="header-cart-icon-container"
                                    onClick={() => history.push('/cart')}
                                >
                                    <Badge
                                        color="primary"
                                        badgeContent={cartCount}
                                        classes={{
                                            anchorOriginTopRightRectangle: classes.badgeIcon
                                        }}
                                    >
                                        <CartIcon className="header-cart-icon" />
                                    </Badge>
                                </IconButton>}
                        </Box>
                    </Toolbar>
                </AppBar>
            </Box>

            {!showInner ? (
                <Box display={['none', 'none', 'block', 'block']} pt={[0, 0, 1, 1]}>
                    <Box >
                        <Toolbar style={{ paddingLeft: 0, backgroundColor: bgColor ? 'primary' : 'secondary' }}>
                            {backspace ? (
                                <IconButton onClick={() => backTo()}>
                                    <KeyboardBackspaceIcon
                                        //   className={classes.back_icon}
                                        color="text.primary"
                                    />
                                </IconButton>
                            )
                                : null}

                            <Typography
                                //   className={classes.text_flex}
                                // color="secondary"
                                variant="subtitle1"
                            >
                                <Box fontWeight="fontWeightMedium" color='text.primary'>{title}</Box>
                            </Typography>
                            {/* <IconButton>
                    <WhatsAppIcon className={classes.cart_icon} />
                    </IconButton> */}
                        </Toolbar>
                    </Box>
                </Box>
            ) : null
            }

        </Box >
    );
}

export default InnerAppBar;