import React from 'react';
import {
    Box,
    Grid,
    Typography,
    Button, Backdrop, Slide, Paper, RadioGroup, Radio, FormControlLabel

} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const AddressListSelection = ({ classes, isOpen, setIsOpen, userData, addressId, handleEditClick, handleAddress, handleAddAddressClick }) => {
    return (
        <Backdrop
            className={classes.backdrop}
            open={isOpen}
            onClick={() => setIsOpen(false)}
        >
            <Box>
                <Slide direction="up" in={isOpen} timeout={1000}>
                    <Paper
                        className="addressContainer"
                        elevation={0}
                    >
                        <Grid style={{ paddingBottom: 15 }}>
                            {userData?.user?.address?.map((data, index) => {
                                return (
                                    <Grid>
                                        {index === 0 && (
                                            <Grid
                                                className={classes.grid_flex_select_address}
                                            >
                                                <Grid item xs={12}>
                                                    <Typography
                                                        className="select-addr"
                                                        variant="subtitle1"
                                                    >
                                                        <Box fontWeight="fontWeightMedium">
                                                            Select Address
                                    </Box>
                                                    </Typography>
                                                </Grid>

                                                <Grid item>
                                                    <Typography onClick={() => setIsOpen(false)}>
                                                        <ClearIcon
                                                            style={{ color: 'rgb(170,170,170)' }}
                                                        />
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                        <RadioGroup
                                            value={addressId}
                                            onChange={(e) => handleAddress(e)}
                                        >
                                            <Grid
                                                container
                                                justify="space-between"
                                                alignItems="center"
                                            >
                                                <Grid item xs={10}>
                                                    <FormControlLabel
                                                        value={data._id}
                                                        control={
                                                            <Radio
                                                                classes={{
                                                                    root: classes.radio,
                                                                    checked: classes.checked
                                                                }}
                                                            />
                                                        }
                                                        label={data.addName}
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            width: '100%',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            display: 'inline-block'
                                                        }}
                                                        classes={{
                                                            label: classes.label
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={2} container justify="flex-end">
                                                    <Button
                                                        color="primary"
                                                        size="small"
                                                        variant="text"
                                                        onClick={() => handleEditClick(data)}
                                                    >
                                                        Edit
                                  </Button>
                                                </Grid>
                                            </Grid>

                                            <Typography className={classes.addressName}>
                                                {data.building} {data.street}, {data.city},{' '}
                                                {data.state}, {data.pincode}
                                            </Typography>

                                        </RadioGroup>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Grid style={{ paddingRight: 20 }}>
                            <Button
                                fullWidth
                                onClick={handleAddAddressClick}
                                variant="outlined"
                                size="large"
                                color="primary"
                                style={{
                                    border: '2px solid rgba(92, 77, 177, 0.5)',
                                    borderRadius: 5
                                }}
                            >
                                Add New Address
                      </Button>
                        </Grid>
                    </Paper>
                </Slide>
            </Box>
        </Backdrop>
    );
};

export default AddressListSelection;
