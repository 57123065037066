/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useState } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';

import storeOfflineCloseImage from '../../../assets/images/GroupClose.png';
import ScrollableTabsButtonAuto from './components/catecoryTabs';
import { useHomeHook } from './hooks';
import MainHeader from '../../Header/mainHeader';
import ErrorImg from '../../../assets/images/technical_issue.svg';
import Footer from './components/footer';
import { getStoreIdBasedOnDomain } from '../../../utils/domainStoreIdMapping';
import PageNoFound from "../../PageNotFound";
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
// import LocationOnIcon from '@material-ui/icons/LocationOn';

const Home = ({ storeData, userCartData }) => {
  const { getStoreData } = useHomeHook();
  const { storeId } = useParams();
  const [timer, setTimer] = useState('');
  const [isMoreThan24, setMoreThan24] = useState('');
  const [timerValue, setTimerValue] = useState('');
  let storeIdAlias = storeId;

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    setHeight(window.innerHeight);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [visible, setVisible] = useState(false)

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  useEffect(() => {
    if (storeId === undefined) {
      window.sessionStorage.setItem('custom_domain', true);

      storeIdAlias = getStoreIdBasedOnDomain();
    }
    else window.sessionStorage.setItem('custom_domain', false);

    if (storeIdAlias === undefined) {
      return <div></div>
    }

    window.sessionStorage.setItem('storeId', storeIdAlias);

    if (storeData.storeData) {
      if ((storeData.storeData?.id ?? '') !== storeIdAlias) {
        if (storeData.storeData.onlineStore?.link) {
          var uniqueStr = storeData.storeData.onlineStore?.link.split('s/');
          getStoreData(
            storeIdAlias,
            true
            // uniqueStr.length > 1 && uniqueStr[1] !== storeIdAlias
          );
        }
        else
          getStoreData(
            storeIdAlias,
            !storeData.storeData.onlineStore?.link?.includes(storeIdAlias) ?? true
          );
      } else {

        // Getting store data without loader screen to take advantage of the persisted store data
        // this store data loading without loader is just to get any price change or discount change on every visit
        getStoreData(storeIdAlias, false);
      }
    } else {
      // if store data is null then get data by showing loader
      getStoreData(storeIdAlias, true);
    }
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: '#333333',
      marginTop: 100
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: 'center',
      backgroundColor: '#333333',
      color: 'white',

    },
    button: {
      width: '35%',
      background: '#5C4DB1',
      color: 'white',
      textTransform: 'none',
      fontSize: 14,
      position: 'static'
    },
  }));
  // const classes = useStyles();

  useEffect(() => {
    if (
      storeData.storeData != null &&
      storeData.storeData.onlineStore?.available
    ) {
      let currentDay = moment().format('dddd').toLowerCase().substr(0, 3);
      let days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
      let currentDayIdx = days.findIndex((ele) => {
        return ele == currentDay;
      });

      let finalDayIdx = currentDayIdx + 1;
      let currentSrtEpoch = moment();
      let tdySrtEpoch = moment.unix(
        storeData.storeData.onlineStore[days[currentDayIdx]]?.startEpoch
      );
      let tdySrtEpochHr = tdySrtEpoch.format('HH');
      let currentSrtEpochHr = currentSrtEpoch.format('HH');
      if (Number(currentSrtEpochHr) < Number(tdySrtEpochHr)) {
        finalDayIdx = currentDayIdx;
      }

      for (let i = finalDayIdx; i < days.length; ++i) {
        if (!storeData.storeData.onlineStore[days[i]].isAvailable) {
          ++finalDayIdx;
        } else {
          break;
        }
      }

      let nextDayStartHrEpoch = moment.unix(
        storeData.storeData.onlineStore[days[finalDayIdx]]?.startEpoch
      );
      let nextDayStartHr = nextDayStartHrEpoch.format('HH');
      let nextDayStartMin = nextDayStartHrEpoch.format('mm');

      if (finalDayIdx - currentDayIdx > 1) {
        setMoreThan24(finalDayIdx - currentDayIdx);
      } else {
        const interval = setInterval(() => {
          let currentHr = currentSrtEpoch.format('HH');
          let currentMin = currentSrtEpoch.format('mm');
          setTimer(currentSrtEpoch + 1);

          let diffHrs = 24 - Number(currentHr) + Number(nextDayStartHr);
          let diffMin = Math.abs(Number(nextDayStartMin) - Number(currentMin));
          if (diffMin > 60) {
            diffHrs += diffMin % 60;
            diffMin -= (diffMin % 60) * 60;
          }
          setTimerValue(
            `${diffHrs}:${diffMin < 10 ? `0${diffMin}` : diffMin}:00`
          );
          if (timer == nextDayStartHrEpoch) {
            clearInterval(interval);
          }
        }, 1000);
        return () => {
          clearInterval(interval);
        };
      }
    }
  }, []);


  const classes = useStyles();

  var isMobile = true;
  if (width > 1024) isMobile = false;


  const domain = window.location.hostname;
  if (domain !== 'apnadukan.co' && window.location.href.includes('/s')) {
    return <PageNoFound />
  }

  return (
    <Box
      className="main-div-in"
    >
      <MainHeader />
      {storeData.loader ? (
        <div className="page-loader">
          <div className="loader small-loader "></div>
        </div>
      ) : storeData.error ? (
        <div className="home-loader">
          {storeData.errorMsg.responseStatus === 404 ? (
            <div style={{ fontSize: 20 }}>Store Not Found</div>
          ) : (
              <div style={{ fontSize: 20 }}>

                <img src={ErrorImg} alt={ErrorImg}
                />

                <Typography
                  style={{ marginTop: 20 }}
                  align="center"
                  variant="h5"
                >
                  Technical Problem

              </Typography>
              </div>
            )}
        </div>
      ) : (
            <Box>

              <Box>
                <ScrollableTabsButtonAuto userCartData={userCartData} />
                {!storeData.storeDayAvaibility && (
                  <Grid container className="store-offline-container">
                    <Grid className="store-offline-image">
                      <img
                        style={{ height: 25, width: 25 }}
                        src={storeOfflineCloseImage}
                        height={25}
                        width={25}
                        alt="Offline_image"
                      />
                    </Grid>
                    <Grid className="store-offline-text">
                      Temporarily store is not accepting new orders
                  <br />{' '}
                      {storeData.storeData.onlineStore.available &&
                        (isMoreThan24 || timerValue) &&
                        `The store re-opens in 
                            ${isMoreThan24 ? `${isMoreThan24} days` : timerValue}`}
                    </Grid>
                  </Grid>
                )}
              </Box>
              <Footer classes={classes} storeData={storeData} userCartData={userCartData}></Footer>
              {!isMobile && visible ?
                <Box className={"back-to-top-button"} onClick={scrollToTop}>
                  <ArrowUpwardSharpIcon style={{ fontSize: '30px', }} />
                </Box>
                : <></>}
            </Box>
          )}
    </Box>
  );
};

const mapStateToProps = ({ storeData, userCartData }) => ({
  storeData,
  userCartData
});

export default connect(mapStateToProps)(Home);
