import React, { useEffect, useState } from 'react';
import {
  Fade,
  Box,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import _ from 'lodash';

import SizeSelectItem from '../../../ItemDetail/components/sizeSelectItem';
import WeightSelectItem from '../../../ItemDetail/components/weightSelectItem';
import ColourSelectItem from '../../../ItemDetail/components/colorSelectItem';
import AddCartButton from '../../../ItemDetail/components/addCartButton';
import { useHomeHook } from '../hooks';
import '../styles.scss';

const VarientSelection = ({
  openModal,
  setOpenModal,
  userCartData,
  handleClose,
  item,
  classes
}) => {
  const [uniqueOptionAvailable, setUniqueOptionAvailable] = useState([]);
  const [weight, setWeight] = useState(null);
  const [color, setColor] = React.useState(null);
  const [size, setSize] = useState(null);
  const [selectedVarientFinal, setSelectedVarientFinal] = useState([]);
  const { addItemToCart, removeItemFromCart } = useHomeHook();

  useEffect(() => {
    if (item?.variants && item.variants.length)
      handleVarients({
        colorSelected: item.variants[0].colour,
        sizeSelected: item.variants[0].size,
        weightSelected: item.variants[0].weight
      });
  }, [item]);

  const handleVarients = async ({
    colorSelected,
    sizeSelected,
    weightSelected
  }) => {
    if (weightSelected) {
      setWeight(weightSelected);
    }
    if (colorSelected) {
      setColor(colorSelected);
    }
    if (sizeSelected) {
      setSize(sizeSelected);
    }
    var filterObj = {
      colour: colorSelected && colorSelected.length ? colorSelected : null,
      size: sizeSelected && sizeSelected.length ? sizeSelected : null,

      weight: weightSelected && weightSelected.length ? weightSelected : null
    };
    if (colorSelected && colorSelected.length) filterObj.size = null;
    var selectedVariants = _.filter(
      item.variants,
      _.pickBy(filterObj, _.identity)
    );
    if (selectedVariants.length) {
      if (colorSelected) setSize(selectedVariants[0].size);
      setSelectedVarientFinal([selectedVariants[0]]);
    }
    setUniqueOptionAvailable(selectedVariants);
  };

  const handleSelectedVarients = ({ sizeSelected }) => {
    if (_.find(uniqueOptionAvailable, { size: sizeSelected })) {
      var filterObj = {
        colour: color,
        size: sizeSelected
      };
      setSize(sizeSelected);
      var selectedVariants = _.filter(
        item.variants,
        _.pickBy(filterObj, _.identity)
      );
      setSelectedVarientFinal(selectedVariants);
    }
  };

  const addItem = () => {
    var filterObj = {
      colour: color,
      size: size,
      weight: weight
    };
    var selectedVariants = _.filter(
      item.variants,
      _.pickBy(filterObj, _.identity)
    );
    if (selectedVariants.length) {
      setSelectedVarientFinal(selectedVariants);
      var clone = _.clone(item, true);
      clone.selectedVariants = selectedVariants;
      addItemToCart(clone);
    }

    if (!item.variants || !item.variants.length) {
      addItemToCart(item);
    }
  };

  const removeItem = (item) => {
    var clone = _.clone(item, true);
    clone.selectedVariants = selectedVarientFinal;
    removeItemFromCart(clone);
  };

  const uniqueWeight = [
    ...new Set(item?.variants.map((item) => item.weight))
  ].filter((str) => str.length !== 0);
  const uniqueSize = [
    ...new Set(item?.variants.map((item) => item.size))
  ].filter((str) => str.length !== 0);
  const uniqueColour = [
    ...new Set(item?.variants.map((item) => item.colour))
  ].filter((str) => str.length !== 0);
  return (
    <Fade in={openModal}>
      <Box
        className='modalDiv'
        py={[0, 0, 3, 3]}
        px={[0, 0, 3, 3]}
        style={{ color: '#333333' }}
      >
        <Box
          pl={2}
          pb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {uniqueSize?.length !== 0 ? (
            <Typography
              id="transition-modal-title"
              style={{ fontSize: 18, fontWeight: '500' }}
            >
              Size &amp; Colour
            </Typography>
          ) : (
              <Typography
                id="transition-modal-title"
                style={{ fontSize: 18, fontWeight: '500' }}
              >
                Quantity
              </Typography>
            )}

          <Box>
            <IconButton
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <CloseIcon style={{ fontSize: 18 }}></CloseIcon>
            </IconButton>
          </Box>
        </Box>

        <Box pl={2} pb={2} maxHeight="250px" overflow="scroll">
          {uniqueColour?.length !== 0 && (
            <ColourSelectItem
              colorList={uniqueColour}
              selectedItem={color}
              handleVarients={handleVarients}
            ></ColourSelectItem>
          )}
          {uniqueSize?.length !== 0 && (
            <SizeSelectItem
              sizeList={uniqueSize}
              uniqueOptionAvailable={uniqueOptionAvailable}
              multipleVariantCheck={
                uniqueColour?.length !== 0 && uniqueSize?.length ? true : false
              }
              selectedItem={size}
              type={'sizeSelected'}
              handleVarients={
                uniqueColour?.length !== 0 && uniqueSize?.length
                  ? handleSelectedVarients
                  : handleVarients
              }
            ></SizeSelectItem>
          )}
          {/* {(uniqueWeight?.length !== 0) && (<SizeSelectItem
                        sizeList={uniqueWeight}
                        handleVarients={handleVarients}
                        uniqueOptionAvailable={[]}
                        selectedItem={weight}
                        type={'weightSelected'}

                    ></SizeSelectItem>)} */}
          {uniqueWeight?.length !== 0 && (
            <WeightSelectItem
              weightList={uniqueWeight}
              handleVarients={handleVarients}
              uniqueOptionAvailable={uniqueOptionAvailable}
              selectedItem={weight}
              type={'weightSelected'}
            ></WeightSelectItem>
          )}
        </Box>
        <Box pl={2}>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            alignItems="center"
            pt={3}
            borderTop={1}
            borderColor="#dedede"
          >
            <Box>
              <Box fontWeight="600">Rs. {selectedVarientFinal[0]?.sp}</Box>
              <Box
                fontWeight="300"
                color="text.info"
                style={{ fontSize: '12px', color: '#777777' }}
              >
                Item total
              </Box>
            </Box>

            {/* <Button variant="contained" color="primary" onClick={addItem} >
                                Add to cart
                        </Button> */}
            <Box flex="0 0 60%">
              <AddCartButton
                isModel={true}
                userCartData={userCartData}
                item={item}
                classes={classes}
                removeItem={removeItem}
                addItem={addItem}
                uniqueOptionAvailable={selectedVarientFinal ?? []}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default VarientSelection;
