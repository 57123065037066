import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { connect } from 'react-redux';
import { useLocation, Redirect, useParams } from 'react-router-dom';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './style.scss';
import 'react-image-gallery/styles/css/image-gallery.css';

import { useHomeHook } from '../Tabs/Home/hooks';
import { HOME_ROUTE } from '../../utils/routesConstants';
import MyImageGallery from './components/imageGallery';
import PageNotFound from '../PageNotFound';
import AddCartButton from './components/addCartButton'
import ImagePlaceholder from './components/imagePlaceholder'
import SizeSelectItem from './components/sizeSelectItem'
import ColourSelectItem from './components/colorSelectItem'
import MainHeader from '../Header/mainHeader';
import ShareOutlined from '@material-ui/icons/ShareOutlined';
import ShareIconButton from '../Header/shareIconButton'
import { useWishlistHook } from '../WishList/hooks';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Favorite from '@material-ui/icons/Favorite';

import _ from 'lodash';
import InnerAppBar from '../Header/innerAppBar';

const useStyles = makeStyles((theme) => ({
  // root: {
  //   flexGrow: 1
  // },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

function ItemDetails({ userCartData, storeItemDetail, storeData, wishlist }) {
  const {
    addItemToCart,
    removeItemFromCart,
    getStoreItemDetail,
    setStoreItemDetail
  } = useHomeHook();

  useEffect(() => {

  }, [wishlist]);
  // const { updateVariants } = useCartHook();
  const [width, setWidth] = useState(window.innerWidth);
  const [uniqueOptionAvailable, setUniqueOptionAvailable] = useState([]);
  const [weight, setWeight] = useState(null);
  const [color, setColor] = React.useState(null);
  const [size, setSize] = useState(null);
  const [localItem, setLocalItem] = useState(false);
  const [selectedVarientFinal, setSelectedVarientFinal] = useState([]);
  const classes = useStyles();
  const location = useLocation();
  const { updateWishlist } = useWishlistHook();
  // const [images , setImages ] = useState( 'photos');
  // const [current , setCurrent ] = useState();
  const [curentImage, setCurrentImage] = useState(null)


  let item;
  let imageListCombined = [];
  const { storeId, itemId } = useParams();
  item = storeItemDetail?.storeItemData;

  // const history = useHistory();

  useEffect(() => {
    if (location.state === undefined) {
      if (storeId && itemId) {
        getStoreItemDetail(storeId, itemId);
        setLocalItem(false);
      } else return <Redirect to={HOME_ROUTE} />;
    } else {
      let { item, storeName } = location.state;

      setStoreItemDetail(item, storeData.storeData);
      setLocalItem(true);
    }

    // if (item.variants && item.variants.length)
    //   handleVarients({ colorSelected: item.variants[0].colour, sizeSelected: item.variants[0].size, weightSelected: item.variants[0].weight })
  }, [storeData]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.scrollTo(0, 0)

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (item.variants && item.variants.length)
      handleVarients({ colorSelected: item.variants[0].colour, sizeSelected: item.variants[0].size, weightSelected: item.variants[0].weight })
  }, [storeItemDetail]);

  var isMobile = true;
  if (width > 840) isMobile = false;

  const addItem = () => {
    var filterObj = {
      colour: color,
      size: size,
      weight: weight,
    }

    var selectedVariants = _.filter(item.variants, _.pickBy(filterObj, _.identity));
    if (selectedVariants.length) {
      setSelectedVarientFinal(selectedVariants)
      var clone = _.clone(item, true);
      clone.selectedVariants = selectedVariants;
      addItemToCart(clone);
    }

    if (!item.variants || !item.variants.length) {
      addItemToCart(item);
    }
  };

  const removeItem = (item) => {
    var clone = _.clone(item, true);
    clone.selectedVariants = selectedVarientFinal;
    removeItemFromCart(clone);
  };

  const convertToRs = (mrp, price) => {
    return `${Math.round((mrp - price) * 100 / mrp)} %`;

  };
  const handleVarients = async ({ colorSelected, sizeSelected, weightSelected }) => {
    if (weightSelected) {
      setWeight(weightSelected)
    }
    if (colorSelected) {
      setColor(colorSelected);
    }
    if (sizeSelected) {
      setSize(sizeSelected)
    }
    var filterObj = {
      colour: colorSelected && colorSelected.length ? colorSelected : null,
      size: sizeSelected && sizeSelected.length ? sizeSelected : null,
      weight: weightSelected && weightSelected.length ? weightSelected : null,
    }
    if (colorSelected && colorSelected.length)
      filterObj.size = null;

    var selectedVariants = _.filter(item.variants, _.pickBy(filterObj, _.identity));
    if (selectedVariants.length) {
      if (colorSelected)
        setSize(selectedVariants[0].size);
      setSelectedVarientFinal([selectedVariants[0]]);

    }
    // console.log(selectedVariants, 'selectedVariants')
    setUniqueOptionAvailable(selectedVariants);
    // item.selectedVariants = selectedVariants;
  }
  const handleSelectedVarients = ({ sizeSelected }) => {
    if (_.find(uniqueOptionAvailable, { size: sizeSelected })) {
      var filterObj = {
        colour: color,
        size: sizeSelected,
      }
      setSize(sizeSelected)
      var selectedVariants = _.filter(item.variants, _.pickBy(filterObj, _.identity));
      setSelectedVarientFinal(selectedVariants);
    }
  }

  if (!item || storeItemDetail.loader) return <div className="page-loader"> <div className="loader "></div></div>;
  if (storeItemDetail.error) return <PageNotFound></PageNotFound>;
  let allImages = item.image
    ? [item.image, ...item.imagesList]
    : item.imagesList;
  imageListCombined = allImages;


  const uniqueWeight = [
    ...new Set(item.variants?.map((item) => item.weight))
  ].filter((str) => str?.length !== 0);
  const uniqueSize = [
    ...new Set(item.variants?.map((item) => item.size))
  ].filter((str) => str?.length !== 0);
  const uniqueColour = [
    ...new Set(item.variants?.map((item) => item.colour))
  ].filter((str) => str?.length !== 0);

  const sp =
    uniqueOptionAvailable?.length > 0
      ? uniqueOptionAvailable[0].sp
      : item.price;
  const mrp =
    uniqueOptionAvailable?.length > 0 ? uniqueOptionAvailable[0].mrp : item.mrp;
  imageListCombined =
    uniqueOptionAvailable?.length > 0 && uniqueOptionAvailable[0].images?.length
      ? uniqueOptionAvailable[0].images
      : imageListCombined;
  imageListCombined = imageListCombined.filter(
    (image) => image && image !== ''
  );

  const handleWishList = (item) => {
    var clonelist = _.clone(wishlist.wishlistData, true);

    if (_.find(wishlist.wishlistData, {
      _id: item._id
    })) {
      _.remove(clonelist, wishlist => wishlist._id === item._id);

    }
    else {
      clonelist.push({ ...item, storeId })
    }
    updateWishlist(clonelist)

  }

  if (!imageListCombined.length) imageListCombined = allImages;
  const getSliderSettings = () => {
    return {
      dots: true,
      autoplay: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1
    };
  }
  const settings = getSliderSettings();

  const handleClickImage = (e, image) => {
    e && e.preventDefault();
    setCurrentImage(image)
    // setCurrent({
    //   current: image
    // });
  };

  const handleCloseModal = e => {
    e && e.preventDefault();

    // setCurrent({
    //   current: ""
    // });
    setCurrentImage(null)
  };

  return (
    <div className="main-div">
      <MainHeader />
      <Box >
        {/* <AppBarContainer
          itemId={item._id}
          itemName={item.itemType}
          storeId={storeItemDetail.itemSpecificStoreData._id}
          storeName={storeItemDetail.itemSpecificStoreData.storeName}
          storeContact={storeItemDetail.itemSpecificStoreData.mobileNumber}
          userCartData={userCartData}
          position="static"
          localItemFlag={localItem}
        /> */}


        <Container
          // className={classes.containerPadding}
          className="items-container"
        >
          <InnerAppBar title={storeItemDetail.itemSpecificStoreData.storeName}
            backspace={true} bgColor={false} userCartData={userCartData} />
          <Box className="main-contain item-details-content">
            <Grid container spacing={0} justify="center" >
              <Grid item xs={12} sm={6}>

                {!isMobile ? (
                  <Grid container item
                    style={{ position: 'relative' }}
                  >
                    <Grid item xs={12} justify="center">
                      {imageListCombined.length ? (

                        <MyImageGallery imageItems={imageListCombined} />
                      ) : (
                          <ImagePlaceholder />
                        )}
                    </Grid>
                    <div
                      id="imageMagnifier"
                      style={{ position: 'absolute' }}
                    />
                  </Grid>
                ) : (
                    <Slider {...settings}>
                      {imageListCombined.length ? (
                        imageListCombined.map((image, id) => {
                          return (
                            <div>
                              <img
                                alt=""
                                src={image}
                                key={id}
                                // className={classes.img}
                                style={{
                                  overflow: 'hidden',
                                  maxHeight: '70vh',
                                  width: '100%',
                                  height: '70vh',
                                  objectFit: 'contain'
                                }}
                                onClick={e => handleClickImage(e, image)}

                              />
                            </div>
                          );
                        })
                      ) : (
                          <ImagePlaceholder />
                        )}
                      {curentImage && (
                        <Lightbox mainSrc={curentImage} onCloseRequest={handleCloseModal} />
                      )}
                    </Slider>
                    // <Carousel
                    //   autoPlay
                    //   showThumbs={false}
                    //   style={{ width: '100%', height: 100 }}
                    // >
                    //   {imageListCombined.length ? (
                    //     imageListCombined.map((image, id) => {
                    //       return (
                    //         <div>
                    //           <img
                    //             alt=""
                    //             src={image}
                    //             key={id}
                    //             // className={classes.img}
                    //             style={{
                    //               overflow: 'hidden',
                    //               maxHeight: '70vh',
                    //               width: '100%',
                    //               height: '70vh',
                    //               objectFit: 'contain'
                    //             }}
                    //           />
                    //         </div>
                    //       );
                    //     })
                    //   ) : (
                    //       <ImagePlaceholder />
                    //     )}
                    // </Carousel>
                  )}
              </Grid>

              <Grid container xs={isMobile ? 12 : 6} style={{ padding: 30, height: 'fit-content' }} >
                <div style={{ width: '100%', paddingBottom: '20px' }}>
                  <Grid container item direction="column">
                    <Box className="inner-share">
                      {/* <IconButton
                        color="primary"
                        className="share-icon"
                        rel="noopener noreferrer"
                        href={`https://api.whatsapp.com/send?text=${shareText}`}
                      >
                        <ShareOutlined className="header-cart-icon"></ShareOutlined>
                      </IconButton> */}
                      <ShareIconButton classVar="share-icon" color="primary" storeData={storeData} itemDetailsURL={window.location.href} />
                    </Box>
                    <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                      <Box style={{
                        color: '#333333',
                        fontWeight: '600',
                        fontSize: 18,
                        float: "left"
                      }}>
                        {item.itemType}
                      </Box>
                      <Box style={{
                        float: "right", fontWeight: '600',
                      }} onClick={() => {
                        handleWishList(item);
                      }}>
                        {_.find(wishlist.wishlistData, {
                          _id: item._id
                        }) ? <Favorite style={{ color: '#5c4db1', fontSize: 15 }} /> : <FavoriteBorderIcon style={{ color: '#5c4db1', fontSize: 15 }} />}
                      </Box>
                    </Box>
                    <Typography variant="subtitle1"
                      // className={classes.productType}
                      style={{
                        fontSize: 14,
                        color: '#777777',
                        marginTop: 5
                      }}
                      color="textSecondary">
                      {item.category}
                    </Typography>

                    {/*}<Typography style={{ marginTop: 5, marginBottom: 10 }}>
                      <span className="tag">
                        Bestseller
                      </span>
                    </Typography>*/}

                    <Box className="divide"></Box>

                    {item.tagsList && item.tagsList.length ? (
                      <Typography className="tag" style={{ marginTop: 5, marginBottom: 10 }}>
                        {item.tagsList[0]}
                      </Typography>
                    ) : null}
                    {mrp !== 0 && mrp ? (
                      <Grid item container direction="row">
                        <Typography
                          // className={classes.productPrice}

                          variant="body1"
                        >
                          M.R.P.:
                        </Typography>
                        <Typography
                          // className={classes.strike_Price}
                          style={{
                            textDecoration: 'line-through',
                            fontSize: 14,
                            marginLeft: 10
                          }}
                          variant="body1"
                          color="textSecondary"
                        >
                          {mrp}
                        </Typography>
                      </Grid>
                    ) : null}
                    <Grid item container direction="row" >

                      <Typography
                        // className={classes.productPrice}
                        style={{
                          color: '#777777',
                          paddingBottom: 5,
                          fontSize: 14,
                        }}
                        variant="body1"
                      >
                        Price:
                      </Typography>
                      <Typography
                        // className={classes.prodPrice}
                        style={{
                          color: '#333333',
                          fontSize: 14,
                          fontWeight: '600',
                          marginLeft: 10,
                          marginRight: 10
                        }}
                        variant="body1"
                      >
                        {sp}
                      </Typography>
                    </Grid>

                    {mrp > sp ? (
                      <Typography
                        // className={classes.discount_text}
                        style={{
                          color: '#5C4DB1',
                          fontWeight: 'bold',
                          fontSize: 13,
                        }}
                      >
                        {convertToRs(mrp, sp)} OFF
                      </Typography>
                    ) : null}
                  </Grid>

                  <Box className="divide"></Box>

                  <Grid>
                    {item.productDescription && (
                      <Typography
                        variant="subtitle1"
                        style={{ paddingTop: 10, paddingBottom: 10, fontWeight: '600', color: '#333333' }}
                      >
                        Description
                      </Typography>
                    )}
                    {item.productDescription && (
                      <Box display="flex" flexDirection="column" >
                        {item.productDescription.split("\n").map(i => <Typography variant="body2" className="desc">{i}</Typography>)}
                      </Box>
                    )}

                    {(uniqueColour?.length !== 0) && (<ColourSelectItem colorList={uniqueColour}
                      selectedItem={color}
                      handleVarients={handleVarients}></ColourSelectItem>)}
                    {(uniqueSize?.length !== 0) && (<SizeSelectItem sizeList={uniqueSize}
                      uniqueOptionAvailable={uniqueOptionAvailable}
                      multipleVariantCheck={(uniqueColour?.length !== 0 && uniqueSize?.length ? true : false)}
                      selectedItem={size}
                      type={'sizeSelected'}
                      handleVarients=
                      {(uniqueColour?.length !== 0 && uniqueSize?.length) ?
                        handleSelectedVarients : handleVarients}>

                    </SizeSelectItem>)}
                    {(uniqueWeight?.length !== 0) && (<SizeSelectItem sizeList={uniqueWeight}
                      handleVarients={handleVarients}
                      uniqueOptionAvailable={[]}
                      type={'weightSelected'}
                      selectedItem={weight}
                    ></SizeSelectItem>)}
                  </Grid>
                </div>
                <AddCartButton
                  userCartData={userCartData}
                  item={item}
                  classes={classes}
                  removeItem={removeItem}
                  addItem={addItem}
                  uniqueOptionAvailable={selectedVarientFinal}
                ></AddCartButton>
              </Grid>


            </Grid>
          </Box>
        </Container>

      </Box>
    </div >
  );
}

const mapStateToProps = ({ userCartData, storeItemDetail, storeData, wishlist }) => ({
  userCartData,
  storeItemDetail,
  storeData,
  wishlist
});

export default connect(mapStateToProps)(ItemDetails);
