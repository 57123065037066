import React from 'react';
import { AppBar, Typography } from '@material-ui/core';

const PageNotFound = () => {
  return (
    <>
      <AppBar
        style={{
          color: '#5C4DB1',
          padding: 5
        }}
        position="static"
      >
        <Typography
          style={{
            color: 'white',
            alignSelf: 'center'
          }}
          variant="h6"
        >
          Marketplace
        </Typography>
      </AppBar>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          direction: 'column',
          flexGrow: 1,
          height: `100vh`
        }}
      >
        <div style={errorGifContainer}>
          <img
            alt="page not found gif"
            src="https://firebasestorage.googleapis.com/v0/b/vardaan-2020.appspot.com/o/download.svg?alt=media&token=4de956b9-7d0f-44d9-9cb4-4450173428a1"
          />
        </div>
      </div>
    </>
  );
};

export default PageNotFound;

const errorGifContainer = {
  backgroundColor: ' #fff',
  boxShadow: '0 10px 40px - 10px rgba(2, 61, 121, 0.2)',
  padding: '5 %',
  borderRadius: '5px',
  border: '0.5px solid #c8d2d858',
  width: '70vw'
};
