import { all } from 'redux-saga/effects';

// import userAuthFirebase from './userAuthFirebase';
import store from './fetchStore';
import userCartData from './userCartData';
import userAuthData from './userAuth';
import userOrderData from './userOrder';
import wishlist from './wishlist';


// export const firebaseLogin = [...userAuthFirebase];
export const storeData = [...store];
export const cartData = [...userCartData];
export const userData = [...userAuthData];
export const userOrder = [...userOrderData];
export const wishlistData = [...wishlist];


const rootSaga = function* rootSaga() {
  // yield all(firebaseLogin);
  yield all(storeData);
  yield all(cartData);
  yield all(userData);
  yield all(userOrder);
  yield all(wishlistData);

};

export default rootSaga;
