import actionTypes from '../actionTypes';

const initialState = () => {
  return {
    cartData: [],
    loader: false,
    error: false,
    errMsg: '',
    onAddItemError: false,
    addItemErrMsg: '',
    onAddItemLoader: true,
    notes: '',
    coupons: [],
    couponsLoading: false,
    couponsError: false,
    couponsErrMsg: ''
  };
};

export default function (state = initialState(), action = {}) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.ADD_ITEM_TO_CART_SUCCESS: {
      return {
        ...state,
        cartData: payload,
        onAddItemLoader: false,
        onAddItemError: false,
        error: false
      };
    }
    case actionTypes.ADD_ITEM_TO_CART_REQUEST: {
      return {
        ...state,
        onAddItemLoader: true
      };
    }
    case actionTypes.ADD_ITEM_TO_CART_FAILURE: {
      return {
        ...state,
        onAddItemError: true,
        addItemErrMsg: payload,
        onAddItemLoader: false
      };
    }

    case actionTypes.UPDATE_USER_CART_REQUEST: {
      return {
        ...state,
        loader: true
      };
    }
    case actionTypes.UPDATE_USER_CART_SUCCESS: {
      return {
        ...state,
        cartData: payload,
        loader: false,
        onAddItemLoader: false,
        onAddItemError: false,
        error: false
      };
    }
    case actionTypes.UPDATE_USER_CART_FAILURE: {
      return {
        ...state,
        errMsg: payload,
        error: true,
        loader: false,
        onAddItemLoader: false
      };
    }
    case actionTypes.UPDATE_USER_NOTE: {
      return {
        ...state,
        notes: payload
      };
    }

    case actionTypes.CART_CLEAR: {
      return {
        ...state,
        cartData: [],
        loader: false,
        onAddItemLoader: false,
        notes:''
      };
    }

    case actionTypes.GET_ALL_COUPONS_REQUEST: {
      return {
        ...state,
        couponsLoading: true,
        couponsErrMsg: '',
        couponsError: false
      };
    }
    case actionTypes.GET_ALL_COUPONS_SUCCESS: {
      return {
        ...state,
        coupons: payload,
        couponsErrMsg: '',
        couponsError: false,
        couponsLoading: false
      };
    }
    case actionTypes.GET_ALL_COUPONS_FAILURE: {
      return {
        ...state,
        couponsErrMsg: payload,
        couponsError: true,
        couponsLoading: false
      };
    }

    default:
      return state;
  }
}
