import firebase from '../config/firebase';
import { setLocalStorageTokens } from '../utils/tokensHelper';
import { toastr } from 'react-redux-toastr';
import * as moment from 'moment/moment';

const setUpRecaptcha = () => {
  window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    'recaptcha-container',
    {
      size: 'invisible',
      callback: function (response) {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // console.log('captcha Resolved');
        // onSignInSubmit();
      }
    }
  );
};

// const add7SecondsDelay = () => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve('7 seconds');
//     }, 7000);
//   });
// };

export const onSignInSubmit = async (phone) => {
  try {
    phone = '+91' + phone;
    setUpRecaptcha();
    // console.log('PhOne Number is ', phone);
    var appVerifier = window.recaptchaVerifier;
    var confirmationResult = await firebase
      .auth()
      .signInWithPhoneNumber(phone, appVerifier);
    // SMS sent
    window.confirmationResult = confirmationResult;
    return confirmationResult;
  } catch (error) {
    toastr.error(error.message || 'Something went wrong!');
    window.location.reload();
  }
};

export const submitVerificationCode = async (codeResult, code) => {
  try {
    var result = await codeResult.confirm(code);
    var user = result.user;
    console.log(`Logged in user is =>>> ${user}`);
    if (
      moment(user.metadata.creationTime) ===
      moment(user.metadata.lastSignInTime)
    )
      // await add7SecondsDelay();

      var user = firebase.auth().currentUser;
    await user.reload();

    user = firebase.auth().currentUser;
    var authToken = await user.getIdToken(true);

    var data = {
      phoneNumber: user.phoneNumber,
      token: authToken,
      refreshToken: user.refreshToken,
      uId: user.uid
    };

    return data;
  } catch (error) {
    console.error(error);
    toastr.error('Invalid Verification code');
  }
};

export const getFirebaseToken = async () => {
  return new Promise(async function (resolve, reject) {
    try {
      var val = firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          user.getIdToken(true).then(function (idToken) {
            resolve(idToken);
          });
        } else {
          resolve('');
          setLocalStorageTokens({ isLoggedIn: false });
          window.localStorage.setItem('isLoggedIn', false);
        }
      });
    } catch (err) {
      reject('');
      setLocalStorageTokens({ isLoggedIn: false });
      window.localStorage.setItem('isLoggedIn', false);
    }
  });
};
