import actionTypes from '../actionTypes';

const initialState = () => {
    return {
        wishlistData: [],

    };
};

export default function (state = initialState(), action = {}) {
    const { type, payload } = action;

    switch (type) {
        case actionTypes.GET_ALL_WISHLIST: {
            return {
                ...state,

            };
        }

        case actionTypes.UPDATE_WISHLIST: {
            return {
                ...state,
                wishlistData: payload
            };
        }
        default:
            return state;
    }
}
