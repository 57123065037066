import actionTypes from '../actionTypes';

const initialState = () => {
    return {
        itemSpecificStoreData: {},
        storeItemData: {},
        loader: true,
        error: false,
        errorMsg: {},

    };
};

export default function (state = initialState(), action = {}) {
    const { type, payload } = action;

    switch (type) {
        case actionTypes.ITEM_DETAIL_SUCCESS: {
            return {
                ...state,
                itemSpecificStoreData: payload.store,
                storeItemData: payload.item,
                loader: false,
                error: false,

            };
        }

        case actionTypes.ITEM_DETAIL_REQUEST: {
            return {
                ...state,
                loader: true,
                error: false,
            };
        }

        case actionTypes.ITEM_DETAIL_FAILURE: {
            return {
                ...state,
                error: true,
                loader: false,
                errorMsg: payload
            };
        }

        default:
            return state;
    }
}
