import { takeLatest, put, select, call } from 'redux-saga/effects';
import actionTypes from '../actionTypes';
import {
  addItemToCartSuccess,
  addItemToCartFailure,
  addItemToCartRequestLoader,
  updateUserCartSuccess,
  updateUserCartLoading,
  updateUserCartFailure,
  updateUserNotes,
  updateVariantsLoader,
  updateVariantsFailure,
  clearCart,
  getCouponsFailure,
  getCouponsSuccess,
  getCouponsLoader,
  paymentSuccess,
  paymentLoader,
  paymentFailure,
  paymentStatusLoader,
  paymentStatusSuccess,
  paymentStatusFailure,
  clearPaymentData
} from '../actions/userActions';
import { getLocalStorageTokens } from '../../utils/tokensHelper';
import * as ApiService from '../../services/apiService';
import { toastr } from 'react-redux-toastr';
import { navigateToWithState } from '../../utils/history';
import { ORDER_CONFIRM } from '../../utils/routesConstants';
import { getDocId } from '../../utils/helper';
function* addItemToCart(action) {
  try {
    yield put(addItemToCartRequestLoader());
    const state = yield select();
    let prevUserCartData = state.userCartData.cartData;
    let selectedObjIndex = prevUserCartData.findIndex(
      (x) => x._id === action.payload[0]._id
    );
    const { isLoggedIn } = getLocalStorageTokens();

    if (!isLoggedIn) {
      if (selectedObjIndex === -1) {
        //Item Id not found
        let cartItems = action.payload;
        cartItems.map((item) => {
          item['quantity'] = 1;
          if (item?.selectedVariants && item?.selectedVariants?.length) {
            let _price = item.selectedVariants[0].sp;
            item['price'] = _price;
            item['priceWithGst'] = _price + (item['gst'] * _price) / 100;
          }
        });
        prevUserCartData.push(cartItems[0]);
        yield put(addItemToCartSuccess(prevUserCartData));
      } else {
        let _flg = false;
        for (var i = 0; i < prevUserCartData.length; i++) {
          if (prevUserCartData[i]._id === action.payload[0]._id) {
            if (
              (prevUserCartData[i]?.selectedVariants?.length &&
                action.payload[0]?.selectedVariants?.length &&
                prevUserCartData[i]?.selectedVariants[0]?._id ===
                action.payload[0]?.selectedVariants[0]?._id) ||
              (!prevUserCartData[i]?.selectedVariants?.length &&
                !action.payload[0]?.selectedVariants?.length)
            ) {
              // selected variant Id is matched
              // or
              // varinats is not present
              _flg = true;
              prevUserCartData[i].quantity = prevUserCartData[i].quantity + 1;
              break;
            }
          }
        }
        if (!_flg) {
          let cartItems = action.payload;
          cartItems.map((item) => {
            item['quantity'] = 1;
            if (item?.selectedVariants && item?.selectedVariants?.length) {
              let _price = item.selectedVariants[0].sp;
              item['price'] = _price;
              item['priceWithGst'] = _price + (item['gst'] * _price) / 100;
            }
          });
          prevUserCartData.push(cartItems[0]);
        }
        yield put(addItemToCartSuccess(prevUserCartData));
      }
    } else {
      if (selectedObjIndex === -1) {
        let cartItems = action.payload;
        cartItems.map((item) => {
          item['quantity'] = 1;
          if (item?.selectedVariants && item?.selectedVariants?.length) {
            let _price = item.selectedVariants[0].sp;
            item['price'] = _price;
            item['priceWithGst'] = _price + (item['gst'] * _price) / 100;
          }
        });
        const payload = {
          sync: false,
          items: cartItems
        };
        const cartStatus = yield call(add_sync_cart, payload);
        if (cartStatus.status === false) {
          yield put(
            addItemToCartFailure(cartStatus.message || 'SomeThing Went Wrong')
          );
        } else {
          const cartDataResult = cartStatus.cart.items;
          yield put(addItemToCartSuccess(cartDataResult));
        }
      } else {
        let _flg = false;
        for (var i = 0; i < prevUserCartData.length; i++) {
          if (prevUserCartData[i]._id === action.payload[0]._id) {
            if (
              (prevUserCartData[i]?.selectedVariants?.length &&
                action.payload[0]?.selectedVariants?.length &&
                prevUserCartData[i]?.selectedVariants[0]?._id ===
                action.payload[0]?.selectedVariants[0]?._id) ||
              (!prevUserCartData[i]?.selectedVariants?.length &&
                !action.payload[0]?.selectedVariants?.length)
            ) {
              // selected variant Id is matched
              // or
              // varinats is not present
              _flg = true;
              const payload = {
                updateAction: 'increase',
                item: action.payload[0]
              };
              const cartStatus = yield call(update_cartitem_quantity, payload);
              if (cartStatus.status === false) {
                yield put(
                  addItemToCartFailure(
                    cartStatus.message || 'SomeThing Went Wrong'
                  )
                );
              } else {
                let cartDataResult = cartStatus.data.items;
                yield put(addItemToCartSuccess(cartDataResult));
              }
              break;
            }
          }
        }
        if (!_flg) {
          let cartItems = action.payload;
          cartItems.map((item) => {
            item['quantity'] = 1;
            if (item?.selectedVariants && item?.selectedVariants?.length) {
              let _price = item.selectedVariants[0].sp;
              item['price'] = _price;
              item['priceWithGst'] = _price + (item['gst'] * _price) / 100;
            }
          });
          const payload = {
            sync: false,
            items: cartItems
          };
          const cartStatus = yield call(add_sync_cart, payload);
          if (cartStatus.status === false) {
            yield put(
              addItemToCartFailure(cartStatus.message || 'SomeThing Went Wrong')
            );
          } else {
            const cartDataResult = cartStatus.cart.items;
            yield put(addItemToCartSuccess(cartDataResult));
          }
        }
      }
    }
  } catch (error) {
    console.log(error, 'error.message');
    yield put(addItemToCartFailure(error.message || 'SomeThing Went Wrong'));
    toastr.error(error.message || 'Something went wrong!');
  }
}

function* removeItemFromCart(action) {
  try {
    yield put(addItemToCartRequestLoader());
    const state = yield select();
    let prevUserCartData = state.userCartData.cartData;
    const { isLoggedIn } = getLocalStorageTokens();
    let _flag = false;

    for (var i = 0; i < prevUserCartData.length; i++) {
      if (prevUserCartData[i]._id === action.payload[0]._id) {
        if (
          (prevUserCartData[i]?.selectedVariants?.length &&
            action.payload[0]?.selectedVariants?.length &&
            prevUserCartData[i]?.selectedVariants[0]?._id ===
            action.payload[0]?.selectedVariants[0]?._id) ||
          (!prevUserCartData[i]?.selectedVariants?.length &&
            !action.payload[0]?.selectedVariants?.length)
        ) {
          // selected variant Id is matched
          // or
          // varinats is not present
          if (!isLoggedIn) {
            if (
              prevUserCartData[i]?.quantity &&
              prevUserCartData[i].quantity > 1
            )
              prevUserCartData[i].quantity = prevUserCartData[i].quantity - 1;
            else prevUserCartData.splice(i, 1);
            yield put(addItemToCartSuccess(prevUserCartData));
          } else _flag = true;
          break;
        }
      }
    }

    if (_flag) {
      const payload = {
        updateAction: 'decrease',
        item: action.payload[0]
      };
      const cartStatus = yield call(update_cartitem_quantity, payload);
      if (cartStatus.status === false) {
        yield put(
          addItemToCartFailure(cartStatus.message || 'SomeThing Went Wrong')
        );
      } else {
        let cartDataResult = cartStatus.data.items;
        yield put(addItemToCartSuccess(cartDataResult));
      }
    } else {
      // In case if the item Id itself is not matched
      yield put(addItemToCartSuccess(prevUserCartData));
    }
  } catch (error) {
    console.log(error, 'error.message');
    yield put(addItemToCartFailure(error.message || 'SomeThing Went Wrong'));
    toastr.error(error.message || 'Something went wrong!');
  }
}

function* getUserCartDataFromApi(action) {
  try {
    const { isLoggedIn } = getLocalStorageTokens();
    let cartSync = window.localStorage.getItem('syncCart');
    cartSync = JSON.parse(cartSync);

    const state = yield select();
    let prevUserCartData = state.userCartData.cartData;

    if (!isLoggedIn) {
      yield put(updateUserCartSuccess(prevUserCartData));
    } else {
      yield put(updateUserCartLoading());

      if (cartSync) {
        const payload = {
          sync: cartSync,
          items: prevUserCartData
        };
        const cartStatus = yield call(add_sync_cart, payload);

        if (cartStatus.status === false) {
          yield put(
            addItemToCartFailure(cartStatus.message || 'SomeThing Went Wrong')
          );
        } else {
          let cartDataResult = cartStatus.cart.items;
          // window.localStorage.setItem('cartId', cartStatus.cart._id);
          window.localStorage.removeItem('syncCart');
          yield put(addItemToCartSuccess(cartDataResult));
        }
      }
      const cartStatus = yield call(get_cart_data);
      if (cartStatus.status === false) {
        yield put(
          updateUserCartFailure(cartStatus.message || 'SomeThing Went Wrong')
        );
      } else {
        let cartDataResult = cartStatus.data[0];
        if (cartDataResult) {
          yield put(updateUserCartSuccess(cartDataResult.items));
        } else {
          yield put(updateUserCartSuccess([]));
        }
      }
    }
  } catch (error) {
    yield put(updateUserCartFailure(error.message || 'SomeThing Went Wrong'));
    toastr.error(error.message || 'Something went wrong!');
  }
}

function* addUserNote(action) {
  try {
    yield put(updateUserNotes(action.payload));
  } catch (error) {
    toastr.error(error.message || 'Something went wrong!');
  }
}

function* updateVariants(action) {
  const { isLoggedIn } = getLocalStorageTokens();

  try {
    if (isLoggedIn) {
      yield put(updateVariantsLoader());
      const cartStatus = yield call(update_cartitem_variants, action.payload);
      if (cartStatus.status === false) {
        yield put(
          updateVariantsFailure(cartStatus.message || 'SomeThing Went Wrong')
        );
      } else {
        let cartDataResult = cartStatus.data.items;
        yield put(addItemToCartSuccess(cartDataResult));
      }
    }
  } catch (error) {
    yield put(updateVariantsFailure(error.message || 'SomeThing Went Wrong'));
    toastr.error(error.message || 'Something went wrong!');
  }
}

function* getAllActiveCoupons(action) {
  try {
    yield put(getCouponsLoader());
    const storeId = window.sessionStorage.getItem('storeLinkingId');
    const APIObj = {
      endPoint: `secure/active/${storeId}`,
      authenticationRequired: true,
      method: 'GET'
    };
    var couponResult = yield ApiService.callApi(APIObj);
    const data = couponResult.response.data;
    // console.log(data);
    yield put(getCouponsSuccess(data));
  } catch (error) {
    yield put(getCouponsFailure(error));
    toastr.error(error.message || 'Something went wrong!');
  }
}

function* cartCheckout(action) {
  try {
    yield put(paymentLoader({}));
    const orderStatus = yield call(checkout, action.payload);
    window.localStorage.setItem('orderId', orderStatus.orders._id);
    window.localStorage.removeItem('deliveryOption');
    yield put(clearCart());
    yield put(clearPaymentData());

    navigateToWithState(ORDER_CONFIRM, orderStatus.orders);
  } catch (error) {
    toastr.error(
      error.message ||
      'Something went wrong, Please report to Vardaan customer service'
    );
  }
}

function* initiatePayment(action) {
  try {
    yield put(paymentLoader(action.payload[0]));
    const state = yield select();
    const storeDataNum = state.storeData.storeData?.allNumbers[0] ?? '';
    Object.assign(action.payload[0], {
      onlinePayment: {},
      storeDataNum: storeDataNum
    });

    const paymentStatus = yield call(payment_initiation, action.payload[0]);
    if (paymentStatus.status) {
      yield put(
        paymentSuccess({
          paymentLink: paymentStatus.data,
          amount: action.payload[0].totalAmountTobePaid
        })
      );
      window.location = paymentStatus.data.paymentLink;
      // {
      //   "status":true,
      //   "message":"Success",
      //   "data":{"status":"OK","paymentLink":"https://payments-test.cashfree.com/order/#62iigYlYhj1Yx5W7enaU"}
      //   }
    } else {
      yield put(paymentFailure());
    }
  } catch (error) {
    console.log(error, 'payment_initiation');
    yield put(paymentFailure(error));

    toastr.error(
      error.message ||
      'Something went wrong, Please report to Vardaan customer service'
    );
  }
}
function* checkPaymentStatus(action) {
  try {
    yield put(paymentStatusLoader());

    if (action.payload) {
      window.localStorage.setItem('orderId', action.payload.firebaseId);
      window.localStorage.removeItem('deliveryOption');
      yield put(clearCart());
      yield put(paymentStatusSuccess(action.payload));
      yield put(clearPaymentData());
    } else {
      const state = yield select();

      const paymentStatus = yield call(check_payment_status, state.payment);
      if (paymentStatus.status) {
        const checkoutData = state.payment.tempCheckoutdata;
        Object.assign(checkoutData, { onlinePayment: paymentStatus.data });
        const orderStatus = yield call(checkout, checkoutData);

        window.localStorage.setItem('orderId', orderStatus.orders.firebaseId);
        window.localStorage.removeItem('deliveryOption');
        yield put(clearCart());
        yield put(paymentStatusSuccess(orderStatus.orders));
        yield put(clearPaymentData());
      } else {
        yield put(paymentStatusFailure());
      }
    }
  } catch (error) {
    console.log(error, 'check_payment_status');
    yield put(paymentStatusFailure(error));
    // toastr.error(
    //   error.message ||
    //     'Something went wrong, Please report to Vardaan customer service'
    // );
  }
}

export default [
  takeLatest(actionTypes.ADD_ITEM_TO_CART, addItemToCart),
  takeLatest(actionTypes.REMOVE_ITEM_FROM_CART, removeItemFromCart),
  takeLatest(actionTypes.GET_USER_CART_DATA, getUserCartDataFromApi),
  takeLatest(actionTypes.CART_CHECKOUT, cartCheckout),
  takeLatest(actionTypes.ADD_USER_NOTE, addUserNote),
  takeLatest(actionTypes.UPDATE_VARIANTS, updateVariants),
  takeLatest(actionTypes.GET_ALL_COUPONS, getAllActiveCoupons),
  takeLatest(actionTypes.INITIATE_PAYMENT, initiatePayment),
  takeLatest(actionTypes.CHECK_PAYMENT_STATUS, checkPaymentStatus)
];

async function get_cart_data() {
  const { user } = getLocalStorageTokens();
  let cartId = window.sessionStorage.getItem('storeId') + user.uuid;

  return new Promise(async function (resolve, reject) {
    const APIObj = {
      endPoint: `secure/carts/${cartId}`,
      authenticationRequired: true,
      method: 'GET'
    };
    ApiService.callApi(APIObj)
      .then((res) => {
        if (res.status) {
          resolve(res);
        } else reject(res);
      })
      .catch((error) => reject(error));
  });
}

async function add_sync_cart(payload) {
  const { user } = getLocalStorageTokens();

  return new Promise(function (resolve, reject) {
    const cartObj = {
      storeId: window.sessionStorage.getItem('storeId'),
      uuid: user.uuid,
      sync: payload.sync,
      items: payload.items
    };
    const APIObj = {
      endPoint: 'secure/cartsV2',
      authenticationRequired: true,
      method: 'POST',
      body: JSON.stringify(cartObj)
    };

    ApiService.callApi(APIObj)
      .then((res) => {
        if (res.status) {
          resolve(res);
        } else reject(res);
      })
      .catch((error) => reject(error));
  });
}

async function update_cartitem_quantity(payload) {
  const { user } = getLocalStorageTokens();

  return new Promise(function (resolve, reject) {
    const cartObj = {
      cartId: window.sessionStorage.getItem('storeId') + user.uuid,
      action: payload.updateAction,
      items: payload.item
    };
    const APIObj = {
      endPoint: 'secure/cartsV2',
      authenticationRequired: true,
      method: 'PUT',
      body: JSON.stringify(cartObj)
    };

    ApiService.callApi(APIObj)
      .then((res) => {
        if (res.status) {
          resolve(res);
        } else reject(res);
      })
      .catch((error) => reject(error));
  });
}

async function update_cartitem_variants(payload) {
  const { user } = getLocalStorageTokens();
  return new Promise(function (resolve, reject) {
    const cartObj = {
      cartId: window.sessionStorage.getItem('storeId') + user.uuid,
      itemId: payload.item._id,
      selectedVariants: payload.item.selectedVariants
    };
    const APIObj = {
      endPoint: 'secure/carts/variants',
      authenticationRequired: true,
      method: 'PUT',
      body: JSON.stringify(cartObj)
    };

    ApiService.callApi(APIObj)
      .then((res) => {
        if (res.status) {
          resolve(res);
        } else reject(res);
      })
      .catch((error) => reject(error));
  });
}

async function checkout(checkout_data) {
  const { user } = getLocalStorageTokens();
  let checkout_address = user.address.findIndex(
    (x) => x._id === checkout_data.addrId
  );
  let storeId = window.sessionStorage.getItem('storeLinkingId');
  let cartId = window.sessionStorage.getItem('storeId') + user.uuid;
  if (!checkout_data.isOnlinePayment) {
    const orderId = getDocId();
    window.sessionStorage.setItem('orderId', orderId);
  }

  return new Promise(function (resolve, reject) {
    const cartObj = {
      uuid: user.uuid,
      storeId: storeId,
      storeName: window.localStorage.getItem('storeName'),
      cartId: cartId,
      firebaseId: window.sessionStorage.getItem('orderId'), // OrderId
      deliveryMode: checkout_data.deliveryOption,
      deliveryAddress:
        checkout_data.deliveryOption === 'delivery'
          ? {
            addName: user.address[checkout_address]?.addName,
            phoneno: user.address[checkout_address]?.phoneno,
            building: user.address[checkout_address]?.building,
            street: user.address[checkout_address]?.street,
            city: user.address[checkout_address]?.city,
            state: user.address[checkout_address]?.state,
            pincode: user.address[checkout_address]?.pincode
            // default: true,
            // lat: 12,
            // long: 56
          }
          : null,
      deliveryCharges:
        checkout_data.deliveryOption === 'delivery'
          ? checkout_data.deliveryRs
          : 0,
      packagingCharges: checkout_data.packagingRs,
      paymentMode: checkout_data.paymentMethod,
      customerNotes: checkout_data.notes,
      totalAmount: checkout_data.totalPrice || 0,
      totalSavings: checkout_data.totalSaving || 0,
      totalTaxes: checkout_data.totalTaxes || 0,
      coupon: checkout_data.cpn,
      couponDocId: checkout_data.cpnId,
      discount: checkout_data.dicVal,
      additionalCharges: checkout_data.appliedAdditionalCharges,
      onlinePayment: checkout_data.isOnlinePayment
        ? checkout_data.onlinePayment
        : null
    };

    const APIObj = {
      endPoint: 'secure/orders',
      authenticationRequired: true,
      method: 'POST',
      body: JSON.stringify(cartObj)
    };

    ApiService.callApi(APIObj)
      .then((res) => {
        if (res.status) {
          resolve(res);
        } else reject(res);
      })
      .catch((error) => reject(error));
  });
}
async function payment_initiation(payload) {
  const { user } = getLocalStorageTokens();
  const orderId = getDocId();

  window.sessionStorage.setItem('orderId', orderId);

  return new Promise(function (resolve, reject) {
    const paymentObj = {
      orderId: orderId,
      storeId: window.sessionStorage.getItem('storeLinkingId'),
      note: 'payment description',
      redirectUrl: `https://${window.location.hostname}/order-status`,
      // redirectUrl: 'http://localhost:3000/order-status',
      notifyUrl: 'https://apnadukan.co',
      storeName: window.localStorage.getItem('storeName'),
      storeNumber: payload.storeDataNum,
      customerMobile: user.phoneno,
      customerEmail:
        user.email === undefined || user.email === null || user.email === ''
          ? 'contact@vardaan.app'
          : user.email,
      customerName:
        user.username === undefined ||
          user.username === null ||
          user.username === ''
          ? 'Marketplace User'
          : user.username,
      amount: payload.totalAmountTobePaid // cart total including taxes, other charges etc.
    };
    const APIObj = {
      endPoint: 'secure/initializePayment',
      authenticationRequired: true,
      method: 'POST',
      body: JSON.stringify(paymentObj)
    };

    ApiService.callApi(APIObj)
      .then((res) => {
        if (res.status) {
          resolve(res);
        } else reject(res);
      })
      .catch((error) => reject(error));
  });
}

async function check_payment_status(payload) {
  return new Promise(function (resolve, reject) {
    const paymentObj = {
      orderId: window.sessionStorage.getItem('orderId'),
      storeId: window.sessionStorage.getItem('storeLinkingId'),
      // amousnt: payload.totalAmountToBePaid
    };
    const APIObj = {
      endPoint: 'secure/paymentStatus',
      authenticationRequired: true,
      method: 'PUT',
      body: JSON.stringify(paymentObj)
    };

    ApiService.callApi(APIObj)
      .then((res) => {
        if (res.status) {
          resolve(res);
        } else reject(res);
      })
      .catch((error) => reject(error));
  });
}
