import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getUserAuthData } from '../../store/actions/userActions';

export const useLoginHook = () => {
  const dispatch = useDispatch();

  const getUserAuthentication = useCallback(
    (username, phone, token, refreshToken, uId) => {
      const data = {
        username,
        phone,
        uId,
        token,
        refreshToken
      };

      dispatch(getUserAuthData(data));
    },
    [dispatch]
  );

  return {  getUserAuthentication };
};
