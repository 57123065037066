import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { addToWishlist } from '../../store/actions/userActions';

export const useWishlistHook = () => {
    const dispatch = useDispatch();

    const updateWishlist = useCallback((WishList) => {
        dispatch(addToWishlist(WishList));
    }, [dispatch]);



    return { updateWishlist };
};
