export const getStoreIdBasedOnDomain = () => {
  const domain = window.location.hostname;
  // console.log(domain);
  switch (domain) {
    case 'localhost': //apnadukan.co
      return 'd9tpV8Jk0N';
    case 'iaracloset.in':
      return '4OVhA1Nl9e';
    case 'order-online.co':
      return 'd9tpV8Jk0N';
    case 'dev.order-online.co':
      return 'd9tpV8Jk0N';
    case 'gettogether.orderstoday.com':
      return 'Q4lmBcziby';
    case 'orderstoday.com':
      return 'Q4lmBcziby';
    case 'masterbakers.in':
      return '9E4WHc8tpa';
    case 'uniformmarkets.in':
      return '6QXlrHS73o';
    case 'shivam.agency':
      return 'z9gS43S2vh';
    case 'restoreits.com':
      return 'Kp2Vhe9QIx';
    case 'vardaman.in':
      return 'rzPLJOuosc';
    case 'saiblessings.store':
      return 'velJEXiaVb';
    case 'rakshand.com':
      return '1Ja8Pwgtq6';
    case 'newleafgrocery.in':
      return 'PiREg3lECa';
    case '360surveillance.online':
      return 'dA6VVuQXNG';
    case 'rajatjewellery.com':
      return 'O4Qp1Nq4mG';
    default:
      return undefined;
  }
};
