import React, { useEffect, useState } from 'react';
import { useAccountHook } from '../hooks';
import {
  Container,
  Typography,
} from '@material-ui/core';

import '../style.scss';
import { useStyles } from '../style';
import firebase from '../../../../config/firebase';

import { connect } from 'react-redux';

const Image = ({ userData, accountUserInfo }) => {
  const { updateUserAuthentication, logoutRequestHandler } = useAccountHook();
  const classes = useStyles();
  const [imageUrl, setImageUrl] = useState('');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    accountUserInfo = userData.user;
    if (accountUserInfo)
      setImageUrl(accountUserInfo?.image ? accountUserInfo.image : null);
  }, [userData.user]);

  const handleImageUpload = (file) => {
    if (file[file.length - 1]) {
      const uploadTask = firebase
        .storage()
        .ref(`onlineStore/customers/${file[file.length - 1].name}`)
        .put(file[file.length - 1]);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          // console.log(error);
        },
        () => {
          firebase
            .storage()
            .ref('onlineStore/customers')
            .child(file[file.length - 1].name)
            .getDownloadURL()
            .then((url) => {
              // console.log(url);
              accountUserInfo['image'] = url;
              updateUserAuthentication(accountUserInfo);
              setImageUrl(url);
            });
        }
      );
    }
  };

  return (
    <>
      {/* <Container style={{ height: '40px' }}></Container> */}
      <Container className={classes.avatar}  >
        <Typography style={{ fontSize: '24px' }}>
          {(accountUserInfo?.username &&
            accountUserInfo?.username.slice(0, 1)) ||
            'U'}
        </Typography>
      </Container>
    </>
  );
};

const mapStateToProps = ({ userData }) => ({
  userData
});

export default connect(mapStateToProps)(Image);
