import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Button,
  Box,
} from '@material-ui/core';

import '../style.scss';
import { useStyles } from '../style';
import { useAccountHook } from '../hooks';
import { navigateToWithState } from '../../../../utils/history';
import { ADDRESS } from '../../../../utils/routesConstants';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AddressList = ({ accountUserInfo }) => {
  const { updateUserAuthentication } = useAccountHook();
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [delAddId, setDelAddId] = useState('');
  const [delAddIsDefault, setDelAddIsDefault] = useState(false);

  return (
    <Grid container spacing={2}
    // className={classes.addressContainer}
    >
      {accountUserInfo?.address?.map((data, index) => {
        return (
          <Grid item xs={12} md={6} lg={6}
            // className={classes.addressGridItem}
            key={index}
          // className={!classes.cardBgColor}
          >
            <Box
              className="address-card"
              border={["0", "0", "1px solid #E1E1E1", "1px solid #E1E1E1"]}
              borderRadius={5}
              px={3} py={1} minHeight="max-content"
            >
              <Box borderBottom={1}
                className={classes.addressHead}
                display='flex' justifyContent='space-between'
                alignItems='center'
              >
                <Box
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Typography className={classes.addressName}>
                    {data.addName}
                  </Typography>
                  {data.default && (
                    <Box className="defaultAddressContainer">
                      <Typography className="defaultTagText">
                        {'Default'}
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box display='flex'>
                  <Button
                    color="primary"
                    className={classes.addAddress}
                    onClick={() => navigateToWithState(ADDRESS, data)}
                  >
                    Edit
                  </Button>
                  <Button
                    color="primary"
                    className={classes.addAddress}
                    onClick={() => {
                      // console.log(data);
                      setDelAddId(data._id);
                      setDelAddIsDefault(data.default);
                      setOpenModal(true);
                    }}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
              <Box py={1}>
                <Typography className={classes.address}>
                  {data.building} {data.street}, {data.city}, {data.state},{' '}
                  {data.pincode}
                </Typography>
              </Box>
            </Box>
            <Dialog
              open={openModal}
              onClose={() => {
                setOpenModal(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              BackdropProps={{ style: { backgroundColor: 'transparent' } }}
            >
              <DialogTitle id="alert-dialog-title">
                {'Do you want to delete the address?'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Confirm on deleting the address by clicking YES.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenModal(false)} color="primary">
                  No
                </Button>
                <Button
                  onClick={() => {
                    accountUserInfo.address = accountUserInfo.address.filter(
                      (x) => x._id !== delAddId
                    );

                    if (delAddIsDefault && accountUserInfo.address.length > 0) {
                      accountUserInfo.address[0].default = true;
                      setDelAddIsDefault(false);
                    }
                    updateUserAuthentication(accountUserInfo);
                    setOpenModal(false);
                  }}
                  color="primary"
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AddressList;
