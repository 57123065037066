import React from 'react';
import { Grid, } from '@material-ui/core';

import noSearchResultsFound from '../../../../../src/assets/images/empty_search.png';

const EmptyResult = ({ classes, isMobile }) => {
    return (
        <div
            className={classes.root}
            style={
                !isMobile
                    ? {
                        maxWidth: 800,
                        minWidth: 800
                    }
                    : {}
            }
        >
            <Grid
                container
                direction="column"
                style={{
                    justify: 'center',
                    alignItems: 'center'
                }}
            >
                {' '}
                <Grid item>
                    <img
                        src={noSearchResultsFound}
                        height="150px"
                        alt="no-search-results-found"
                    />
                </Grid>
                <br></br>
                <Grid item>No Results Found</Grid>
            </Grid>
        </div>
    );
}
export default EmptyResult;