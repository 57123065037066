//export const ROOT_ROUTE = '/';
// export const HOME_STORE_ROUTE_TEMP = '/store/:storeId';

export const LOGIN_ROUTE = '/login';
export const HOME_ROUTE = '/';
export const LOGIN_ACCOUNT = '/account-login';
export const ORDER_ROUTE = '/order_details';
export const ACCOUNT_ROUTE = '/account';
export const CART_ROUTE = '/cart';
export const ADDRESS = '/address';
export const WISHLIST = '/wishlist';
export const ORDER_CONFIRM = '/order-status';
export const INDIVIDUAL_ORDER = '/single-order';


export const HOME_STORE_ROUTE = '/s/:storeId';
export const CUSTOME_DOMAIN_HOME_STORE_ROUTE = '/';

export const ITEM_DETAILS = '/s/:storeId/:itemId';
export const INVOICE_VIEW = '/i/:storeId/:orderId';

export const CUSTOM_ITEM_DETAILS = '/:storeId/:itemId';
