import React from 'react';
import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  Paper
} from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import _ from 'lodash';

const AddCartButton = ({
  userCartData,
  item,
  removeItem,
  addItem,
  classes,
  uniqueOptionAvailable,
  isModel
}) => {
  var index = -1;
  var cartSelectedVariants = _.filter(userCartData.cartData, {
    _id: item?._id
  });
  if (
    cartSelectedVariants.length > 0 &&
    item.variants.length &&
    uniqueOptionAvailable.length
  )
    for (var i = 0; i < userCartData.cartData.length; i++) {
      if (
        _.find(userCartData.cartData[i].selectedVariants, {
          _id: uniqueOptionAvailable[0]?._id
        })
      ) {
        index = i;
      }
    }
  else {
    index = userCartData.cartData?.findIndex((x) => x._id === item?._id);
  }

  return (
    <Grid item xs={12} md={12} direction="row">
      {item &&
        index !== -1 &&
        userCartData.cartData[
          index
          // userCartData.cartData?.findIndex((x) => x._id === item._id)
        ]?.quantity > 0 ? (
          <Box
            py={1}
            px={isModel ? 1 : 3}
            display="flex"
            alignItems="center"
            borderRadius="10px"
            borderColor="#EBEBEB"
            backgroundColor="#ffffff"
            boxShadow={isModel ? '' : '0px 1px 20px 0px #0000001a'}
          >
            <Box
              display="flex"
              width="100%"
              justifyContent={isModel ? 'flex-end' : 'space-between'}
              alignItems="center"
            >
              <Box
                display="flex"
                alignItems="center"
                className="cartbtn-container"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  borderRadius={5}
                  style={{ fontSize: '14px' }}
                  borderColor={'#EBEBEB'}
                >
                  <Grid item className="add-item-outer-container">
                    <Grid
                      container
                      direction="row"
                      className="add-item-inner-container"
                    >
                      <Grid
                        item
                        className="add-item-minus-container"
                        onClick={
                          userCartData.onAddItemLoader
                            ? null
                            : () => removeItem(item)
                        }
                      >
                        <Typography>{'-'}</Typography>
                      </Grid>
                      <Grid item>
                        {userCartData.onAddItemLoader ? (
                          <div className="circular">
                            <CircularProgress size={16} />
                          </div>
                        ) : (
                            <Typography className="add-item-counter">
                              {item && userCartData.cartData[index]?.quantity}
                            </Typography>
                          )}
                      </Grid>

                      <Grid
                        item
                        className="add-item-plus-container"
                        onClick={
                          userCartData.onAddItemLoader
                            ? null
                            : () => addItem(item)
                        }
                      >
                        <Typography>{'+'}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box
                display={isModel ? 'none' : 'block'}
                width="55%"
                className="cartbtn-container"
              >
                <Paper
                  // className={classes.itemAddedTab}

                  style={{
                    padding: 8,
                    margin: 5,
                    textAlign: 'center',
                    cursor: 'pointer',
                    color: 'white',
                    backgroundColor: '#DBA719',
                    width: '-webkit-fill-available',
                  }}
                >
                  <Typography style={{ fontSize: 20 }}> Added</Typography>
                </Paper>
              </Box>
            </Box>
          </Box>
        ) : (
          <Paper
            // className={classes.addToCartButton}

            onClick={userCartData.onAddItemLoader ? null : () => addItem(item)}
            style={{
              cursor: 'pointer',
              paddingLeft: 32,
              paddingRight: 32,
              paddingTop: 8,
              paddingBottom: 8,
              margin: 5,
              textAlign: 'center',
              color: 'white',
              backgroundColor: '#5C4DB1',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {userCartData.onAddItemLoader ? (
              <CircularProgress
                style={{ color: 'white', marginRight: 5 }}
                size={24}
              />
            ) : (
                <Box display="flex" alignItems="center">
                  <ShoppingCartIcon
                    style={{
                      color: 'white',
                      textAlign: 'center',
                      marginRight: 5,
                      fontSize: 22
                    }}
                  />
                  <Typography style={{ fontSize: 16 }}> Add to Cart</Typography>
                </Box>
              )}
          </Paper>
        )}
    </Grid>
  );
};
export default AddCartButton;
