const { makeStyles } = require("@material-ui/core");

export const useStyles = makeStyles((theme) => ({
    //appbar.js
    back_icon: {
        color: 'white'
    },
    appBar: {
        color: '#5C4DB1',
        padding: 5
    },
    appText: {
        color: 'white',
        alignSelf: 'center'
    },
    text_flex: {
        flex: 1
    },
    cart_icon: {
        color: 'white',
        justify: "space-between",
    },
    storeContainer: {
        padding: '2%',
        margin: '2%',
        alignItems: 'center'

    },
    box: {
        paddingTop: theme.spacing(7),
        paddingBottom: theme.spacing(8),
    },
    responsiveImg: {
        maxWidth: '90%',
        height: '250px',
    },
    button: {
        // width: '90%',
        background: '#5C4DB1',
        color: 'white',
        textTransform: 'none',
        fontSize: 18,
        marginTop: 40

    },
    grid: {
        paddingTop: '50%',
        justifyContent: 'center',
        display: 'flex'
    },
}));