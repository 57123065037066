import React from 'react';
import QRCode from "react-qr-code";
import {
  Box,
  Grid,
  Button,
  Typography
} from '@material-ui/core';
import '../invoice_styles.scss';
import { useHistory, useParams } from 'react-router-dom';

const OldInvoice = ({
  orderDate,
  invoiceData,
  items,
  discountPercent,
  subTotal,
  deliveryCharges,
  packagingCharges,
  totalAmount,
  showTaxColumn,
  totalTaxes,
}) => {
  const params = useParams();
  const history = useHistory();
  const goToHome = () => {
    history.push(`/s/${params.storeId}`)
  }
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center' }}
      className="invoiceRoot"
    >
      <div className="MobileApp" id="printableArea">
        <div className="paddingHorizentalOldInvoice">
          <div className="rows oldInvoiceTopRow ">
            <div className="rowStart">
              <div style={invoiceDateContainer}>
                INVOICE Date{': '}
                <div className="blackboldtext">{orderDate}</div>
              </div>
              {invoiceData.deliverDate && (
                <div style={invoiceDateContainer}>
                  Delivery Date{': '}
                  <div className="blackboldtext">{invoiceData.deliverDate}</div>
                </div>
              )}
              {invoiceData.terms && (
                <div style={invoiceDateContainer}>
                  Terms{': '}
                  <div className="blackboldtext">{invoiceData.terms}</div>
                </div>
              )}
              {invoiceData.orderStatusId && (
                <div style={invoiceDateContainer}>
                  Order Status{': '}
                  <div className="blackboldtext">{invoiceData.orderStatus}</div>
                </div>
              )}
            </div>

            <div className="rowEnd">
              <div style={{ ...invoiceDateContainer, alignItems: 'center' }}>
                INV. ID{': '}
                <div style={{ ...invoiceContainer, padding: 0 }}>
                  #{invoiceData.orderId}
                </div>
              </div>
              <div style={{ ...invoiceDateContainer, alignItems: 'center' }}>
                Balance Due{': '}
                <div style={{ ...invoiceContainer, padding: 0 }}>
                  {showPendingAmount(invoiceData.orderStatusId)
                    ? `Rs.${Math.round(
                      (totalAmount - invoiceData.amountPaid) * 100
                    ) / 100
                    }`
                    : 'Rs.0'}
                </div>
              </div>
            </div>
          </div>
        </div>

        {invoiceData.isPaidOnline &&

          <Box px={[2, 2, 0, 0]} py={1} mt={[1, 1, 0, 0]} >
            <Box display="flex" justifyContent="space-between" style={{ paddingLeft: "1.5vh", paddingRight: "1.5vh" }}>
              <Typography
                style={{ fontSize: 14, fontWeight: 700 }}
                // className={classes.price_details_text}
                variant="subtitle1"
              >
                Mode:

              </Typography>
              <Typography

                style={{ fontSize: 14, fontWeight: 'bold', color: 'green' }}
                // className={classes.price_details_text}
                variant="subtitle1"
              >
                {'Paid online'}
              </Typography>
            </Box>
          </Box>}


        <div className="paddingHorizentalOldInvoice">
          {invoiceData.terms ? (
            <div style={{ height: 30 }}></div>
          ) : (
              <div style={{ height: 15 }}></div>
            )}
        </div>

        <div style={rows} className="paddingHorizentalOldInvoice">
          {(invoiceData.storeName ||
            invoiceData.storeNumber ||
            invoiceData.storeAddress) && (
              <div style={{ ...rowStart, lineHeight: '22px' }}>
                <div style={invoiceContainer}>From : </div>
                <div style={headerStoreContainer}>{invoiceData.storeName} </div>
                <div style={headerBlackMediumContainer}>
                  {invoiceData.storeNumber}{' '}
                </div>
                <div style={headerBlackMediumContainer}>
                  {invoiceData.storeAddress}{' '}
                </div>
              </div>
            )}

          {(invoiceData.customerName ||
            invoiceData.customerNumber ||
            invoiceData.customerAddress) && (
              <div style={{ ...rowEnd, lineHeight: '22px' }}>
                <div style={invoiceContainer}>Bill To : </div>
                <div style={headerStoreContainer}>
                  {invoiceData.customerName}{' '}
                </div>
                <div style={headerBlackMediumContainer}>
                  {invoiceData.customerNumber}{' '}
                </div>
                <div style={headerBlackMediumContainer}>
                  {invoiceData.customerAddress}{' '}
                </div>
              </div>
            )}
        </div>

        <div className="paddingHorizentalOldInvoice">
          {invoiceData.storeGstNumber && (
            <>
              <div style={gstContainer}>GST Number</div>
              <div style={gstValueContainer}>{invoiceData.storeGstNumber}</div>
            </>
          )}
        </div>

        <div style={{ height: 60 }} className="paddingHorizentalOldInvoice">
          {invoiceData.customerGstNumber && (
            <>
              <div style={gstContainer}>GST Number</div>
              <div style={gstValueContainer}>
                {invoiceData.customerGstNumber}
              </div>
            </>
          )}
        </div>

        <div>
          <div style={{ overflowX: 'scroll' }}>
            <table style={{ width: '100%' }} className="paddingHorizentalOldInvoice" cellSpacing={0}>
              <tr style={{ ...tableHeaderContainer }}>
                <th style={{ ...tableHeaders, width: "35%" }}>ITEM</th>
                <th style={tableHeaders}>Price</th>
                <th style={tableHeaders}>QTY</th>
                <th style={{ ...tableHeaders, textAlign: 'end', paddingRight: '5%' }}>Amount</th>
              </tr>

              {items.map((item) => {
                return (
                  <tr
                    key={item.itemType}
                  >
                    <td style={tableCells}>
                      <div
                        style={{
                          fontSize: 14,
                          fontWeight: 'bold',
                          opacity: 0.75,
                          marginBottom: 5
                        }}
                      >
                        {item.itemType}
                      </div>
                      {showTaxColumn && (
                        <span style={{ fontSize: 12 }}>GST %: {item.gst}</span>
                      )}
                    </td >
                    <td style={{ ...tableCells, width: "35%" }}>
                      {Math.round(item.price * 100) / 100}
                    </td>
                    <td style={tableCells}>{item.quantity}</td>
                    <td style={{ ...tableCells, textAlign: "end", paddingRight: '5%' }}>
                      {Math.round(item.price * item.quantity * 100) / 100}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>

          <div
            className="paddingHorizentalOldInvoice"
            style={{ lineHeight: '22px' }}
          >
            <div style={{ height: 20 }}></div>

            <div style={subTotalContainer}>
              <RowText text={'Sub Total'}></RowText>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div>Rs.{subTotal}</div>
            </div>
            <div style={discountContainer}>
              <RowText text={'Discount'}></RowText>
              <div></div>
              <div></div>
              <div></div>
              <div>{discountPercent}%</div>
              <div>
                - Rs.{Math.round(Math.abs(invoiceData.discount) * 100) / 100}
              </div>
            </div>
            {totalTaxes > 0 && (
              <div style={discountContainer}>
                <RowText text={'Total taxes'}></RowText>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div>Rs.{totalTaxes}</div>
              </div>
            )}

            {invoiceData.additionalCharges &&
              invoiceData.additionalCharges.length ? (
                invoiceData.additionalCharges.map((ele) => {
                  return (
                    <div style={deliveryChargesContainer}>
                      <RowText text={ele.name}></RowText>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div>Rs.{ele.cost}</div>
                    </div>
                  );
                })
              ) : (
                <>
                  {deliveryCharges > 0 && (
                    <div style={deliveryChargesContainer}>
                      <RowText text={'Delivery Charges'}></RowText>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div>Rs.{deliveryCharges}</div>
                    </div>
                  )}
                  {packagingCharges > 0 && (
                    <div
                      style={
                        deliveryCharges > 0
                          ? discountContainer
                          : deliveryChargesContainer
                      }
                    >
                      <RowText text={'Packaging Charges'}></RowText>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div>Rs.{packagingCharges}</div>
                    </div>
                  )}
                </>
              )}
            {invoiceData.amountPaid > 0 && (
              <div style={discountContainer}>
                <RowText text={'Advance amount'}></RowText>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div>Rs.{invoiceData.amountPaid}</div>
              </div>
            )}

            <div style={totalAmountContainer}>
              <RowText text={'Total Amount'}></RowText>
              <div>Rs.{Math.round(totalAmount * 100) / 100}/-</div>
            </div>
            {!invoiceData.isPaidOnline && invoiceData.paymentLink && invoiceData.paymentLink.length ? (
              <div className="rows paymentLinkContainer">
                <div>
                  <RowText text={'UPI Link:'} />
                  <a href={invoiceData.paymentLink} target="_blank">
                    {' '}
                    {invoiceData.paymentLink}
                  </a>
                </div>

                <div>
                  <QRCode value={invoiceData.paymentLink} size="100" />
                </div>
              </div>
            ) :
              <div className="rows paymentLinkContainer">
                <div>
                  <RowText text={`Visit our store ${invoiceData.isPremiumUser ? invoiceData.storeName : 'Vardaan'} by scanning QRCode.`} />

                </div>
                <div>
                  <QRCode value={`https://apnadukan.co/s/${params.storeId}`} size="100" />
                </div>
              </div>}
          </div>

          {/* <div style={customGeneratedBillContainer}>
            This is a computer generated bill and if you found any issues
            contact your store owner or Vardaan support by sharing your invoice.
          </div> */}

          <div style={poweredbyContainer}>
            Powered by
            <span style={poweredbyText}>
              {invoiceData.isPremiumUser ? ` ${invoiceData.storeName}` : ' Vardaan'}
            </span>
          </div>
        </div>
      </div>
    </div >
  );
};

function showPendingAmount(status) {
  switch (status) {
    case 'order_created':
      return true;
    case 'order_cancelled':
      return false;
    case 'order_completed':
      return false;
    case 'payment_pending':
      return true;
    case 'shipped':
      return true;
    case 'accepted':
      return true;
    case 'refund_return':
      return false;
    case 'rejected':
      return false;
    case 'delivered':
      return false;
    default:
      return false;
  }
}

export default OldInvoice;

const RowText = ({ text }) => <div style={{ minWidth: 100 }}>{text}</div>;

const rows = {
  display: 'flex',
  justifyContent: 'space-between',
  flex: 1
};
const rowStart = {
  display: 'flex',
  flexDirection: 'column',
  flex: 3,
  fontSize: '14px'
};

const rowEnd = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  flex: 3
};

const textrow = {
  display: 'flex',
  whiteSpace: 'pre'
};

const headertext = {
  padding: '2px'
};

const greyboldtext = {
  color: '#808080',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '22px'
};

const blackboldtext = {
  color: 'black',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: '22px'
};

const blackmediumtext = {
  color: 'black',
  fontWeight: '300',
  fontSize: '14px',
  lineHeight: '22px'
};

const storename = {
  color: 'black',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '22px'
};

const tableheader = {
  color: 'white',
  fontWeight: '600',
  fontSize: '14px',
  paddingBottom: '16px',
  paddingTop: '16px',
  paddingLeft: '3px',
  backgroundColor: 'rgb(92, 77, 177)',
  marginBottom: 12,
  lineHeight: '22px'
};

const itemPadding = {
  padding: '5px 3px'
};

const poweredbyText = {
  color: '#5c4db1',
  fontWeight: 'bold',
  whiteSpace: 'pre'
};

const fontColor = { color: 'black' };

const button = {
  width: '93%',
  background: '#5C4DB1',
  color: 'white',
  textTransform: 'none',
  fontSize: 18,
}

const subTotalContainer = {
  ...rows,
  ...fontColor,
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 5,
  paddingRight: 5,
  fontWeight: 'bold',
  opacity: 0.8,
  borderBottom: '0.5px solid grey'
};

const discountContainer = { ...rows, ...fontColor, padding: 5 };

const totalAmountContainer = {
  ...rows,
  ...fontColor,
  paddingTop: 20,
  paddingBottom: 20,
  paddingLeft: 5,
  paddingRight: 5,
  borderBottom: '0.5px solid grey',
  borderTop: '0.5px solid grey',
  fontWeight: 'bold',
  opacity: 0.85
};

const grid = {
  paddingTop: '4%',
  paddingBottom: '4%',
  justifyContent: 'center',
  display: 'flex',
};

const deliveryChargesContainer = {
  ...rows,
  ...fontColor,
  padding: 5,
  borderTop: '0.5px solid grey'
};

const customGeneratedBillContainer = {
  ...rows,
  ...fontColor,
  padding: '10px',
  textAlign: 'center',
  lineHeight: '22px'
};

const tableHeaders = {
  paddingTop: '15px',
  paddingBottom: '15px',
  width: '15%'
};

const tableCells = {
  paddingTop: '12px',
  paddingBottom: '12px',
  textAlign: 'center',
  width: '15%'
}

const poweredbyContainer = {
  ...rows,
  ...fontColor,
  padding: '10px 5px',
  justifyContent: 'center',
  lineHeight: '22px'
};

const invoiceDateContainer = {
  ...textrow,
  ...headertext,
  ...greyboldtext,
  lineHeight: '22px'
};

// const lineHeight = {
//   lineHeight: '20px'
// };

const gstContainer = { ...headertext, ...greyboldtext };

const invoiceContainer = { ...headertext, ...blackboldtext };
const gstValueContainer = { ...headertext, ...blackboldtext, opacity: 0.85 };

const headerBlackMediumContainer = { ...headertext, ...blackmediumtext };

const headerStoreContainer = { ...headertext, ...storename };

const tableHeaderContainer = { ...tableheader };

const itemPaddingContainer = { ...rows, ...itemPadding, ...fontColor };
