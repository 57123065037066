import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  requestAddItemToCart,
  requestRemoveItemFromCart,
  requestUserCart,
  requestCartCheckout,
  requestaddUserNotes,
  requestUpdateVariants,
  getActiveCoupons,
  requestInitiatePayment
} from '../../store/actions/userActions';

export const useCartHook = () => {
  const dispatch = useDispatch();

  const addItemToCart = useCallback(
    (item) => {
      dispatch(requestAddItemToCart([item]));
    },
    [dispatch]
  );

  const removeItemFromCart = useCallback(
    (item) => {
      dispatch(requestRemoveItemFromCart([item]));
    },
    [dispatch]
  );

  const getUsercart = useCallback(() => {
    dispatch(requestUserCart());
  }, [dispatch]);

  const cartCheckout = useCallback(
    (checkout_data) => {
      dispatch(requestCartCheckout(checkout_data));
    },
    [dispatch]
  );

  const addUserNotes = useCallback(
    (note) => {
      dispatch(requestaddUserNotes(note));
    },
    [dispatch]
  );

  const updateVariants = useCallback(
    (item) => {
      dispatch(requestUpdateVariants(item));
    },
    [dispatch]
  );

  const activeCouponsList = useCallback(() => {
    dispatch(getActiveCoupons());
  }, [dispatch]);
  const initiatePayment = useCallback(
    (paymentObj) => {
      dispatch(requestInitiatePayment([paymentObj]));
    },
    [dispatch]
  );


  return {
    addItemToCart,
    removeItemFromCart,
    getUsercart,
    cartCheckout,
    addUserNotes,
    updateVariants,
    activeCouponsList,
    initiatePayment
  };
};
