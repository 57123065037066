import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  startLogout,
  updateUserAuthData
} from '../../../store/actions/userActions';

export const useAccountHook = () => {
  const dispatch = useDispatch();

  const updateUserAuthentication = useCallback(
    (userInfo) => {
      dispatch(updateUserAuthData(userInfo));
    },
    [dispatch]
  );

  const logoutRequestHandler = useCallback(() => {
    dispatch(startLogout());
  }, [dispatch]);

  return { updateUserAuthentication, logoutRequestHandler };
};
