import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  makeStyles,
  BottomNavigationAction,
  BottomNavigation,
  Badge
} from '@material-ui/core';
import { getLocalStorageTokens } from '../../utils/tokensHelper';

import HomeIcon from '@material-ui/icons/Home';
import CartIcon from '@material-ui/icons/ShoppingCart';
import UserIcon from '@material-ui/icons/Person';

import Home from './Home';
import Account from './Account';
import OrderDetails from './Order_Detail';
import { replace } from '../../utils/history';

// import Grid from '@material-ui/core/Grid';
import Header from '../Header/mainHeader';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({

  selected: {
    fontSize: [12, '!important'],
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

var tabState = 'home';

const SimpleBottomNavigation = ({ userCartData }) => {
  const { storeId } = useParams();

  const classes = useStyles();
  const location = useLocation();
  const data = location.state;
  const [value, setValue] = React.useState(data ? data : tabState);
  const [scrollMap, setScrollMap] = React.useState({});
  useEffect(() => {
    tabState = value;
    if (data) replace();
    // window.sessionStorage.setItem('storeId', storeId);
  }, []);
  const updateScrollMap = (tabState, newValue) => {
    let dummyTabDetails = {};
    dummyTabDetails[tabState] = {
      x: window.pageXOffset,
      y: window.pageYOffset,
    };

    setScrollMap(prev => {
      return { ...prev, ...dummyTabDetails };
    });

    if (value === 'home') {
      return;
    }

    window.scrollTo(
      scrollMap[newValue] === undefined ? 0 : scrollMap[newValue].x,
      scrollMap[newValue] === undefined ? 0 : scrollMap[newValue].y
    );
  };

  const { isLoggedIn } = getLocalStorageTokens();


  return (
    <Box position="relative" className="full-page">

      <Box
        className="category-outer-container full-content"
      >
        {///TODO: We need nested routing approach here
          value === 'home'
            ? <Home />
            : value === 'account' ? <Account /> : <OrderDetails />}
      </Box>
      <Box>

        <Box className="content-mobile">
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              updateScrollMap(tabState, newValue);

              tabState = newValue;
              setValue(newValue);
            }}
            showLabels
            className="stickyMenu "
          >

            <BottomNavigationAction
              // classes={{ selected: classes.selected }}
              classes={{
                selected: classes.selected,
              }}
              value="home"
              label="Home"
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              icon={
                <Badge
                  color="primary"
                  badgeContent={!isLoggedIn && value !== 'home' ? userCartData.cartData.length : 0}
                  classes={{
                    anchorOriginTopRightRectangle: classes.badgeIcon
                  }}
                >
                  <HomeIcon />
                </Badge>}
            />
            <BottomNavigationAction
              classes={{
                selected: classes.selected,
              }}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              //  classes={{ selected: classes.selected }}
              value="order"
              label="My Orders"
              style={{ flexWrap: 'nowrap' }}
              icon={<CartIcon />}
            />
            <BottomNavigationAction
              //  classes={{ selected: classes.selected }}
              classes={{
                selected: classes.selected,
              }}
              value="account"
              label="Account"
              icon={<UserIcon />}
            />
          </BottomNavigation>
        </Box>
      </Box>
    </Box>
  );
}

const mapStateToProps = ({ userCartData }) => ({
  userCartData
});
export default connect(mapStateToProps)(SimpleBottomNavigation);
