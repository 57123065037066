import React, { Component } from "react";

import ReactImageMagnify from "react-image-magnify";
// import { withStyles } from "@material-ui/core/styles";

// const styles = theme => ({
//     root: {}
// });

class MyReactImageMagnify extends Component {
    render() {
        return (
            <ReactImageMagnify
                {...{
                    smallImage: {
                        alt: "No Image",
                        isFluidWidth: true,
                        src: this.props.image,

                        // src: "https://placeimg.com/640/480/any"
                    },
                    largeImage: {
                        src: this.props.image,
                        width: 1000,
                        height: 1200
                    },
                    enlargedImagePortalId: "imageMagnifier"
                }}
            />
        );
    }
}

// export default withStyles(styles)(MyReactImageMagnify);
export default MyReactImageMagnify;
