import { takeLatest, put, select } from 'redux-saga/effects';
import actionTypes from '../actionTypes';
import { updateWishlist } from '../actions/userActions';




function* getAllWishlist() {


}
function* updateWishListByItem(action) {
    yield put(updateWishlist(action.payload));

    // const state = yield select();
    // let prevWishlistData = state.wishlist.wishlistData;
    // const storeId = window.sessionStorage.getItem('storeLinkingId');

    // prevWishlistData[storeId] = action.payload;
    // console.log(prevWishlistData, 'prevWishlistData', action.payload, storeId)
    // yield put(updateWishlist(prevWishlistData));
    // yield put(updateWishlist([]))

}

export default [
    takeLatest(actionTypes.GET_WISHLIST, getAllWishlist),
    takeLatest(actionTypes.ADD_TO_WISHLIST, updateWishListByItem)];
