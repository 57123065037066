import React from 'react';
import {Grid, Button} from '@material-ui/core';
import {useAccountHook} from '../hooks';
import '../style.scss';
import {useStyles} from '../style';

const Logout = () => {
  const classes = useStyles ();
  const {logoutRequestHandler} = useAccountHook ();

  return (
    <Grid container className={classes.logoutContainer}>
      <Grid item xs={12}>
        <Button
          fullWidth={true}
          variant="outlined"
          color="primary"
          size="large"
          className={classes.logoutButton}
          onClick={logoutRequestHandler}
        >
          Logout
        </Button>
      </Grid>
    </Grid>
  );
};

export default Logout;
