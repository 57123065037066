import React, { useState, useEffect } from 'react';
import '../invoice_styles.scss';

const secondaryColor = '#5c4db1';
const primaryColor = '#000';

const NewInvoice = ({
  orderDate,
  invoiceData,
  items,
  showTaxColumn,
  showHSNColumn
}) => {
  const [subTotalBeforeTax, setSubTotalBeforeTax] = useState(null);
  const [totalTax, setTotalTax] = useState(null);
  const [subTotalAfterTax, setSubTotalAfterTax] = useState(null);
  const [total, setTotal] = useState(null);
  const [discountPercent, setDiscountPercent] = useState(null);
  const [uniqueTax, setUniqueTax] = useState(null);
  const [terms, setTerms] = useState(null);

  useEffect(() => {
    let sumSubTotal = 0;
    let totalTaxAmount = 0;

    for (let i = 0; i < items.length; i++) {
      sumSubTotal =
        sumSubTotal +
        (Math.round(((items[i].price * 100) / (100 + items[i].gst)) * 100) /
          100) *
          items[i].quantity;
    }
    setSubTotalBeforeTax(Math.round(sumSubTotal * 100) / 100);
    setTerms(invoiceData.terms.split('\n'));

    // Unique tax function here
    let uniqueTaxObj = items.reduce(function (allTax, item) {
      if (item.gst in allTax) {
        allTax[item.gst] =
          allTax[item.gst] +
          item.quantity *
            (Math.round(
              ((((item.price * 100) / (100 + item.gst)) * item.gst) / 100) * 100
            ) /
              100);
      } else {
        allTax[item.gst] =
          item.quantity *
          (Math.round(
            ((((item.price * 100) / (100 + item.gst)) * item.gst) / 100) * 100
          ) /
            100);
      }
      return allTax;
    }, {});

    var taxArray = [];
    var taxObj = {};
    for (var key of Object.keys(uniqueTaxObj)) {
      taxObj = {};
      taxObj[key] = uniqueTaxObj[key];
      taxArray.push(taxObj);
      totalTaxAmount = totalTaxAmount + uniqueTaxObj[key];
    }
    setUniqueTax(taxArray);
    setTotalTax(totalTaxAmount);
    setSubTotalAfterTax(Math.round((sumSubTotal + totalTaxAmount) * 100) / 100);
  }, []);

  useEffect(() => {
    if (invoiceData.discount) {
      setTotal(
        Math.round((subTotalAfterTax - Math.abs(invoiceData.discount)) * 100) /
          100
      );
    } else {
      setTotal(subTotalAfterTax);
    }
    setDiscountPercent(
      Math.round(
        ((Math.abs(invoiceData.discount) * 100) / subTotalAfterTax) * 100
      ) / 100
    );
  }, [subTotalAfterTax]);

  return (
    <div id="newPrintableArea">
      <div className="newInvoiceHeaderForPrint">
        <div
          style={{ color: secondaryColor, paddingRight: 20 }}
          className="headerInvoiceText headerInvoiceTextForPrint"
        >
          INVOICE
        </div>
        <div style={{ borderTop: `3px solid ${secondaryColor}` }}></div>
        <div className="topHeaderBoxData">
          <div className="rows">
            <div className="rowStart">
              <div className="topHeaderBoxDataText">Invoice #</div>
              <div className="topHeaderBoxDataText">Date:</div>
              <div className="topHeaderBoxDataText">Amount:</div>
            </div>

            <div className="rowEnd">
              <div className="topHeaderBoxDataText">{invoiceData.orderId}</div>
              <div className="topHeaderBoxDataText">{orderDate}</div>
              <div className="topHeaderBoxDataText">
                {'₹ '}
                {total}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="NewMobileApp" style={{ paddingTop: 20 }}>
          <div style={{ height: 180 }} className="newInvoiceHeader">
            <div
              style={{ color: secondaryColor, paddingRight: 20 }}
              className="headerInvoiceText"
            >
              INVOICE
            </div>
            <div style={{ borderTop: `3px solid ${secondaryColor}` }}></div>
            <div className="topHeaderBoxData">
              <div className="rows">
                <div className="rowStart">
                  <div className="topHeaderBoxDataText">Invoice #</div>
                  <div className="topHeaderBoxDataText">Date:</div>
                  <div className="topHeaderBoxDataText">Amount:</div>
                </div>

                <div className="rowEnd">
                  <div className="topHeaderBoxDataText">
                    {invoiceData.orderId}
                  </div>
                  <div className="topHeaderBoxDataText">{orderDate}</div>
                  <div className="topHeaderBoxDataText">
                    {'₹ '}
                    {total}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ padding: 20 }}>
            <div style={rows}>
              {(invoiceData.storeName ||
                invoiceData.storeNumber ||
                invoiceData.storeAddress) && (
                <div style={rowStart}>
                  <div style={invoiceContainer}>From : </div>
                  <div style={headerStoreContainer}>
                    {invoiceData.storeName}{' '}
                  </div>
                  <div style={headerBlackMediumContainer}>
                    {invoiceData.storeNumber}{' '}
                  </div>
                  <div style={headerBlackMediumContainer}>
                    {invoiceData.storeAddress}{' '}
                  </div>
                </div>
              )}

              {(invoiceData.customerName || invoiceData.customerNumber) && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    flex: 3
                  }}
                >
                  <div style={invoiceContainer}>Bill To : </div>
                  <div style={headerStoreContainer}>
                    {invoiceData.customerName}{' '}
                  </div>
                  <div style={headerBlackMediumContainer}>
                    {invoiceData.customerNumber}{' '}
                  </div>
                  <div style={headerBlackMediumContainer}> </div>
                </div>
              )}
            </div>
            {invoiceData.storeGstNumber && (
              <div style={gstContainer}>
                GST Number {' : '}{' '}
                <span style={headerBlackMediumContainer}>
                  {invoiceData.storeGstNumber}
                </span>
              </div>
            )}

            <div style={{ height: 10 }}></div>

            <div style={{ overflowX: 'scroll' }}>
              <div className="newTableHeaderContainer">
                <div style={rows}>
                  <div className="newItemWidth">Item Name</div>
                  {showHSNColumn && <div style={hsnWidth}>HSN/SAC</div>}
                  <div style={newQtyWidth}>Quantity</div>
                  <div style={newPriceWidth}>Sales Price</div>
                  {showTaxColumn && <div style={newTaxWidth}>Tax(%)</div>}
                  <div style={newAmountWidth}>Amount(₹)</div>
                </div>
              </div>

              {items.map((item) => {
                return (
                  <div
                    key={item.itemType}
                    style={newItemPaddingContainer}
                    className="newInvoiceCommonFont"
                  >
                    <div style={rows}>
                      <div className="newItemWidth">
                        <div>{item.itemType}</div>
                      </div>
                      {showHSNColumn && (
                        <div style={hsnWidth}>{item.hsnCode}</div>
                      )}
                      <div style={newQtyWidth}>{item.quantity}</div>
                      <div style={newPriceWidth}>
                        {Math.round(
                          ((item.price * 100) / (100 + item.gst)) * 100
                        ) / 100}
                      </div>
                      {showTaxColumn && (
                        <div style={newTaxWidth}>{item.gst}</div>
                      )}
                      <div style={newAmountWidth}>
                        {(Math.round(
                          ((item.price * 100) / (100 + item.gst)) * 100
                        ) /
                          100) *
                          item.quantity}
                      </div>
                    </div>
                  </div>
                );
              })}

              <div style={{ height: 10 }}></div>
            </div>

            <div
              style={{ ...newSubTotalContainer, padding: '15px 3px 0px 3px' }}
            >
              <div style={rowStart} />
              <div style={rowMiddle} className="newInvoiceCommonFont">
                <div>Sub Total</div>
                <div>₹{subTotalBeforeTax}</div>
              </div>
            </div>

            <div
              style={{ ...newSubTotalContainer, padding: '0px 3px 15px 3px' }}
            >
              <div style={rowStart} />
              <div
                style={{
                  ...rowMiddle,
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
                className="newInvoiceCommonFont"
              >
                {showTaxColumn &&
                  uniqueTax &&
                  uniqueTax.map((item, index) => {
                    return (
                      <div
                        key={item.itemType}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          width: '100%',
                          color: primaryColor
                        }}
                      >
                        <div>Tax@{`${Object.keys(uniqueTax[index])}%`}</div>
                        <div>{`${Object.values(uniqueTax[index])}`}</div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div style={newSubTotalContainer}>
              <div style={rowStart} />
              <div style={rowMiddle}>
                <div style={{ border: '0.5px solid grey', flex: 1 }} />
              </div>
            </div>

            <div
              style={{ ...newSubTotalContainer, padding: '15px 3px 0px 3px' }}
            >
              <div style={rowStart} />
              <div style={rowMiddle} className="newInvoiceCommonFont">
                <div>Sub Total</div>
                <div style={{ fontFamily: 'Montserrat' }}>
                  ₹{subTotalAfterTax}
                </div>
              </div>
            </div>

            <div
              style={{ ...newSubTotalContainer, padding: '0px 3px 15px 3px' }}
            >
              <div style={rowStart} />
              <div style={rowMiddle} className="newInvoiceCommonFont">
                {Math.abs(invoiceData.discount) > 0 && (
                  <>
                    <div className="newInvoiceDiscounttext">
                      Discount@{discountPercent}%
                    </div>
                    <div>{Math.abs(invoiceData.discount)}</div>
                  </>
                )}
              </div>
            </div>
            <div style={newSubTotalContainer}>
              <div style={rowStart} />
              <div style={rowMiddle}>
                <div style={{ border: '0.5px solid grey', flex: 1 }} />
              </div>
            </div>

            <div
              style={{ ...newSubTotalContainer, padding: '15px 3px 0px 3px' }}
            >
              <div style={rowStart} />
              <div style={rowMiddle} className="newInvoiceCommonFont">
                <div style={{ color: secondaryColor }}>Total:</div>
                <div style={{ color: secondaryColor }}>₹{total}</div>
              </div>
            </div>

            <div style={{ height: 20 }} />
            <div style={rows} className="TnC_Container">
              {invoiceData.terms && (
                <div style={rowStart}>
                  <div style={{ color: secondaryColor, marginBottom: 10 }}>
                    Terms & Condition
                  </div>
                  <div
                    style={{
                      borderBottom: '1px solid black',
                      width: '40%',
                      marginBottom: 10
                    }}
                  />
                  {terms &&
                    terms.map((term) => (
                      <div style={{ color: '#000' }}>{term}</div>
                    ))}
                </div>
              )}

              {(invoiceData.paymentLink || invoiceData.qrCode) && (
                <div style={rowEnd}>
                  <div style={{ color: secondaryColor, marginBottom: 10 }}>
                    Payment Details
                  </div>
                  <div
                    style={{
                      borderBottom: '1px solid black',
                      width: '25%',
                      marginBottom: 10
                    }}
                  />

                  {invoiceData.upiId && (
                    <div style={primaryColorText}>
                      UPI ID: {invoiceData.upiId}
                    </div>
                  )}

                  {invoiceData.paymentLink && (
                    <div style={{ marginTop: 10 }}>
                      {' '}
                      <a
                        style={{ textDecoration: 'none', color: primaryColor }}
                        href={invoiceData.paymentLink}
                        target="_blank"
                      >
                        {' '}
                        {invoiceData.paymentLink}
                      </a>
                    </div>
                  )}
                  {invoiceData.qrCode && (
                    <div style={{ marginTop: 10 }}>
                      {' '}
                      <img
                        alt="qr-code"
                        src={`data:image/svg+xml;utf8,${invoiceData.qrCode}`}
                        style={{ width: 100 }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <div style={{ height: 10 }}></div>
          <div className="printFooter">
            <div style={{ padding: 20 }}>
              <div style={{ height: 20, borderTop: '1px solid black' }}></div>

              <div style={newCustomGeneratedBillContainer}>
                This is a computer generated bill and if you found any issues
                contact your store owner or Vardaan support by sharing your
                invoice.
              </div>
              <div style={poweredbyContainer}>
                Powered by <span style={poweredbyText}> Vardaan</span>
              </div>
            </div>

            <div className="printGradient">
              <div
                style={{
                  height: 20,
                  width: '25%',
                  backgroundColor: primaryColor,
                  backgroundImage: `linear-gradient(to right, ${primaryColor} , white)`
                }}
              ></div>
              <div
                style={{
                  height: 20,
                  width: '40%',
                  backgroundColor: primaryColor,
                  backgroundImage: `linear-gradient(to right, ${secondaryColor} , white)`
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="printGradientForPrint"
        style={{ marginTop: '20px !important', paddingTop: '20px !important' }}
      >
        <div
          style={{
            height: 20,
            width: '25%',
            backgroundColor: primaryColor,
            backgroundImage: `linear-gradient(to right, ${primaryColor} , white)`
          }}
        ></div>
        <div
          style={{
            height: 20,
            width: '40%',
            backgroundColor: primaryColor,
            backgroundImage: `linear-gradient(to right, ${secondaryColor} , white)`
          }}
        ></div>
      </div>
    </div>
  );
};

export default NewInvoice;

const primaryColorText = {
  color: primaryColor
};

const secondaryColorText = {
  color: secondaryColor
};

const rows = {
  display: 'flex',
  justifyContent: 'space-between',
  flex: 1
};
const rowStart = {
  display: 'flex',
  flexDirection: 'column',
  flex: 3
};

const rowMiddle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 3
};

const rowEnd = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignContent: 'flex-end',
  alignItems: 'flex-end',
  flex: 3
};

const headertext = {
  padding: '2px',
  fontFamily: 'Montserrat'
};

const blackboldtext = {
  color: primaryColor,
  fontWeight: '600',
  fontSize: '14px'
};

const blackmediumtext = {
  color: primaryColor,
  fontWeight: '300',
  fontSize: '14px'
};

const storename = {
  color: primaryColor,
  fontWeight: '600',
  fontSize: '16px',
  fontFamily: 'Ubuntu'
};

const poweredbyText = {
  color: secondaryColor,
  fontWeight: 'bold',
  whiteSpace: 'pre'
};

const poweredbyContainer = {
  ...rows,
  padding: '10px 5px',
  justifyContent: 'center',
  color: primaryColor
};

const invoiceContainer = { ...headertext, ...blackboldtext };

const headerBlackMediumContainer = { ...headertext, ...blackmediumtext };

const headerStoreContainer = { ...headertext, ...storename };

// New Invoice Css
const newItemPadding = {
  padding: '5px 3px'
};

const newItemWidth = {
  width: '200px'
};
const hsnWidth = {
  width: '100px',
  textAlign: 'end'
};

const newPriceWidth = {
  width: '100px',
  textAlign: 'end'
};

const newQtyWidth = {
  width: '100px',
  textAlign: 'end'
};

const newTaxWidth = {
  width: '100px',
  textAlign: 'end'
};

const newAmountWidth = {
  width: '100px',
  textAlign: 'end'
};

const newItemPaddingContainer = {
  ...rows,
  ...newItemPadding,
  ...primaryColorText
};

const newSubTotalContainer = { ...rows, ...primaryColorText };

const newCustomGeneratedBillContainer = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  padding: '10px 5px',
  textAlign: 'center',
  color: primaryColor
};

const gstContainer = {
  ...headertext,
  color: primaryColor,
  fontWeight: '600',
  fontSize: '1.4rem',
  color: primaryColor
};
