import React from 'react';
import {
    Box,
    Grid,
    Typography,
    Container,
    Button,
    Paper

} from '@material-ui/core';
import InnerAppBar from '../../Header/innerAppBar';
import emptyCart from '../../../assets/images/cart.png';
import { navigateToHome } from '../../../utils/history';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import storeOfflineCloseImage from '../../../assets/images/GroupClose.png';



const EmptyCart = ({ userCartData, classes, storeData, isLoggedIn, deliveryOption, isMoreThan24, timerValue }) => {

    return (
        <div>
            <Container maxWidth="lg" className="cart-container">
                <InnerAppBar
                    title="Cart"
                    bgColor={true}
                    backspace={true}
                    userCartData={userCartData}
                />
                <Box
                    display="flex"
                    // mt={5}
                    className="empty-cart"
                >
                    <div className="columns3">
                        <Box my={12}>
                            <Grid className={classes.grid_image}>
                                <img className="cart-image" src={emptyCart} />
                            </Grid>
                            <Grid className={classes.grid_center}>
                                <Typography
                                    className={classes.text}
                                    variant="subtitle1"
                                >
                                    <Box fontWeight={600} fontSize={18} my={1}>
                                        Your cart is empty
                              </Box>
                                </Typography>
                            </Grid>
                            <Grid className={classes.grid_center}>
                                <Box
                                    variant="subtitle1"
                                    fontSize={15}
                                    className="empty"
                                >
                                    Looks like you have not added any items{' '}
                                </Box>
                            </Grid>
                            <Grid className={classes.grid_center}>
                                <Box
                                    variant="subtitle1"
                                    fontSize={15}
                                    className="empty"
                                >
                                    To your cart yet.{' '}
                                </Box>
                            </Grid>
                        </Box>

                        <Box className="home-btn">
                            <Grid item className={classes.grid}>
                                <Button
                                    onClick={() => navigateToHome()}
                                    className={classes.button}
                                    variant="contained"
                                    type="button"
                                >
                                    Go to Homepage
                            </Button>
                            </Grid>
                        </Box>
                    </div>

                    <div class="divider"></div>

                    <div className="columns4">
                        {/* BILL DETAILS START */}
                        <Typography
                            className={classes.titletext}
                            variant="subtitle1"
                        >
                            <Box fontWeight="fontWeightMedium">Bill Details</Box>
                        </Typography>
                        <Paper
                            className={classes.billDetailsPaper}
                            elevation={0}
                        >
                            <Grid className={classes.grid_flex_bill}>
                                <Grid item xs={12}>
                                    <Typography className={classes.label}>
                                        Total Items
                              </Typography>
                                </Grid>
                                <Grid item>
                                    <Box className={classes.bill_text}>
                                        &#8377;{Math.round(0 * 100) / 100}
                                    </Box>
                                </Grid>
                            </Grid>
                            {/* Delivery Charge */}
                            {isLoggedIn &&
                                storeData.storeData.onlineStore.delivery?.enabled &&
                                deliveryOption === 'delivery' && (
                                    <Grid className={classes.grid_flex_bill}>
                                        <Grid xs={12}>
                                            <Typography className={classes.label}>
                                                Delivery Charges
                                  </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                className={classes.bill_text}
                                                variant="subtitle1"
                                            >
                                                &#8377;0
                                                    </Typography>

                                        </Grid>
                                    </Grid>
                                )}

                            <Box
                                className={classes.grid_flex_bill_total}
                                borderBottom={[0, 0, 1, 1]}
                            >
                                <Grid xs={12}>
                                    <Box className={classes.label_total}>
                                        Total Price
                              </Box>
                                </Grid>
                                <Grid item>
                                    <Typography
                                        className={classes.bill_text_total}
                                        variant="subtitle1"
                                    >
                                        <Box fontWeight="fontWeightMedium">
                                            &#8377;{' '}
                                            {
                                                Math.round(
                                                    (0) * 100
                                                ) / 100
                                            }
                                        </Box>
                                    </Typography>
                                </Grid>
                            </Box>
                        </Paper>
                        {/* BILL DETAILS END */}

                        <Paper className={classes.item_paper} elevation={0}>
                            <Grid xs={12} className="payment">
                                <Grid style={{ paddingRight: 4, display: 'flex' }}>
                                    <ErrorOutlineIcon
                                        style={{ color: '#DBA719', fontSize: 20 }}
                                    />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography className={classes.paymentText}>
                                        Payment Method
                              </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.paymentText}>
                                        <Box fontWeight="fontWeightMedium">
                                            UPI/Cash
                                </Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>

                        {storeData.storeDayAvaibility ? (
                            <Grid item className={classes.grid}>
                                <Button
                                    className={classes.button}
                                    variant="contained"
                                    // onClick={() => handleCheckoutForLogin(false)}
                                    type="submit"
                                    disabled
                                >
                                    <ShoppingCartIcon className={classes.back_icon} />{' '}
                              Checkout{' '}
                                </Button>
                            </Grid>
                        ) : (
                                <Grid
                                    container
                                    className="store-offline-container-cart-screen"
                                >
                                    <Grid className="store-offline-image">
                                        <img
                                            className="banner-img"
                                            src={storeOfflineCloseImage}
                                            height={25}
                                            width={25}
                                            alt="Offline_image"
                                        />
                                    </Grid>
                                    <Grid className="store-offline-text">
                                        Temporarily store is not accepting new orders
                              <br />
                                        {storeData.storeData.onlineStore?.available &&
                                            (isMoreThan24 || timerValue) &&
                                            `The store re-opens in
                                      ${
                                            isMoreThan24
                                                ? `${isMoreThan24} days`
                                                : timerValue
                                            }`}
                                    </Grid>
                                </Grid>
                            )}
                    </div>
                </Box>
            </Container>
        </div>
    );
};

export default EmptyCart;
