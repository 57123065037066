import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Paper,
  TextField,
  Container,
  Button,
  CircularProgress,
  Slide,
  Backdrop,
  Tooltip
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import HelpIcon from '@material-ui/icons/Help';
import moment from 'moment';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { navigateTo, navigateToWithState } from '../../utils/history';
import { ADDRESS, LOGIN_ROUTE, HOME_ROUTE } from '../../utils/routesConstants';
import storeOfflineCloseImage from '../../assets/images/GroupClose.png';
import { getLocalStorageTokens } from '../../utils/tokensHelper';
import { useCartHook } from './hooks';
import ListItem from './components/listItem';
import DeliveryOptions from './components/deliveryOption';
import PaymentMethod from './components/paymentMethod';
import MainHeader from '../Header/mainHeader';
import InnerAppBar from '../Header/innerAppBar';
import { useStyles } from './style';
import './style.scss';
import LoginScreen from '../Login/Components/LoginScreen';
import Coupons from './components/coupons';
import AddressListSelection from './components/addressSelectionSlider';
import EmptyCart from './components/emptyCart';
import { Redirect } from 'react-router-dom';

const Cart = ({ userCartData, userData, storeData, payment }) => {
  const {
    addItemToCart,
    removeItemFromCart,
    cartCheckout,
    addUserNotes,
    activeCouponsList,
    initiatePayment
  } = useCartHook();
  const { isLoggedIn } = getLocalStorageTokens();

  const [deliveryOption, setDeliveryOption] = React.useState(
    storeData.storeData.onlineStore?.selfPickupEnabled ?? true
      ? window.localStorage.getItem('deliveryOption') || 'self-pickup'
      : 'delivery'
  );
  const [paymentOption, setPaymentOption] = React.useState(
    storeData.storeData.onlineStore?.codEnabled &&
      storeData.storeData.onlineStore?.onlinePaymentEnabled
      ? 'online' :
      storeData.storeData.onlineStore?.codEnabled ? 'cash' :
        storeData.storeData.onlineStore?.onlinePaymentEnabled ? 'online' :
          'cash'

  );

  const [addressId, setAddressId] = React.useState('');
  const [address, setAddress] = React.useState(null);
  const [selectedCoupon, setCoupon] = React.useState(null);
  const [discount, setDiscount] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const [notes, setNotes] = React.useState(userCartData.notes);
  const [isNotes, setIsNotes] = React.useState(
    userCartData.notes.length > 0 ? true : false
  );
  const [itemId, setitemId] = useState('');
  const [timer, setTimer] = useState('');
  const [isMoreThan24, setMoreThan24] = useState('');
  const [timerValue, setTimerValue] = useState('');

  const [loginSlide, setLoginSlide] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [showNavBar, setShowNavBar] = useState(true);
  const [couponSidebar, setCoupinSidebar] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      if (window.innerWidth) {
        setShowNavBar(false);
      } else {
        setShowNavBar(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      userData.user?.address.length > 0 &&
      userData.user?.address.length !== 1
    ) {
      let address = userData.user.address.filter((item) => {
        return item.default === true;
      });
      if (address.length > 0) {
        setAddress(address[0]);
        setAddressId(address[0]._id);
      } else {
        setAddressId(userData.user.address[0]._id);
        setAddress(userData.user.address[0]);
      }
    } else if (userData.user?.address.length === 1) {
      setAddressId(userData.user.address[0]._id);
      setAddress(userData.user.address[0]);
    }
  }, [userData.user]);

  useEffect(() => {
    if (userCartData.onAddItemLoader === false) {
      setitemId('');
    }
  }, [userCartData.onAddItemLoader]);

  useEffect(() => {
    setNotes(userCartData.notes);
  }, [userCartData.notes]);

  const handleClose = () => {
    setLoginSlide(false);
    setCoupinSidebar(false);
  };

  const setSelectedCoupon = (coupon) => {
    handleClose();
    setCoupon(coupon);
  };

  useEffect(() => {
    if (selectedCoupon && userCartData?.cartData?.length) {
      let _ttam = 0;
      var _discValue = 0;
      for (var i = 0; i < userCartData.cartData.length; i++) {
        var data = userCartData.cartData[i];
        var id = data._id;
        if (
          selectedCoupon.itemIds?.length === 0 ||
          selectedCoupon.itemIds.includes(id)
        ) {
          var price = data.price;
          if (data.selectedVariants && data.selectedVariants.length) {
            price = data.selectedVariants[0].sp;
            if (data.gst) price = price + (price * data.gst) / 100;
          } else if (data.priceWithGst) price = data.priceWithGst;
          _ttam += data.quantity * price;
        }
      }
      if (_ttam > 0) {
        if (selectedCoupon.isPercentage) {
          _discValue = (selectedCoupon.discountValue / 100) * _ttam;
        } else {
          _discValue =
            selectedCoupon.discountValue > _ttam
              ? _ttam
              : selectedCoupon.discountValue;
        }
        setDiscount(_discValue);
      }
    }
  }, [selectedCoupon, userCartData?.cartData]);

  const applyCouponHandler = () => {
    if (selectedCoupon) {
      setCoupon(null);
      setDiscount(0);
    } else {
      setCoupinSidebar(true);
      activeCouponsList();
    }
  };

  const handleCheckout = (e) => {
    e.preventDefault();
    if (!isLoggedIn) {
      if (width > 768) {
        setLoginSlide(true);
      } else {
        navigateTo(LOGIN_ROUTE);
      }
    } else {
      if (!deliveryOption) {
        toastr.error(`Select all the fields to continue`);
      } else if (deliveryOption === 'delivery' && !addressId) {
        // toastr.error(`Select delivery address`);
        navigateTo(ADDRESS);
      } else {
        let deliveryRs = 0;
        let packagingRs = 0;
        if (
          storeData.storeData.onlineStore?.additionalCharges &&
          storeData.storeData.onlineStore?.additionalCharges?.length
        ) {
          // need to implememnt
        } else {
          if (storeData.storeData.onlineStore?.delivery) {
            deliveryRs = storeData.storeData.onlineStore?.delivery?.enabled
              ? storeData.storeData.onlineStore?.delivery?.cost
              : 0;
          }
          if (storeData.storeData.onlineStore?.packaging) {
            packagingRs = storeData.storeData.onlineStore?.packaging?.enabled
              ? storeData.storeData.onlineStore?.packaging?.cost
              : 0;
          }
        }
        const addrId = deliveryOption === 'delivery' ? addressId : '';
        deliveryRs = deliveryOption === 'delivery' ? deliveryRs : 0;
        const cpn = selectedCoupon ? selectedCoupon.code : '';
        const cpnId = selectedCoupon ? selectedCoupon.couponId : '';
        const dicVal = discount;
        const paymentMethod =
          totalAmountTobePaid <= 30 ? 'cash' : paymentOption;
        const isOnlinePayment = paymentMethod === 'online';
        var checkout_data = {
          deliveryOption,
          addrId,
          notes,
          deliveryRs,
          packagingRs,
          totalPrice,
          totalSaving,
          totalTaxes,
          paymentMethod,
          appliedAdditionalCharges,
          cpn,
          cpnId,
          dicVal,
          isOnlinePayment,
          totalAmountTobePaid
        };

        if (isOnlinePayment) initiatePayment(checkout_data);
        else cartCheckout(checkout_data);
      }
    }
  };

  useEffect(() => {
    if (storeData.storeData.onlineStore?.available) {
      let currentDay = moment().format('dddd').toLowerCase().substr(0, 3);
      let days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
      let currentDayIdx = days.findIndex((ele) => {
        return ele === currentDay;
      });
      let finalDayIdx = currentDayIdx + 1;
      let currentSrtEpoch = moment();
      let tdySrtEpoch = moment.unix(
        storeData.storeData.onlineStore[days[currentDayIdx]].startEpoch
      );
      let tdySrtEpochHr = tdySrtEpoch.format('HH');
      let currentSrtEpochHr = currentSrtEpoch.format('HH');
      if (Number(currentSrtEpochHr) < Number(tdySrtEpochHr)) {
        finalDayIdx = currentDayIdx;
      }

      for (let i = finalDayIdx; i < days.length; ++i) {
        if (!storeData.storeData.onlineStore[days[i]].isAvailable) {
          ++finalDayIdx;
        } else {
          break;
        }
      }

      if (storeData.storeData.onlineStore[days[finalDayIdx]]) {
        let nextDayStartHrEpoch = moment.unix(
          storeData.storeData.onlineStore[days[finalDayIdx]].startEpoch
        );
        let nextDayStartHr = nextDayStartHrEpoch.format('HH');
        let nextDayStartMin = nextDayStartHrEpoch.format('mm');

        if (finalDayIdx - currentDayIdx > 1) {
          setMoreThan24(finalDayIdx - currentDayIdx);
        } else {
          const interval = setInterval(() => {
            let currentHr = currentSrtEpoch.format('HH');
            let currentMin = currentSrtEpoch.format('mm');
            setTimer(currentSrtEpoch + 1);

            let diffHrs = 24 - Number(currentHr) + Number(nextDayStartHr);
            let diffMin = Math.abs(
              Number(nextDayStartMin) - Number(currentMin)
            );
            if (diffMin > 60) {
              diffHrs += diffMin % 60;
              diffMin -= (diffMin % 60) * 60;
            }
            setTimerValue(
              `${diffHrs}:${diffMin < 10 ? `0${diffMin}` : diffMin}:00`
            );
            if (timer === nextDayStartHrEpoch) {
              clearInterval(interval);
            }
          }, 1000);
          return () => {
            clearInterval(interval);
          };
        }
      }
    }
  }, []);

  const addItem = (item) => {
    if (item.variants?.length && item.selectedVariants?.length)
      setitemId(item._id + item.selectedVariants[0]._id);
    else setitemId(item._id);
    addItemToCart(item);
  };

  const removeItem = (item) => {
    if (item.variants?.length && item.selectedVariants?.length)
      setitemId(item._id + item.selectedVariants[0]._id);
    else setitemId(item._id);
    removeItemFromCart(item);
  };

  const handleDeliveryOption = (event) => {
    if (event.target.value === 'delivery') {
      if (userData?.user?.address.length > 0) {
        setDeliveryOption(event.target.value);
        window.localStorage.setItem('deliveryOption', 'delivery');
      } else {
        setDeliveryOption(event.target.value);

        // navigateTo(ADDRESS);

        // toastr.info(`Please Add Address from your Account Section.`);
      }
    } else {
      setIsOpen(false);
      setDeliveryOption(event.target.value);
      window.localStorage.setItem('deliveryOption', 'self-pickup');
    }
  };

  const handleAddress = (event) => {
    setAddressId(event.target.value);
    let address = userData.user.address.filter((item) => {
      return item._id === event.target.value;
    });
    setAddress(address[0]);
    setIsOpen(false);
  };
  const handlePaymentOption = (event) => {
    setPaymentOption(event.target.value);
  };
  const handleClick = () => {
    setIsNotes(!isNotes);
  };
  var isMobile = true;
  if (width > 840) isMobile = false;
  var totalPrice = 0;
  var totalSaving = 0;
  var totalTaxes = 0;
  var totalAmountTobePaid = 0;
  var appliedAdditionalCharges = [];

  const handleAddAddressClick = () => {
    navigateTo(ADDRESS);
  };

  const handleEditClick = (data) => {
    navigateToWithState(ADDRESS, data);
  };

  const additionalChargesCalc = (additionalChargesList) => {
    appliedAdditionalCharges = [];
    var totalAdditionalCharges = 0;
    for (var i = 0; i < additionalChargesList.length; i++) {
      if (
        deliveryOption === 'self-pickup' &&
        additionalChargesList[i].name === 'Delivery Charges'
      ) {
        continue;
      }

      if (additionalChargesList[i].enabled)
        if (additionalChargesList[i].upperCap === 0) {
          totalAdditionalCharges =
            totalAdditionalCharges + additionalChargesList[i].cost;
          appliedAdditionalCharges.push(additionalChargesList[i]);
        } else if (totalPrice <= additionalChargesList[i].upperCap) {
          totalAdditionalCharges =
            totalAdditionalCharges + additionalChargesList[i].cost;
          appliedAdditionalCharges.push(additionalChargesList[i]);
        }
    }
    return totalAdditionalCharges;
  };

  const totalPriceCalc = () => {
    var amount = 0;

    if (storeData.storeData.onlineStore?.additionalCharges?.length > 0)
      amount = additionalChargesCalc(
        storeData.storeData.onlineStore?.additionalCharges ?? []
      );
    else {
      if (
        deliveryOption === 'delivery' &&
        storeData.storeData.onlineStore?.delivery?.enabled
      ) {
        amount = amount + storeData.storeData.onlineStore?.delivery?.cost;
      }
      if (storeData.storeData.onlineStore?.packaging?.enabled) {
        amount = amount + storeData.storeData.onlineStore?.packaging?.cost;
      }
    }
    totalAmountTobePaid =
      Math.round((totalPrice + totalTaxes - discount + amount) * 100) / 100;
    return totalAmountTobePaid;
  };

  const storeId = window.sessionStorage.getItem('storeId');
  // console.log(storeId, 'storeIdstoreId')
  if (storeId === null || storeId === undefined)
    return <Redirect to={HOME_ROUTE} />

  return (
    <Box className="main-div-in" minHeight="100vh">
      <MainHeader />
      {userCartData.loader ? (
        <div className="home-loader">
          <CircularProgress />
        </div>
      ) : (
          <div>
            <form onSubmit={handleCheckout}>
              <AddressListSelection
                classes={classes}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                userData={userData}
                addressId={addressId}
                handleEditClick={handleEditClick}
                handleAddress={handleAddress}
                handleAddAddressClick={handleAddAddressClick}
              />

              <Box className={classes.box}>
                {userCartData.cartData?.length > 0 ? (
                  // FIRST MAIN DIV
                  <div>
                    {/* FIRST ROW START */}
                    <Container maxWidth="lg" className="cart-container">
                      <InnerAppBar
                        title="Cart"
                        bgColor={true}
                        backspace={true}
                        userCartData={userCartData}
                      />
                      <Box
                        display="flex"
                        mt={[3, 3, 0, 0]}
                        className="cart-items"
                      >
                        <div className="columns1">
                          <Typography className={classes.titletext}>
                            <Box fontWeight="fontWeightMedium">Items</Box>
                          </Typography>
                          <Paper className={classes.item_paper} elevation={0}>
                            <Grid container>
                              {userCartData.cartData.map((data, index) => {
                                const mrp =
                                  data.selectedVariants &&
                                    data.selectedVariants.length
                                    ? data.selectedVariants[0].mrp
                                    : data.mrp;
                                const price =
                                  data.selectedVariants &&
                                    data.selectedVariants.length
                                    ? data.selectedVariants[0].sp
                                    : data.price;

                                var id = data._id;
                                if (
                                  data.variants?.length &&
                                  data.selectedVariants?.length
                                )
                                  id = data._id + data.selectedVariants[0]._id;

                                if (mrp)
                                  totalSaving += data.quantity * (mrp - price);
                                if (data.priceWithGst)
                                  totalTaxes +=
                                    (data.priceWithGst - data.price) *
                                    data.quantity;
                                totalPrice += data.quantity * price;

                                return (
                                  <ListItem
                                    classes={classes}
                                    data={data}
                                    removeItem={removeItem}
                                    addItem={addItem}
                                    mrp={mrp}
                                    price={price}
                                    id={id}
                                    itemId={itemId}
                                    onAddItemLoader={userCartData.onAddItemLoader}
                                  />
                                );
                              })}
                            </Grid>
                          </Paper>
                          {userData.user ? (
                            <div>
                              <DeliveryOptions
                                onlineStore={storeData.storeData.onlineStore}
                                classes={classes}
                                deliveryOption={deliveryOption}
                                handleDeliveryOption={handleDeliveryOption}
                              />
                              {/* DELIVERY OPTION END */}
                              {/* DELIVERY ADDRESS START */}
                              {deliveryOption === 'delivery' ? (
                                <div>
                                  <Grid container justify="space-between">
                                    <Grid item>
                                      <Typography className={classes.text}>
                                        <Box fontWeight="fontWeightMedium">
                                          Delivery Address
                                      </Box>
                                      </Typography>
                                    </Grid>
                                    {userData.user?.address.length === 0 ? (
                                      <Grid
                                        onClick={handleAddAddressClick}
                                        item
                                        className={classes.addAddressGrid}
                                      >
                                        <AddCircleIcon
                                          color="primary"
                                          className={classes.addressIcon}
                                        />
                                        <Typography
                                          className={classes.notes_text}
                                        >
                                          Add Address
                                      </Typography>
                                      </Grid>
                                    ) : null}
                                  </Grid>
                                  {address ? (
                                    <Paper
                                      elevation={0}
                                      className={classes.delivery_address_paper}
                                    >
                                      <Grid container direction="column">
                                        <Grid
                                          container
                                          item
                                          justify="space-between"
                                          className={
                                            classes.delivery_address_grid
                                          }
                                          direction="row"
                                        >
                                          <Grid item xs={9}>
                                            <Typography
                                              style={{
                                                whiteSpace: 'nowrap',
                                                width: '100%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis'
                                              }}
                                            >
                                              {address.addName}
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={3}
                                            container
                                            justify="flex-end"
                                          >
                                            <Box
                                              display="flex"
                                              alignItems="center"
                                              style={{ cursor: 'pointer' }}
                                              onClick={() => setIsOpen(true)}
                                              className={classes.change_address}
                                            >
                                              <Typography
                                                className={
                                                  classes.change_address_text
                                                }
                                              >
                                                Change
                                            </Typography>{' '}
                                              <ChevronRightIcon
                                                style={{ fontSize: 16 }}
                                                color="primary"
                                              />
                                            </Box>
                                          </Grid>
                                        </Grid>
                                        <Grid item>
                                          <Typography className={classes.address}>
                                            {address.building}, {address.street},{' '}
                                            {address.city}, {address.state},{' '}
                                            {address.pincode}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Paper>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                          ) : (
                              <div></div>
                            )}{' '}
                          {/* FIRST ROW END */}
                          {/* DELIVERY ADDRESS END */}
                        </div>

                        <div class="divider"></div>
                        <div className="columns2">
                          {/* BILL DETAILS START */}
                          <Typography
                            className={classes.titletext}
                            variant="subtitle1"
                          >
                            <Box fontWeight="fontWeightMedium">Bill Details</Box>
                          </Typography>
                          <Paper
                            className={classes.billDetailsPaper}
                            elevation={0}
                          >
                            <Grid className={classes.grid_flex_bill}>
                              <Grid item xs={12}>
                                <Typography className={classes.label}>
                                  Total Items
                              </Typography>
                              </Grid>
                              <Grid item>
                                <Box
                                  className={classes.bill_text}
                                  fontWeight="fontWeightMedium"
                                  display="flex"
                                >
                                  <Box mr="3px"> &#8377; </Box>
                                  {Math.round(totalPrice * 100) / 100}
                                </Box>
                              </Grid>
                            </Grid>
                            {Math.round(totalSaving * 100) / 100 > 0 && (
                              <Grid className={classes.grid_flex_bill}>
                                <Grid xs={12}>
                                  <Typography className={classes.label}>
                                    Your Savings
                                </Typography>
                                </Grid>
                                <Grid item>
                                  <Box
                                    fontWeight="fontWeightMedium"
                                    className={classes.price_text}
                                    display="flex"
                                  >
                                    <Box mr="3px"> &#8377; </Box>
                                    {Math.round(totalSaving * 100) / 100}
                                  </Box>
                                </Grid>
                              </Grid>
                            )}

                            {/* Delivery Charge  and Packaging And Gst Charge  */}
                            {isLoggedIn ? (
                              storeData.storeData.onlineStore?.additionalCharges
                                ?.length ? (
                                  <div>
                                    {storeData.storeData.onlineStore?.additionalCharges.map(
                                      (additionalCharge) => {
                                        if (!additionalCharge?.enabled)
                                          return <div />;
                                        if (
                                          deliveryOption === 'self-pickup' &&
                                          additionalCharge.name ===
                                          'Delivery Charges'
                                        )
                                          return <div />;

                                        return (
                                          <Grid className={classes.grid_flex_bill}>
                                            <Grid xs={12}>
                                              <Typography className={classes.label}>
                                                {additionalCharge.name}
                                              </Typography>
                                            </Grid>
                                            <Grid item>
                                              {additionalCharge.cost === 0 ||
                                                (additionalCharge.upperCap !== 0 &&
                                                  totalPrice >=
                                                  additionalCharge.upperCap) ? (
                                                  <Box
                                                    fontWeight="fontWeightMedium"
                                                    className={classes.bill_text}
                                                    variant="subtitle1"
                                                  >
                                                    Free
                                                    {additionalCharge.upperCap >
                                                      0 && (
                                                        <Tooltip
                                                          title={`${additionalCharge.name} are FREE on shopping above Rs.${additionalCharge.upperCap}`}
                                                          placement="top"
                                                        >
                                                          <StarIcon
                                                            style={{
                                                              fontSize: 12,
                                                              position: 'absolute',
                                                              color: 'green'
                                                            }}
                                                          />
                                                        </Tooltip>
                                                      )}
                                                  </Box>
                                                ) : (
                                                  <Box
                                                    fontWeight="fontWeightMedium"
                                                    display="flex"
                                                    className={classes.bill_text}
                                                    variant="subtitle1"
                                                  >
                                                    <Box mr="3px"> &#8377;</Box>
                                                    {Math.round(
                                                      additionalCharge?.cost * 100
                                                    ) / 100}
                                                    {additionalCharge.upperCap >
                                                      0 && (
                                                        <Tooltip
                                                          title={`Do shoppping for Rs.${additionalCharge.upperCap} to avoid ${additionalCharge.name}`}
                                                          placement="top"
                                                        >
                                                          <HelpIcon
                                                            style={{ fontSize: 12 }}
                                                          />
                                                        </Tooltip>
                                                      )}
                                                  </Box>
                                                )}
                                            </Grid>
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    {storeData.storeData.onlineStore?.delivery
                                      ?.enabled &&
                                      deliveryOption === 'delivery' && (
                                        <Grid className={classes.grid_flex_bill}>
                                          <Grid xs={12}>
                                            <Typography className={classes.label}>
                                              Delivery Charges
                                        </Typography>
                                          </Grid>
                                          <Grid item>
                                            {storeData.storeData.onlineStore
                                              .delivery?.cost === 0 ? (
                                                <Typography
                                                  className={classes.bill_text}
                                                  variant="subtitle1"
                                                >
                                                  Free
                                                </Typography>
                                              ) : (
                                                <Box
                                                  className={classes.bill_text}
                                                  variant="subtitle1"
                                                  display="flex"
                                                >
                                                  <Box mr="3px"> &#8377;</Box>
                                                  {Math.round(
                                                    storeData.storeData.onlineStore
                                                      ?.delivery?.cost * 100
                                                  ) / 100}
                                                </Box>
                                              )}
                                          </Grid>
                                        </Grid>
                                      )}

                                    {storeData.storeData.onlineStore?.packaging
                                      ?.enabled ? (
                                        <div>
                                          <Grid className={classes.grid_flex_bill}>
                                            <Grid xs={12}>
                                              <Typography className={classes.label}>
                                                Packing Charges
                                        </Typography>
                                            </Grid>
                                            <Grid item>
                                              {storeData.storeData.onlineStore
                                                .packaging?.cost === 0 ? (
                                                  <Box
                                                    fontWeight="fontWeightMedium"
                                                    className={classes.bill_text}
                                                    variant="subtitle1"
                                                  >
                                                    Free
                                                  </Box>
                                                ) : (
                                                  <Box
                                                    fontWeight="fontWeightMedium"
                                                    display="flex"
                                                    className={classes.bill_text}
                                                    variant="subtitle1"
                                                  >
                                                    <Box mr="3px"> &#8377;</Box>
                                                    {Math.round(
                                                      storeData.storeData.onlineStore
                                                        ?.packaging?.cost * 100
                                                    ) / 100}
                                                  </Box>
                                                )}
                                            </Grid>
                                          </Grid>
                                        </div>
                                      ) : (
                                        <div />
                                      )}
                                  </div>
                                )
                            ) : (
                                <div />
                              )}

                            {/* Taxes goes here */}
                            {Math.round(totalTaxes * 100) / 100 > 0 && (
                              <Grid className={classes.grid_flex_bill}>
                                <Grid xs={12}>
                                  <Typography className={classes.label}>
                                    Taxes
                                </Typography>
                                </Grid>
                                <Grid item>
                                  <Box
                                    display="flex"
                                    fontWeight="fontWeightMedium"
                                    className={classes.bill_text}
                                  >
                                    <Box mr="3px"> &#8377; </Box>{' '}
                                    {Math.round(totalTaxes * 100) / 100}
                                  </Box>
                                </Grid>
                              </Grid>
                            )}

                            {/* Service fees */}
                            {/* {paymentOption === 'online' ? (
                              <Grid className={classes.grid_flex_bill}>
                                <Grid item xs={12}>
                                  <Typography className={classes.label}>
                                    Service fees
                                </Typography>
                                </Grid>
                                <Grid item>
                                  <Box
                                    className={classes.bill_text}
                                    fontWeight="fontWeightMedium"
                                    display="flex"
                                  >
                                    <Box mr="3px"> &#8377; </Box>
                                    {
                                      totalPriceCalc().toFixed(2)}
                                  </Box>
                                </Grid>
                              </Grid>
                            ) : null} */}

                            {isLoggedIn && (
                              <Box
                                className={classes.grid_flex_bill_total}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Box>
                                  <Typography>
                                    {selectedCoupon
                                      ? `${selectedCoupon.code}`
                                      : 'Apply Coupon'}
                                  </Typography>
                                  {selectedCoupon && (
                                    <Typography
                                      style={{ fontSize: 13, color: '#FFA500' }}
                                    >
                                      You have saved additional Rs.
                                      {discount.toFixed(2)}
                                    </Typography>
                                  )}
                                </Box>

                                <Button
                                  onClick={applyCouponHandler}
                                  variant="outlined"
                                  className="apply-button"
                                >
                                  {selectedCoupon ? 'Remove' : 'Apply'}
                                </Button>
                              </Box>
                            )}

                            <Box
                              className={classes.grid_flex_bill_total}
                              borderBottom={[0, 0, 1, 1]}
                            >
                              <Grid xs={12}>
                                <Box className={classes.label_total}>
                                  Total Price
                              </Box>
                              </Grid>
                              <Grid item>
                                <Box
                                  className={classes.bill_text_total}
                                  variant="subtitle1"
                                  fontWeight="fontWeightMedium"
                                >
                                  {isLoggedIn ? (
                                    <Box
                                      fontWeight="fontWeightMedium"
                                      display="flex"
                                    >
                                      <Box pr="3px">&#8377; </Box>
                                      {/* {paymentOption === 'online'
                                        ? (
                                          totalAmountTobePaid +
                                          Math.round(
                                            totalAmountTobePaid
                                          ) /
                                          100
                                        ).toFixed(2)
                                        : */}
                                      {totalPriceCalc()}

                                    </Box>
                                  ) : (
                                      <Box fontWeight="fontWeightMedium">
                                        &#8377;
                                        {Math.round(
                                          (totalPrice + totalTaxes) * 100
                                        ) / 100}
                                      </Box>
                                    )}
                                </Box>
                              </Grid>
                            </Box>
                          </Paper>
                          {/* BILL DETAILS END */}

                          <Grid className={classes.notes_grid}>
                            <Grid xs={11}>
                              {isNotes ? (
                                <Typography>
                                  <Box
                                    style={{
                                      color: 'rgb(51,51,51)',
                                      fontSize: 15
                                    }}
                                    fontWeight="fontWeightMedium"
                                  >
                                    Provide instructions
                                </Box>
                                </Typography>
                              ) : null}
                            </Grid>
                            <Grid
                              onClick={handleClick}
                              items
                              xs={1}
                              className={classes.icon_flex}
                              item
                            >
                              <Grid
                                item
                                style={{ display: 'flex', paddingRight: 3 }}
                              >
                                {isNotes ? (
                                  <RemoveCircleOutlineIcon
                                    className={classes.icon}
                                  />
                                ) : (
                                    <AddCircleOutlineIcon
                                      className={classes.icon}
                                    />
                                  )}
                              </Grid>
                              <Grid
                                item
                                style={{ display: 'flex', alignItems: 'center' }}
                                onClick={() => {
                                  if (isNotes) addUserNotes('');
                                }}
                              >
                                <Typography className={classes.notes_text}>
                                  {isNotes ? 'Remove' : 'Instructions'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          {isNotes ? (
                            <Paper
                              className={classes.text_area_paper}
                              elevation={0}
                            >
                              <TextField
                                rows={4}
                                className={classes.text_area}
                                placeholder="Type your instructions"
                                multiline
                                variant="outlined"
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.cssLabel,
                                    multiline: classes.inputField
                                  }
                                }}
                                onChange={(e) => setNotes(e.target.value)}
                                onBlur={() => addUserNotes(notes)}
                                value={notes}
                              />
                            </Paper>
                          ) : null}
                          <PaymentMethod
                            classes={classes}
                            onlineStore={storeData.storeData.onlineStore}
                            handlePaymentOption={handlePaymentOption}
                            paymentOption={paymentOption}
                            orderAmount={totalPriceCalc()}
                          />
                          {storeData.storeDayAvaibility ? (
                            <Grid item className={classes.grid}>
                              <Button
                                className={classes.button}
                                variant="contained"
                                type="submit"
                                disabled={payment.initiatePaymentLoader}
                              >
                                {payment.initiatePaymentLoader ? (
                                  <CircularProgress />
                                ) : (
                                    <>
                                      {' '}
                                      <ShoppingCartIcon
                                        className={classes.back_icon}
                                      />
                                      {paymentOption === 'online'
                                        ? 'CHECKOUT'
                                        : 'PLACE ORDER'}
                                    </>
                                  )}
                              </Button>
                            </Grid>
                          ) : (
                              <Grid
                                container
                                className="store-offline-container-cart-screen"
                              >
                                <Grid className="store-offline-image">
                                  <img
                                    className="banner-img"
                                    src={storeOfflineCloseImage}
                                    height={25}
                                    width={25}
                                    alt="Offline_image"
                                  />
                                </Grid>
                                <Grid className="store-offline-text">
                                  Temporarily store is not accepting new orders
                              <br />
                                  {storeData.storeData.onlineStore?.available &&
                                    (isMoreThan24 || timerValue) &&
                                    `The store re-opens in
                                  ${
                                    isMoreThan24
                                      ? `${isMoreThan24} days`
                                      : timerValue
                                    }`}
                                </Grid>
                              </Grid>
                            )}
                        </div>
                      </Box>
                      <Backdrop
                        open={couponSidebar}
                        className={classes.backdrop}
                      //  onClick={handleClose}
                      >
                        {isMobile ? (
                          <Slide
                            direction="up"
                            in={couponSidebar}
                            mountOnEnter
                            unmountOnExit
                          >
                            <Box elevation={4} className="slideLoginMobile">
                              <Coupons
                                handleClose={handleClose}
                                isMobile={isMobile}
                                userCartData={userCartData}
                                callback={setSelectedCoupon}
                              />
                            </Box>
                          </Slide>
                        ) : (
                            <Slide
                              direction="left"
                              in={couponSidebar}
                              mountOnEnter
                              unmountOnExit
                            >
                              <Box elevation={4} className="slideLogin">
                                <Coupons
                                  handleClose={handleClose}
                                  isMobile={isMobile}
                                  userCartData={userCartData}
                                  callback={setSelectedCoupon}
                                />
                              </Box>
                            </Slide>
                          )}
                      </Backdrop>

                      <Backdrop open={loginSlide} className={classes.backdrop}>
                        <Slide
                          direction="left"
                          in={loginSlide}
                          mountOnEnter
                          unmountOnExit
                        >
                          <Box elevation={4} className="slideLogin">
                            <LoginScreen
                              showNavBar={showNavBar}
                              handleClose={handleClose}
                            />
                          </Box>
                        </Slide>
                      </Backdrop>
                    </Container>
                  </div>
                ) : (
                    // END MAIN DIV //
                    <EmptyCart
                      userCartData={userCartData}
                      classes={classes}
                      totalPrice={totalPrice}
                      totalSaving={totalSaving}
                      storeData={storeData}
                      isLoggedIn={isLoggedIn}
                      deliveryOption={deliveryOption}
                      isMoreThan24={isMoreThan24}
                      timerValue={timerValue}
                    />
                  )}
              </Box>
            </form>
          </div>
        )}
    </Box>
  );
};

const mapStateToProps = ({ userCartData, userData, storeData, payment }) => ({
  userCartData,
  userData,
  storeData,
  payment
});

export default connect(mapStateToProps)(Cart);
