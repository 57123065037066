import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  //appbar.js
  back_icon: {
    color: 'white',
    padding: 0
  },
  text_flex: {
    //flex: 1,
    //justifyContent: 'space-between',
    //display: 'flex',
    fontSize: 17
  },
  cart_icon: {
    color: 'grey',
    justify: 'space-between'
  },
  boxLeft: {
    display: 'flex',
    flexDirection: 'row'
  },
  routeText: {
    paddingLeft: 10,
    fontWeight: theme.typography.fontWeightMedium
  },
  boxRight: {
    display: 'flex',
    alignSelf: 'center',
    cursor: 'pointer'
  },

  //image container
  image: {
    width: '85px',
    maxHeight: '85px',
    borderRadius: '8px',
    backgroundColor: '#F4F4F4'
  },
  createIcon: {
    color: ' rgb(116, 101, 203) ',
    fontSize: 14,
    alignSelf: 'center'
  },
  editText: {
    color: 'black',
    marginLeft: '15%',
    fontSize: 18
  },
  imageUpload: {
    backgroundColor: 'transparent'
  },
  imageButton: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    backgroundColor: 'transparent',
    boxShadow: 0,
    color: 'black',
    padding: 0,
    fontSize: 21,
    marginBottom: 6,
    marginLeft: 5,
    marginTop: 10,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    alignSelf: 'center'
  },
  imageFileContainer: {
    boxShadow: 0,
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    width: 100
  },

  //account details
  detailsContainer: {
    backgroundColor: 'white',
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 10,
    paddingBottom: 25,
    marginTop: 20
  },
  gridItem: {
    // paddingTop: 5,
    //paddingBottom: 7
  },
  label: {
    fontSize: 14,
    color: 'rgb(51,51,51)',
    marginBottom: 5,
    marginTop: 10
  },
  inputField: {
    borderRadius: 5,
    borderColor: 'rgb(235,235,235)',
    borderWidth: 2,
    fontSize: 14
  },

  autoCompleteDropDownBox: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 32,
    borderRadius: 5
  },

  //address
  addressHeading: {
    // paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBotton: 5
  },
  addressHeadingText: {
    fontWeight: '500',
    fontSize: 14
  },
  addAddress: {
    color: 'rgb(92,77,177)',
    fontSize: 14,
    fontWeight: '500',
    textTransform: 'none',
    borderwidth: 'none'
  },
  plusIcon: {
    color: 'rgb(92,77,177)',
    marginRight: 7,
    fontSize: 20,
    fontWeight: '500'
  },
  addAddressRow: {
    alignItems: 'center',
    paddingLeft: 5,
    paddingRight: 5
  },
  addressContainer: {
    //paddingLeft: 10,
    paddingTop: 5,
    backgroundColor: 'transparent'
  },
  addressGridItem: {
    // marginBottom: 10,
    // marginTop: 10,
    //backgroundColor: 'pink'
  },
  addressHead: {
    // justifyContent: 'space-between',
    // paddingRight: 10,
    // display: 'flex',
    borderColor: '#EBEBEB'
    // alignItems: 'center',
    // marginLeft: 10
  },
  address: {
    // paddingTop: 5,
    // paddingLeft: 10,
    color: 'rgb(77,77,77)',
    // paddingBottom: 10,
    fontSize: 14
  },
  addressName: {
    fontWeight: '500',
    fontSize: 15,
    marginTop: 3,
    marginBottom: 3,
    color: 'rgb(51,51,51)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    maxWidth: 150
    // alignSelf: 'center'
  },

  //logout
  logoutContainer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 0,
    backgroundColor: 'transparent'
  },
  logoutButton: {
    borderWidth: 1.5,
    borderRadius: 5,
    backgroundColor: 'white',
    textTransform: 'none'
  },

  saveButton: {
    borderWidth: 1.5,
    borderRadius: 5,
    textTransform: 'none',
    color: 'white',
    backgroundColor: '#5C4DB1',
    minWidth: '100%',
    height: 40,
    fontSize: 16,
    outline: 'none'
  },

  //Style for Address Screen
  AddressContainer: {
    //height: '45%',
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: 'white'
  },
  resize: {
    fontSize: 14,
    padding: 10
  },
  dropDownInput: {
    fontSize: 14,
    padding: 10
  },
  saveContainer: {
    marginTop: 10,
    padding: 20
  },
  defaultAddressContainer: {
    alignItems: 'center',
    paddingTop: 15,
    justifyContent: 'flex-start'
  },
  defaultAddressText: {
    fontWeight: '500',
    fontSize: 14,
    color: 'rgb(51,51,51)',
    marginTop: 10
  },
  stateSelect: {
    backgroundColor: 'white'
  },
  error: {
    color: 'red',
    fontSize: 12,
    paddingTop: 3
  },
  badgeIcon: {
    left: 5,
    height: 17,
    lineHeight: 0.8,
    minWidth: 17
  },
  dropdownContainer: {
    borderStyle: 'solid',
    borderRadius: 5,
    borderColor: 'rgb(235,235,235)',
    borderWidth: 2
  },
  dropdownIcon: {
    width: '1.5em',
    backgroundColor: 'rgb(237,237,237)',
    height: 'auto'
  },
  avatar: {
    backgroundColor: 'rgb(92, 77, 177)',
    // marginTop: '40px',
    borderRadius: '50%',
    width: '70px !important',
    height: '70px',
    textAlign: 'center',
    color: 'white',
    textTransform: 'upperCase',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex !important',
    marginLeft: '0',
    marginRight: '0',
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },

  rootBgColor: {
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'transparent'
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'white'
    }
  },
  cardBgColor: {
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'white'
    },
    [theme.breakpoints.up('md')]: {
      backgroundColor: 'transparent'
    }
  },
  containerPadding: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0
    }
  }
}));
