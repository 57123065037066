const { makeStyles } = require('@material-ui/core');

export const useStyles = makeStyles(theme => ({
  //appbar.js
  back_icon: {
    color: 'white',
  },
  text_flex: {
    flex: 1,
  },
  cart_icon: {
    color: 'white',
    justify: 'space-between',
  },

  //cart.js
  box: {
    paddingBottom: theme.spacing(8),
  },
  text: {
    padding: '12px 15px 6px 15px',
    margin: 'auto',
    color: 'rgb(51,51,51)',
  },
  titletext: {
    padding: '12px 15px 6px 15px',
    color: 'rgb(51,51,51)',
  },
  item_paper: {
    padding: '8px 15px 10px 15px',
  },
  text_area_paper: {
    padding: '10px 15px 10px 10px',
    marginBottom: 15,
  },
  text_area: {
    width: '100%',
    height: '200%',
  },
  cost_text: {
    textDecorationLine: 'line-through',
    fontSize: 13,
    color: 'rgb(119,119,119)',
    paddingRight: 6,
  },
  cssLabel: {
    borderColor: 'white !important',
  },
  discount_text: {
    color: '#DBA719',
    fontSize: 12,
  },
  radio_items: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '2px solid rgb(235,235,235)',
  },
  radio: {
    '&$checked': {
      color: '#5C4DB1',
    },
  },
  checked: {
    '&, & + $label': {
      fontWeight: 500,
    },
  },
  grid: {
    paddingTop: '4%',
    paddingBottom: '4%',
    justifyContent: 'center',
    display: 'flex',
  },
  del_text: {
    color: '#DBA719',
    fontSize: 15,
    padding: '6px 7px 6px 0px',
    fontWeight: 500,
    paddingRight: 15,
  },
  bill_text: {
    fontSize: 14,
  },
  bill_text_total: {
    fontSize: 15,
  },
  price_text: {
    color: '#DBA719',
  },
  notes_grid: {
    padding: '10px 15px 4px 15px',
    // margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    color: '#5C4DB1',
  },
  icon: {
    paddingBottom: '20%',
    fontSize: 14,
  },
  icon_flex: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  notes_text: {
    width: 'max-content',
    fontSize: 14,
    color: '#5C4DB1',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    width: '93%',
    background: '#5C4DB1',
    color: 'white',
    textTransform: 'none',
    fontSize: 18,
  },
  grid_flex: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 3,
    paddingTop: 3,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  grid_flex_item: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 3,
    justifyContent: 'space-between',
  },
  grid_flex2: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    textAlign: 'right',
    paddingTop: 14,
  },
  grid_image: {
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 40,
  },
  grid_center: {
    display: 'flex',
    justifyContent: 'center',
  },

  goToHomeButton: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
  },
  text_common: {
    color: 'rgb(51,51,51)',
    paddingBottom: 7,
    fontSize: 15,
    flexWrap: 'wrap',
    overflowWrap: 'wrap',
  },
  quantity: {
    color: '#333333',
    margin: 'auto',
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    color: '#333333',
  },
  label_total: {
    fontSize: 15,
    fontWeight: 500,
  },
  deliveryDetailsPaper: {
    padding: '8px 1px 10px 15px',
  },
  billDetailsPaper: {
    padding: '8px 15px 5px 15px',
  },
  grid_flex_bill: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 6,
    paddingTop: 4,
  },
  grid_flex_bill_total: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 6,
    paddingTop: 10,
    paddingRight: 1,
    margin: '10px 0px',
    borderTop: '1px solid rgb(235,235,235)',
    borderColor: 'rgb(235,235,235)',
  },
  text_bold: {
    fontSize: 15,
    fontWeight: 500,
    padding: '2px 0px 6px 15px',
  },
  inputField: {
    padding: '5px 15px 5px 5px',
    borderWidth: '1px',
    borderColor: 'rgb(219 167 25)',
    borderStyle: 'solid',
  },
  item_paper_payment: {
    padding: '0px 10px 5px 15px',
  },
  addAddressGrid: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    padding: '12px 15px 4px 0px',
  },
  addressIcon: {
    paddingRight: 5,
    fontSize: 22,
    paddingBottom: 3,
  },
  delivery_address_paper: {
    padding: '10px 1px 10px 15px',
  },
  delivery_address_grid: {
    fontSize: 13,
    borderBottom: '2px solid rgb(235,235,235)',
    paddingBottom: 8,
  },
  change_address: {
    padding: '0px 20px 0px 0px',
  },
  change_address_text: {
    fontSize: 13,
    color: theme.palette.primary.main,
  },
  address: {
    fontSize: 13,
    color: 'rgb(77,77,77)',
    paddingTop: 10,
    paddingRight: 15,
  },
  addressName: {
    fontSize: 14,
    paddingLeft: 20,
    color: 'rgb(119,119,119)',
    paddingBottom: 7,
  },
  grid_flex_select_address: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 10,
    paddingTop: 3,
    paddingRight: 10,
  },
  address_name_row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    height: '100%',
    overflow: 'hidden',
  },
  stopScrolling: {
    height: '100%',
    overflow: 'hidden',
  },
  otpLabel: {
    fontSize: 15,
    color: 'rgb(51,51,51)',
    marginBottom: 11,
    fontWeight: 500,
  },
  loginTitle: {
    fontSize: 17,
  },
  grid_flex_login: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 10,
    paddingTop: 3,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 4,
  },
  payment_grid_container: {
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  payment_grid_item1: {
    display: 'flex',
    alignItems: 'center',
  },
  text_payment: {
    fontSize: 15,
  },
  text_payment_right: {
    fontSize: 15,
    fontWeight: 500,
  },

  grid_flex_item_exp: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 10,
    paddingTop: 3,
    justifyContent: 'space-between',
  },
  itemNameContainer: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  paymentText: {
    fontSize: 15,
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    height: '100%',
    overflow: 'hidden',
  }
}));

// padding: 20px 1px 20px 20px;
// position: fixed;
// bottom: 0;
// right: 0;
// left: 0;
// z-index: 999;
// min-height: 180px;
// height: 45%;
// overflow-y: scroll;
// // border: 1px solid #707070;
// box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
// }
