import React from 'react';
import {
    Box,
    Grid,
    Typography,
    ButtonBase, Container,
    CircularProgress

} from '@material-ui/core';
import PlaceHolder from '../../../assets/images/image_placeholder.png';
import Variants from "../components/variants"
const ListItem = ({ classes, data, removeItem, addItem, mrp, price, id, itemId, onAddItemLoader }) => {
    return (
        <Grid item xs={12} direction="column">
            <ul>
                <li>
                    <Grid
                        item
                        xs={12}
                        direction="row"
                        className={classes.grid_flex_item}
                    >
                        <Grid item style={{ flex: '185%' }}>
                            <Grid
                                item
                                style={{
                                    marginBottom: 3,
                                    marginTop: 10,
                                    width: 'auto',
                                    flexWrap: 'nowrap',
                                    display: 'flex',
                                    alignItems: 'start',
                                    alignContent: 'start',
                                    alignSelf: 'start'
                                    // paddingLeft: 10
                                }}
                                direction="row"
                                classes={{
                                    container:
                                        classes.itemNameContainer
                                }}
                            >
                                <ButtonBase
                                    className={classes.image}
                                >

                                    {data.image === '' ||
                                        data.image === undefined ? (


                                            <Container
                                                style={{
                                                    height: 70,
                                                    width: 70,
                                                    borderRadius: '5px',
                                                    backgroundColor: '#E8E8E8',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <img
                                                    src={PlaceHolder}
                                                    style={{
                                                        margin: 0,
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform:
                                                            'translate(-50%, -50%)'
                                                    }}
                                                ></img>
                                            </Container>
                                        ) : (
                                            <img
                                                style={{ objectFit: 'cover' }}
                                                class={classes.item_img}
                                                src={data.image}
                                                height={80}
                                                width={70}
                                                onError={(e) => {
                                                    e.target.src = PlaceHolder;
                                                    e.target.height = '30px';
                                                    e.target.style =
                                                        'height:30px;width:30px;background-color:#f3f3f3;padding:20px; ';
                                                }}
                                            />
                                        )}
                                </ButtonBase>
                                <Box paddingLeft="15px">
                                    <Typography
                                        className={classes.text_common}
                                    >
                                        {data.itemType}
                                    </Typography>
                                    <Grid container>
                                        <Grid
                                            item
                                            className="add-item-outer-container"
                                        >
                                            <Grid
                                                container
                                                direction="row"
                                                className="add-item-inner-container"
                                            >
                                                <Grid
                                                    item
                                                    className="add-item-minus-container"
                                                    onClick={() =>
                                                        removeItem(data)
                                                    }
                                                >
                                                    <Typography>
                                                        {'-'}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    className={
                                                        classes.quantity
                                                    }
                                                >
                                                    {onAddItemLoader ? (
                                                        <div>
                                                            {id === itemId ? (
                                                                <div className="circular">
                                                                    <CircularProgress
                                                                        size={16}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                    <Typography className="add-item-counter">
                                                                        {data.quantity}
                                                                    </Typography>
                                                                )}
                                                        </div>
                                                    ) : (
                                                            <Typography className="add-item-counter">
                                                                {data.quantity}
                                                            </Typography>
                                                        )}
                                                </Grid>
                                                <Grid
                                                    item
                                                    className="add-item-plus-container"
                                                    onClick={() =>
                                                        addItem(data)
                                                    }
                                                >
                                                    <Typography>
                                                        {'+'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* Variants */}

                                    {data.selectedVariants &&
                                        data.selectedVariants.map(
                                            (e, ind) => {
                                                if (ind % 2 === 0)
                                                    return (
                                                        <Grid
                                                            direction="row"
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'space-between'
                                                            }}
                                                        >

                                                            <Variants list={data.selectedVariants} ind={ind} />
                                                            {data.selectedVariants
                                                                .length >
                                                                ind + 1 &&
                                                                <Variants list={data.selectedVariants} ind={ind} />
                                                            }
                                                        </Grid>
                                                    );
                                            }
                                        )}

                                </Box>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            direction="column"
                        //style={{ alignItems: 'flex-end' }}
                        >
                            <Grid item>
                                <Typography
                                    style={{
                                        fontSize: 15,
                                        fontWeight: 500,
                                        textAlign: 'right',
                                        marginTop: 10,
                                        marginbottom: 6
                                    }}
                                >
                                    &#8377; {price}
                                </Typography>
                            </Grid>

                            {mrp !== 0 ? (
                                <Grid
                                    className={classes.grid_flex2}
                                    item
                                >
                                    {mrp && (
                                        <Typography
                                            className={classes.cost_text}
                                        >
                                            &#8377; {mrp}
                                        </Typography>
                                    )}
                                    {mrp > price && (
                                        <Typography
                                            className={
                                                classes.discount_text
                                            }
                                        >
                                            &#8377;
                                            {Math.round(
                                                (mrp - price) * 100
                                            ) / 100}{' '}
                      OFF
                                        </Typography>
                                    )}
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                </li>
            </ul>
        </Grid>
    );
};

export default ListItem;
