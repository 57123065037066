import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  useMediaQuery,
  Button,
  IconButton,
  Grid,
  Tabs,
  Tab,
  Typography,
  Box,
  InputBase,
  ButtonBase,
  Badge,
  MenuItem,
  Menu
} from '@material-ui/core';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CartIcon from '@material-ui/icons/ShoppingCart';
import FilterListIcon from '@material-ui/icons/FilterList';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import MenuIcon from '@material-ui/icons/Menu';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import _ from 'lodash';
import { navigateTo, shareUrl } from '../../../../utils/history'
import { CART_ROUTE, WISHLIST } from '../../../../utils/routesConstants';

import '../styles.scss';
import ListCustomItem from './listView';
import ProceedToCart from './proceedToCart';
import ShareIconButton from '../../../Header/shareIconButton';
import { FormatListNumberedRtlOutlined } from '@material-ui/icons';

var itemViewFormat = 'grid';
var categoryTabState = 0;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography style={{ padding: 0 }}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme) => ({
  selectedCategory: {
    paddingLeft: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(1),
    border: '1px solid #EBEBEB',
    borderRadius: '3px !important',
    backgroundColor: 'white'
  },

  headerInputContainer: {
    width: '100%'
  },

  categoryContainer: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },

  locationContainer: {
    padding: '0px 10px 10px 10px'
  },
  // img: {
  //   margin: 'auto',
  //   display: 'block',
  //   maxWidth: '100%',
  //   maxHeight: '100%',
  //   fontSize: 19
  // },
  proceedToCart: {
    width: '100%',
    position: 'fixed',
    bottom: '56px'
  },
  badgeIcon: {
    left: 5,
    height: 17,
    lineHeight: 0.8,
    minWidth: 17,
    background: '#DBA719',
    color: '#fff'
  }
}));

function ScrollableTabsButtonAuto({ storeData, userCartData }) {
  const url = shareUrl();
  const shareText = encodeURIComponent(
    `Hello, I was going through your ${storeData.storeData.storeName} store on ${url}. Is it a good time to chat?`
  );

  const [value, setValue] = React.useState(categoryTabState);
  const [toggle, setToggle] = React.useState(
    itemViewFormat === 'list' ? false : true
  );
  const [products, setProducts] = useState([]);
  const [categories, setCategory] = useState([]);
  // const [locationToggle, setLocationToggle] = useState(false);
  const [productMap, setproductMap] = useState({});
  const [searchText, setSearchText] = React.useState('');
  const classes = useStyles();
  const [width, setWidth] = useState(window.innerWidth);

  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    // console.log(userCartData);
    const sum = userCartData.cartData
      .map((n) => n.quantity)
      .reduce((a, b) => a + b, 0);
    setCartCount(sum);
  }, [userCartData]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    var catList = _.uniq(_.map(storeData.storeData.items, 'category'));
    catList.splice(0, 0, 'All');
    setCategory(catList);
    setSearchText('');
    //Makes a map of products according to their category
    updateProductMapByCategory(catList);
  }, [storeData.storeData]);

  const [isFiltered, setIsFiltered] = useState(false);
  useEffect(() => {
    if (!isFiltered) {
      if (searchText.length === 0)
        if (categoryTabState === 0) setProducts(storeData.storeData.items);
        else setProducts(productMap[categories[categoryTabState]]);
    } else {
      setIsFiltered(false);
    }

  }, [products]);



  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  const updateProductMapByCategory = (catList) => {
    let dummyMap = {};

    catList.forEach((currentItem) => {
      dummyMap[currentItem] = [];
    });

    storeData.storeData.items.forEach((currentItem) => {
      dummyMap[currentItem.category].push(currentItem);
    });

    setproductMap(dummyMap);
  };

  const handleChange = (event, newValue) => {
    //Tab Change
    setValue(newValue);
    let filteredProducts = [];
    categoryTabState = newValue;
    // Set Product Array According to Category
    if (categories[newValue] === 'All') {
      filteredProducts = storeData.storeData.items;
    } else {
      filteredProducts = productMap[categories[newValue]];
    }
    const newList = handleFilter(selectedFilter, filteredProducts);
    if (searchText && searchText.length) {
      handleSearch(searchText, newList);
    }
  };

  const handleSearch = _.debounce((text, newList) => {
    setSearchText(text);
    const toBeFiltered = newList ? newList : products;
    const filtered = toBeFiltered.filter(function (str) {
      return str.itemType.toLowerCase().indexOf(text.toLowerCase()) !== -1;
    });
    setProducts(filtered);
  }, 300);



  const handleFilter = (option, filteredProducts) => {
    setIsFiltered(true);
    setAnchorEl(null);
    let sortedList = [];
    if (['Must Try', 'New', 'BestSeller'].includes(selectedFilter)) {
      sortedList = storeData.storeData.items;
    }
    else {
      sortedList = filteredProducts ? filteredProducts : products;
    }
    if (option !== selectedFilter) {
      setSelectedFilter(option);
    }
    // ['A-Z', 'Z-A', 'Low-High Price', 'High-Low Price']
    if (option === 'A-Z') {
      sortedList = _.orderBy(sortedList, [item => item.itemType.toLowerCase()], ['asc']);
    }
    else if (option === 'Z-A') {
      sortedList = _.orderBy(sortedList, [item => item.itemType.toLowerCase()], ['desc']);
    }

    else if (['Must Try', 'New', 'BestSeller'].includes(option)) {
      sortedList = sortedList.filter(el => el.tagsList.includes(option));
    }
    else if (option === 'Low-High Price')
      sortedList = _.orderBy(sortedList, [item => item.price], ['asc']);
    else if (option === 'High-Low Price')
      sortedList = _.orderBy(sortedList, [item => item.price], ['desc']);
    else if (option === 'Clear Filters') {
      setSelectedFilter(null);
      sortedList = storeData.storeData.items;
      setValue(0);
    }

    if (sortedList.length)
      setProducts(sortedList);
    return sortedList;
  };
  var isMobile = true;
  if (width > 1024) isMobile = false;

  const mediumViewport = useMediaQuery('(min-width:960px)');
  const [loaded, setLoaded] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);

  const defaultImage = `https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcS4JEXhkf2BYh60f3sZXbgLsjsd0qpJHgXQzA&usqp=CAU`;
  return (
    <Box pb={1} className="inner-page">
      {storeData.loader ? (
        <div className="flex-center pt-200">
          <div className="loader small-loader" />
        </div>
      ) : (
          <Box position="relative">
            <Box mb={userCartData && userCartData.cartData.length > 0 ? 10 : 0}>
              <Container className="mobile-p-0">
                <Box className="inner-header" mb={2} overflow="hidden">
                  <Box
                    display="flex"
                    style={{ backgroundColor: '#5C4DB1' }}
                    p={2}
                  >
                    <ButtonBase
                      className="header-image-container"
                      style={{ cursor: 'default' }}
                    >
                      {loaded ? null : <div className="loader white small"></div>}
                      {storeData.storeData.storePhoto ? (
                        <img
                          className="header-img"
                          alt="complex"
                          src={storeData.storeData.storePhoto}
                          style={loaded ? {} : { display: 'none' }}
                          onLoad={() => setLoaded(true)}
                        />
                      ) : (
                          <img
                            className="header-img"
                            alt="complex"
                            style={loaded ? {} : { display: 'none' }}
                            onLoad={() => setLoaded(true)}
                            onError={(e) => {
                              e.target.src = `${defaultImage}`; // some replacement image
                              // e.target.style = 'padding: 8px; margin: 16px' // inline styles in html format
                            }}
                            src={defaultImage}
                          />
                        )}
                    </ButtonBase>

                    <Box
                      width="100%"
                      px={1}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      {storeData.storeData.storeName && (
                        <Box pl="10px" mb={1}>
                          <Typography
                            className="header-store-title"
                            color="secondary"
                            variant="body1"
                          >
                            {storeData.storeData.storeName}
                          </Typography>
                        </Box>
                      )}
                      <Box
                        width="100%"
                        display="flex"
                        flexDirection={['row', 'row', 'row', 'row']}
                        justifyContent="space-between"
                        className="location-contenier"
                      >
                        <Box className="content-dsk">
                          <Box display="flex" width="80%" alignItems="flex-start">
                            <ButtonBase className="location-icon">
                              <LocationOnIcon />
                            </ButtonBase>
                            <Grid
                              item
                              xs
                              container
                              direction="column"
                              spacing={12}
                            >
                              <Grid item xs>
                                <Box className="cat-address-line-1">
                                  {storeData.storeData?.city?.name}
                                </Box>
                                <Box className="cat-address-line-2">
                                  {storeData.storeData?.storeAddress}
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                        <Box
                          display="flex"
                          mb={[1, 0, 0, 0]}
                          className="dsk-location"
                        >
                          <Box
                            mr={1}
                            className={
                              storeData.storeData?.storeEmail === ''
                                ? classes.noEmail
                                : classes.email
                            }
                          >
                            <Box display={['flex', 'flex', 'flex', 'flex']}>
                              <Button
                                className="header-button"
                                variant="outlined"
                                color="secondary"
                                size="small"
                                onClick={() => {
                                  window.location.href = `tel:+91${storeData.storeData.mobileNumber}`;
                                }}
                              >
                                Call Us
                            </Button>
                            </Box>
                            <Box display={['none', 'none', 'none', 'none']}>
                              <IconButton
                                variant="outlined"
                                color="secondary"
                                className="header-cart-icon-container"
                                onClick={() => {
                                  window.location.href = `tel:+91${storeData.storeData.mobileNumber}`;
                                }}
                              >
                                <CallIcon className="header-cart-icon" />
                              </IconButton>
                            </Box>
                          </Box>
                          {storeData.storeData.storeEmail !== '' ? (
                            <Box style={{ width: 'fit-content' }} mr={1}>
                              <Box display={['flex', 'flex', 'flex', 'flex']}>
                                <Button
                                  className="header-button"
                                  variant="outlined"
                                  color="secondary"
                                  size="small"
                                  onClick={() => {
                                    window.location.href = `mailto:${storeData.storeData.storeEmail}`;
                                  }}
                                >
                                  Email Us
                              </Button>
                              </Box>
                              <Box display={['none', 'none', 'none', 'none']}>
                                <IconButton
                                  variant="outlined"
                                  color="secondary"
                                  className="header-cart-icon-container"
                                  onClick={() => {
                                    window.location.href = `mailto:${storeData.storeData.storeEmail}`;
                                  }}
                                >
                                  <EmailIcon className="header-cart-icon" />
                                </IconButton>
                              </Box>
                            </Box>
                          ) : null}
                        </Box>
                        <Box className="content-mobile" >



                          <Box display="flex">
                            <Box mr={1}>
                              <ShareIconButton
                                classVar="header-cart-icon-container"
                                color="secondary"
                                storeData={storeData}
                                itemDetailsURL={false}
                              />
                            </Box>
                            <Box>
                              <IconButton
                                variant="outlined"
                                color="secondary"
                                className="header-cart-icon-container"
                                onClick={() => navigateTo(CART_ROUTE)}
                              >
                                <Badge
                                  color="secondary"
                                  badgeContent={cartCount}
                                  classes={{
                                    anchorOriginTopRightRectangle:
                                      classes.badgeIcon
                                  }}
                                >
                                  <CartIcon className="header-cart-icon" />
                                </Badge>
                              </IconButton>
                            </Box>
                            <Box >
                              <IconButton
                                variant="outlined"
                                color="secondary"
                                className="header-cart-icon-container"
                                onClick={() => navigateTo(WISHLIST)}
                              >

                                <FavoriteBorderIcon className="header-cart-icon" />

                              </IconButton>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="content-mobile">
                    {/* <Box
                      display="flex"
                      p={2}
                      style={{ backgroundColor: 'white' }}
                    >
                      <ButtonBase
                        className={classes.image}
                        style={{ marginRight: 10 }}
                      >
                        <LocationOnIcon
                          style={{
                            margin: 'auto',
                            display: 'block',
                            maxWidth: '100%',
                            maxHeight: '100%',
                            fontSize: 19
                          }}
                        // className={classes.img}
                        />
                      </ButtonBase>
                      <Grid item xs container direction="column" spacing={12}>
                        <Grid item xs>
                          <Box className="cat-address-line-1">
                            {storeData.storeData?.city?.name}
                          </Box>
                          <Box className="cat-address-line-2">
                            {storeData.storeData?.storeAddress}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box> */}
                  </Box>
                </Box>

                <Box
                  display={['block', 'block', 'none', 'none']}
                  marginBottom={2}
                  pl={2}
                  pr={2}
                >
                  <InputBase
                    fullWidth
                    className="headerInput"
                    style={{ backgroundColor: 'white' }}
                    placeholder={'Search by item, service, category'}
                    onChange={(e) => handleSearch(e.target.value)}
                    inputProps={{
                      style: {
                        paddingTop: 5,
                        paddingBottom: 5,
                        fontSize: 15,
                        paddingRight: 5,
                        marginRight: 10,
                        height: '36px',
                        fontSize: '14px'
                      }
                    }}
                  />
                </Box>
              </Container>
              <Container maxWidth="lg" className="cart-container">
                {categories && categories.length ? (
                  <Box
                    style={{ backgroundColor: 'white' }}
                    p={[2, 2, 3, 3]}
                    display="flex"
                    flexDirection={['column', 'column', 'row', 'row']}
                  >
                    <Box
                      px={[0, 0, 3, 3]}
                      // minWidth={['100%', '100%', '250px', '250px']}
                      style={{
                        borderColor: '#c8d2d858'
                      }}
                      borderRight={[0, 0, 1, 1]}
                    >
                      <Box pb={2} fontWeight={500}>
                        Category
                    </Box>
                      <Tabs
                        orientation={mediumViewport ? 'vertical' : 'horizontal'}
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                      >
                        {categories.map((item, index) => {
                          return (
                            <Tab
                              // index={index}
                              key={index}
                              component={() => (
                                <Box
                                  mr={[1, 1, 0, 0]}
                                  onClick={(e) => handleChange(e, index)}
                                  style={{
                                    cursor: 'pointer',
                                    alignSelf: 'flex-end',
                                    marginBottom: '8px',
                                    backgroundColor:
                                      value === index ? '#5C4DB1' : '#FFFFFF',
                                    borderRadius: 5,
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                    paddingRight: 15,
                                    paddingLeft: 15,
                                    border: 'solid',
                                    borderWidth: 1,
                                    borderColor: '#EBEBEB',
                                    fontSize: 14,
                                    fontWeight: 'regular',
                                    color: value !== index ? 'black' : '#FFFFFF'
                                  }}
                                >
                                  <text style={{ textAlign: 'center' }}>
                                    {item}
                                  </text>
                                </Box>
                              )}
                              {...a11yProps(index)}
                            />
                          );
                        })}
                      </Tabs>
                    </Box>

                    <Box width="100%" pl={[0, 0, 3, 3]}>
                      <Box
                        width="336px"
                        display={['none', 'none', 'block', 'block']}
                      >
                        <InputBase
                          fullWidth
                          className="headerInput"
                          style={{ backgroundColor: '#f4f4f4' }}
                          placeholder={'Search by item, service, category'}
                          onChange={(e) => handleSearch(e.target.value)}
                          inputProps={{
                            style: {
                              paddingTop: 5,
                              paddingBottom: 5,
                              fontSize: 15,
                              paddingRight: 5,
                              marginRight: 10,
                              height: '36px',
                              fontSize: '14px'
                            }
                          }}
                        />
                      </Box>

                      {categories.map((item, index) => {
                        return (
                          <TabPanel
                            key={index}
                            value={value}
                            index={index}
                          // className="bg-white"
                          >
                            <Box display="flex" flexDirection="column" my={1}>
                              <Box py={1}>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    sm
                                    container
                                  // alignItems="center"
                                  >
                                    <Grid
                                      item
                                      xs
                                    // alignItems="center"
                                    >
                                      <Typography className="tabs-heading semiBold">
                                        {item} items
                                    </Typography>
                                    </Grid>



                                    {/* <Grid item>
                                      <Box style={{ cursor: 'pointer' }}>
                                        <Box
                                          className="cat-toggle-icon-container"
                                          py="5px"
                                        >
                                          {toggle ? (
                                            <MenuIcon
                                              onClick={() => {
                                                // console.log(itemViewFormat, 'itemViewFormat')
                                                itemViewFormat = 'list';
                                                setToggle(!toggle);
                                              }}
                                            />
                                          ) : (
                                              <ViewComfyIcon
                                                onClick={() => {
                                                  itemViewFormat = 'grid';
                                                  setToggle(!toggle);
                                                }}
                                              />
                                            )}
                                        </Box>
                                      </Box> */}



                                    {products && products.length ? <><div>
                                      <Box style={{ cursor: 'pointer' }} className="cat-toggle-icon-container" py="5px">
                                        <FilterListIcon style={selectedFilter ? { color: "black" } : { color: "grey" }} onClick={handleClick} />
                                      </Box>
                                      <Menu
                                        id="long-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                          style: {
                                            // maxHeight: ITEM_HEIGHT * 4.5,
                                            width: '20ch',
                                          },
                                        }}
                                      >
                                        {['A-Z', 'Z-A', 'Low-High Price', 'High-Low Price', 'Must Try', 'BestSeller', 'New', 'Clear Filters'].map((option) => {
                                          if (selectedFilter === null && option === 'Clear Filters')
                                            return null;

                                          return (
                                            <MenuItem key={option}
                                              // selected={option === 'A-Z'}
                                              onClick={() => { handleFilter(option) }} style={option === selectedFilter ? { fontWeight: "bold" } : { fontWeight: "normal" }}>
                                              {option}
                                              {/* {option} */}
                                            </MenuItem>
                                          )
                                        }
                                        )}
                                      </Menu>
                                    </div>

                                      <Grid item>
                                        <Box style={{ cursor: 'pointer', marginLeft: '1vh' }}>
                                          <Box
                                            className="cat-toggle-icon-container"
                                            py="5px"
                                          >
                                            {toggle ? (
                                              <MenuIcon
                                                onClick={() => {
                                                  // console.log(itemViewFormat, 'itemViewFormat')
                                                  itemViewFormat = 'list';
                                                  setToggle(!toggle);
                                                }}
                                              />
                                            ) : (
                                                <ViewComfyIcon
                                                  onClick={() => {
                                                    itemViewFormat = 'grid';

                                                    setToggle(!toggle);
                                                  }}
                                                />
                                              )}
                                          </Box>
                                        </Box>
                                      </Grid> </> : <></>}
                                  </Grid>
                                </Grid>

                              </Box>
                              {
                                <Box>
                                  <ListCustomItem
                                    toggle={toggle}
                                    products={products ?? []}
                                    storeName={storeData.storeData.storeName}
                                    isMobile={isMobile}
                                    storeId={storeData.storeData._id}
                                  />
                                </Box>
                              }
                            </Box>
                          </TabPanel>
                        );
                      })}
                    </Box>
                  </Box>
                ) : (
                    <div className="flex-center pt-200">
                      <div className="loader small-loader" />
                    </div>
                  )}
              </Container>
            </Box>

            <ProceedToCart
              shareText={shareText}
              storeData={storeData}
            ></ProceedToCart>
          </Box>
        )
      }
    </Box >
  );
}
const mapStateToProps = ({ storeData }) => ({
  storeData
});

export default connect(mapStateToProps)(ScrollableTabsButtonAuto);