import React from 'react';
import {
    Box,
    Grid,
    Typography

} from '@material-ui/core';
const Variants = ({ list, ind }) => {
    return (
        <Grid
            direction="row"
            style={{
                display: 'flex',
                alignItems: 'center',
                paddingTop: '8px'
            }}
        >
            {list[ind].colour !== '' && (
                <div style={{ display: 'flex' }}>
                    <Typography
                        style={{
                            paddingRight: 10,
                            fontWeight: 'bold',
                            fontSize: 14
                        }}
                    >
                        {'Colour'}:
            </Typography>
                    <div
                        style={{
                            backgroundColor: list[ind].colour,
                            width: 25,
                            height: 15,
                            border: 'solid',
                            borderColor: 'black',
                            borderWidth: 'thin'
                        }}
                    />
                </div>
            )}

            {list[ind].size !== '' && (
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Typography
                        style={{
                            paddingLeft: 15,
                            paddingRight: 10,
                            fontWeight: 'bold',
                            fontSize: 14
                        }}
                    >
                        {'Size'}:
            </Typography>
                    <Typography>{list[ind].size}</Typography>
                </div>
            )}
            {list[ind].weight !== '' && (
                <Box display="flex" alignItems="center">
                    <Typography
                        style={{
                            paddingLeft: 7,
                            paddingRight: 7,
                            fontWeight: 'bold',
                            fontSize: 14
                        }}
                    >
                        {'Weight/Portion'}:
            </Typography>
                    {/* <Typography>{list[ind].weight}</Typography> */}
                    <Typography style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "pre" }}>{list[ind].weight}</Typography>
                </Box>
            )}
        </Grid>
    );
};

export default Variants;
