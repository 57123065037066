import React, { useEffect, useState } from 'react';
import { useLoginHook } from './hooks';
import { AppBar, Typography, IconButton, Toolbar, Box } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import CarouselImage1 from '../../assets/images/knock.png';
import CarouselImage2 from '../../assets/images/invoicing.png';

import LoginBtn from './Components/LoginBtn';
import Credentials from './Components/Credentials';
import { useStyles } from './style';
import './style.scss';
import {
  onSignInSubmit,
  submitVerificationCode,
} from '../../services/authService';
import { getLocalStorageTokens } from '../../utils/tokensHelper';
import { HOME_ROUTE } from '../../utils/routesConstants';
import Verification from './Components/Verification';
import { toastr } from 'react-redux-toastr';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Login = ({ handleClose }) => {
  const getSliderSettings = () => {
    return {
      dots: true,
      autoplay: false,
      // infinite: true,
      // slidesToShow: 1,
      arrows: false,
      slidesToScroll: 1,
      adaptiveHeight: true,

    };
  }
  const carouselData = [
    {
      image: CarouselImage1,
      tag: `Order from  ${window.localStorage.getItem('storeName')}.`//  if ${window.localStorage.getItem('storeName')} is not possible. Then in that case "Order from your trusted stores.`

    },
    {
      image: CarouselImage2,
      tag: "Get update on your order and track it's status."
    }
  ]
  const settings = getSliderSettings();


  const { getUserAuthentication } = useLoginHook();
  const [username, setUserName] = useState('');
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  var [codeResult, setCodeResult] = useState({});
  const [disableVerificationButton, setdisableVerificationButton] = useState(
    false
  );
  const [appBarText, setAppBarText] = useState('Login');

  async function onPhoneNumberSubmit() {
    try {
      var result = await onSignInSubmit(phone);
      setCodeResult(result);
      setShowVerification(true);
      setAppBarText('OTP');
    } catch (error) {
      // console.log(error)
    }
  }

  async function onVerificationCodeSubmit() {
    try {
      setdisableVerificationButton(true);
      var result = await submitVerificationCode(codeResult, code);
      getUserAuthentication(
        '',
        result.phoneNumber,
        result.token,
        result.refreshToken,
        result.uId
      );
    } catch (error) {
      setdisableVerificationButton(false);
      // console.log(error);
      toastr.error(error.message || 'Something went wrong!');
    }
  }

  const classes = useStyles();

  const { isLoggedIn } = getLocalStorageTokens();
  if (isLoggedIn) {
    handleClose();
    return <div></div>
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      className="login-section"
    >
      <Box
        position="fixed"
        top={0}
        display={['flex', 'none', 'none', 'none']}
        width="100%"
      >
        <AppBar className="appBar">
          <Toolbar>
            {showVerification &&
              <IconButton>
                <KeyboardBackspaceIcon
                  color="secondary"
                  onClick={() => window.location.reload()}
                />
              </IconButton>}
            <Typography
              // className={classes.appText}
              color="secondary"
              variant="h6"
            >
              {appBarText}
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>

      <Box>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <Box
            overflow="hidden"
            className="responsive-img-holder"
          >
            <Slider {...settings}>

              {
                carouselData.map((data, id) => {
                  return (
                    <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                      <img className="responsive-img" src={data.image} style={{
                        overflow: 'hidden',
                        height: '32vh',
                      }} />
                      <div style={{ justifyContent: 'center', display: 'flex', textAlign: 'center' }}>

                        <Typography
                          color="primary"
                          variant="subtitle2"

                        >{data.tag}</Typography>
                      </div>
                    </div>
                  );
                })


              }
            </Slider>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box display="flex" width="90%" pb={5} className="login-bottom-section fade-in">

              {showVerification
                ? <Verification
                  code={code}
                  setCode={setCode}
                  disable={disableVerificationButton}
                  onVerificationCodeSubmit={onVerificationCodeSubmit}
                />
                : <Credentials
                  phone={phone}
                  setPhone={setPhone}
                  username={username}
                  setUserName={setUserName}
                  onPhoneNumberSubmit={onPhoneNumberSubmit}
                />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
