import { IconButton } from '@material-ui/core';
import { ShareOutlined } from '@material-ui/icons';
import React from 'react';
import { shareUrl } from '../../utils/history'
const ShareIconButton = (props) => {
  const { storeData, itemDetailsURL, color, classVar } = props;

  const url = itemDetailsURL
    ? itemDetailsURL
    : shareUrl();
  const shareText = itemDetailsURL
    ? encodeURIComponent(
      `Hello! Check out this product from ${storeData.storeData.storeName}. Click on ${url} to see the product.`
    )
    : encodeURIComponent(
      `Hello! Checkout some amazing product from ${storeData.storeData.storeName}, Click on ${url} to access the store.`
    );

  return (
    <IconButton
      color={color}
      variant="outlined"
      className={classVar}
      rel="noopener noreferrer"
      href={`https://api.whatsapp.com/send?text=${shareText}`}
      target="_blank"
    // href={`https://wa.me?text=${shareText}`}
    >
      <ShareOutlined className="header-cart-icon"></ShareOutlined>
    </IconButton>
  );
};
export default ShareIconButton;
